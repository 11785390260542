import { createEntityAdapter } from '@reduxjs/toolkit'
import { PhoenixBlockTemplate } from './types/blocks'
import { AppStudioPage } from './types/pages'

const customBlockTemplateAdapter = createEntityAdapter<PhoenixBlockTemplate>({
    selectId: (template) => template._id,
    sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})

const appStudioPagesAdapter = createEntityAdapter<AppStudioPage>({
    selectId: (page) => page._id,
    sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})

export { customBlockTemplateAdapter, appStudioPagesAdapter }
