import * as Sentry from '@sentry/react'

export default function initSentry() {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        tracesSampleRate: 1,
        tracePropagationTargets: [import.meta.env.VITE_TC_BACKEND_API],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
        environment: import.meta.env.MODE
    })
}
