/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ScreenTypes, Screen } from 'types/api-types'
import { ScreensState } from 'types/dashboard-types'
import { Parse } from 'services/parse'
import getHeaders from 'store/_utils/get-headers'
import { CustomScreenForm } from 'types/forms/custom-screen-types'
import { RootState } from 'store'
import screensAdapter from './screens-adapter'
import {
    transformScreenFormToCreateScreen,
    transformeScreenFormToUpdateScreen,
} from './utils/transformScreenFormToScreen'
import { selectScreenById } from './screens-selectors'

const api = import.meta.env.VITE_TC_INSIGHTS_API as string

type CreateScreenInput = {
    screenFormData: CustomScreenForm
    screenType: ScreenTypes
}

export const createScreen = createAsyncThunk<Screen, CreateScreenInput>(
    'screens/createScreen',
    async ({ screenFormData, screenType }, { getState, rejectWithValue }) => {
        try {
            const state = getState() as RootState
            const appId = state.app.data.id
            const screen = transformScreenFormToCreateScreen({
                screenFormData,
                screenType,
                appId,
            })
            const headers = await getHeaders()
            const response = await fetch(`${api}/v1/apps/${appId}/pages`, {
                method: 'POST',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...screen, appId }),
            })
            // Check if the response status is not OK
            if (!response.ok) {
                const errorData = await response.json()
                return rejectWithValue(errorData.msg || 'Server Error')
            }

            const responseData = await response.json()

            return responseData
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred')
        }
    }
)

export const fetchScreens = createAsyncThunk(
    'screens/fetchScreens',
    async (_, { rejectWithValue }) => {
        try {
            const appId = Parse.User.current().attributes.app.id as string
            const headers = await getHeaders()
            const response = await fetch(`${api}/v1/apps/${appId}/pages`, {
                method: 'GET',
                headers: { ...headers, 'Content-Type': 'application/json' },
            })

            // Check if the response status is not OK
            if (!response.ok) {
                const errorData = await response.json()
                return rejectWithValue(errorData.msg || 'Server Error')
            }

            const responseData = await response.json()
            return responseData
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred')
        }
    }
)

type UpdateScreenPayload = {
    screenId: string
    screenFormData: CustomScreenForm
    screenType: 'account-page' | 'hybrid-page' | 'cart-page'
}

export const updateScreen = createAsyncThunk<Screen, UpdateScreenPayload>(
    'screens/updateScreen',
    async (
        { screenId, screenFormData, screenType },
        { getState, rejectWithValue }
    ) => {
        try {
            const state = getState() as RootState
            const appId = state.app.data.id
            const currentScreen = selectScreenById(state, screenId)
            const screen = transformeScreenFormToUpdateScreen({
                screenId,
                screenFormData,
                screenType,
                appId,
                currentScreen,
            })

            const headers = await getHeaders()
            const response = await fetch(
                `${api}/v1/apps/${appId}/pages/${screenId}`,
                {
                    method: 'PUT',
                    headers: { ...headers, 'Content-Type': 'application/json' },
                    body: JSON.stringify(screen),
                }
            )

            // Check if the response status is not OK
            if (!response.ok) {
                const errorData = await response.json()
                return rejectWithValue(errorData.msg || 'Server Error')
            }

            const responseData = await response.json()
            return responseData
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred')
        }
    }
)

const screensSlice = createSlice({
    name: 'screens',
    initialState: screensAdapter.getInitialState<ScreensState>({
        initialized: false,
        loading: 'pending',
        error: null,
        entities: {},
        ids: [],
    }),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchScreens.pending, (state) => {
            state.loading = 'pending'
            state.initialized = false
        })
        builder.addCase(fetchScreens.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            state.initialized = true
            screensAdapter.upsertMany(state, action.payload)
        })
        builder.addCase(fetchScreens.rejected, (state, action) => {
            state.loading = 'failed'
            state.initialized = true
            state.error = action.payload || ''
        })
        builder.addCase(createScreen.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(createScreen.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            screensAdapter.addOne(state, action.payload)
        })
        builder.addCase(createScreen.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.payload || ''
        })
        builder.addCase(updateScreen.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateScreen.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.error = null
            screensAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(updateScreen.rejected, (state, action) => {
            state.loading = 'failed'
            state.error = action.payload || ''
        })
    },
})

export default screensSlice.reducer
