import find from 'lodash/find'
import { Parse } from 'services/parse'

export const getAllIntegrations = () => {
    const integrationsQuery = new Parse.Query('Integrations')
    integrationsQuery.ascending('comingSoon')
    return integrationsQuery.find()
}
export const getAllUserIntegrations = () =>
    Parse.User.current().attributes.app.attributes.settings
        ? Parse.User.current().attributes.app.attributes.settings.attributes
              .integrations
        : []
export const grabShopifyDomain = () =>
    Parse.User.current().attributes.app.attributes.shopifyStore
export const getIntegrationByHandle = (handle) => {
    const integrationQuery = new Parse.Query('Integrations')
    integrationQuery.equalTo('internalName', handle)
    return integrationQuery.first()
}
export const getUserIntegrationByHandle = (handle) =>
    find(
        Parse.User.current().attributes.app.attributes.settings.attributes
            .integrations,
        { name: handle }
    )
export const addUserIntegration = (options, integrationFields) => {
    const params = {
        config: integrationFields,
    }
    if (options.integrationId === 'eLL1cA9X3S') {
        params.config = {
            ...params.config,
            'search-api-key': options.searchApiKey,
        }
    }
    return Parse.Cloud.run('integrations_addIntegration', {
        appId: Parse.User.current().attributes.app.id,
        integrationId: options.integrationId,
        ...(options.platform && { platform: true }),
        config: params.config,
    })
}
// Might need to add checks

export const removeUserIntegration = (integrationHandle) =>
    // Might need to add checks
    Parse.Cloud.run('integrations_disableIntegration', {
        appId: Parse.User.current().attributes.app.id,
        integrationId: integrationHandle,
    })
export const updateUserIntegration = (options, integrationFields) =>
    Parse.Cloud.run('integrations_updateIntegration', {
        appId: Parse.User.current().attributes.app.id,
        integrationId: options.integrationId,
        ...(options.platform && { platform: true }),
        config: integrationFields,
    })
export const enableUserIntegration = (options, integrationFields) =>
    Parse.Cloud.run('integrations_enableIntegration', {
        appId: Parse.User.current().attributes.app.id,
        integrationId: options.integrationId,
        config: integrationFields,
    })
export const setRechargeOauthIntegration = (code) =>
    Parse.Cloud.run('integrations_recharge_getAccessToken', {
        appId: Parse.User.current().attributes.app.id,
        code,
    })
