import React from 'react'

const SvgAbandonCartIcon = ({ color = '#111111', iconLeft }) => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginLeft: iconLeft ? '10px' : 0 }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.33325 28.0002C9.33325 26.5274 10.5272 25.3335 11.9999 25.3335C13.4727 25.3335 14.6666 26.5274 14.6666 28.0002C14.6666 29.4729 13.4727 30.6668 11.9999 30.6668C10.5272 30.6668 9.33325 29.4729 9.33325 28.0002Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 28.0002C24 26.5274 25.1939 25.3335 26.6667 25.3335C28.1394 25.3335 29.3333 26.5274 29.3333 28.0002C29.3333 29.4729 28.1394 30.6668 26.6667 30.6668C25.1939 30.6668 24 29.4729 24 28.0002Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.33333C0 0.596954 0.596954 0 1.33333 0H6.66667C7.30217 0 7.84935 0.448514 7.97407 1.07166L9.09391 6.66667H18C18.7364 6.66667 19.3333 7.26362 19.3333 8C19.3333 8.73638 18.7364 9.33333 18 9.33333H9.62764L11.5478 18.9269C11.6087 19.2338 11.7757 19.5096 12.0195 19.7058C12.2632 19.902 12.5683 20.0062 12.8811 20.0002L12.9067 20H25.8667L25.8922 20.0002C26.2051 20.0062 26.5101 19.902 26.7539 19.7058C26.9965 19.5105 27.163 19.2364 27.2247 18.9312L28.2903 14.6769C28.4282 13.9536 29.1264 13.479 29.8498 13.6169C30.5731 13.7549 31.0477 14.4531 30.9097 15.1764L29.8412 19.4464C29.6583 20.3672 29.1573 21.1943 28.426 21.783C27.6984 22.3688 26.7888 22.6813 25.8551 22.6667H12.9182C11.9845 22.6813 11.0749 22.3688 10.3473 21.783C9.61633 21.1946 9.11551 20.3679 8.93244 19.4476L6.70505 8.31894C6.69602 8.28213 6.68851 8.24471 6.6826 8.20677L5.57375 2.66667H1.33333C0.596954 2.66667 0 2.06971 0 1.33333Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.7239 10.9425C28.2446 11.4632 29.0888 11.4632 29.6095 10.9425C30.1302 10.4218 30.1302 9.57757 29.6095 9.05687L27.8856 7.33301L29.6095 5.60915C30.1302 5.08845 30.1302 4.24423 29.6095 3.72353C29.0888 3.20283 28.2446 3.20283 27.7239 3.72353L26 5.44739L24.2761 3.72353C23.7554 3.20283 22.9112 3.20283 22.3905 3.72353C21.8698 4.24423 21.8698 5.08845 22.3905 5.60915L24.1144 7.33301L22.3905 9.05687C21.8698 9.57757 21.8698 10.4218 22.3905 10.9425C22.9112 11.4632 23.7554 11.4632 24.2761 10.9425L26 9.21863L27.7239 10.9425Z"
            fill={color}
        />
    </svg>
)

export default SvgAbandonCartIcon
