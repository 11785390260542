import React from 'react'

function Loader(properties) {
    switch (properties.type) {
        case 'logo':
            return (
                <div className='loader-bg'>
                    <div className="loader-wrapper" style={properties.style}>
                        <div className="logo-loader" />
                    </div>
                </div>
            )
        case 'spinner':
        default:
            return null
    }
}

export default Loader
