import Gifshot from 'gifshot'
import { createAsset } from 'store/Assets/assets-slice'

export const checkResponseForError = (response) => {
    const res = response?.attributes?.results
    let platformsFailed = 0

    if (response === 'Push notification already sent.  Duplicate blocked.') {
        throw new Error(
            'An identical push has been sent out within the last 30 minutes.'
        )
    }

    if (res?.errors) {
        const { ios: iOSError, android: androidError } = res.errors
        if (iOSError) platformsFailed += 1
        if (androidError) platformsFailed += 1

        if (
            (iOSError?.includes('Topic does not exist') ||
                androidError?.includes('Topic does not exist')) &&
            platformsFailed === 2
        ) {
            console.error(
                'Neither platform succeeded in sending push notification'
            )
            throw new Error(
                'Your push notifications are not set up correctly, please contact a member of the Tapcart team.'
            )
        }
    }
}

export const createBase64Gif = (images) =>
    new Promise((resolve, reject) => {
        if (!images || images.length === 0) {
            return reject()
        }

        Gifshot.createGIF(
            {
                images,
                interval: 0.5,
                numFrames: images.length,
                frameDuration: 0.5,
                sampleInterval: 5,
                gifWidth: 750,
                gifHeight: 750,
            },
            (object) => {
                resolve(object.image)
            }
        )
    })

export const createGif = async (images, dispatch) => {
    try {
        const formattedImages = await gifFilesFormatter(images);
        const base64Gif = await createBase64Gif(formattedImages);
    
        const blob = await (await fetch(base64Gif)).blob();
        const file = new File([blob], "lookbook.gif", { type: "image/gif" });
    
        const assetCreationResponse = await dispatch(createAsset({
            file: file,
            path: '',
        })).unwrap();
    
        if (!assetCreationResponse.url) {
            throw new Error('Failed to upload GIF');
        }
    
        return assetCreationResponse.url;
    } catch (error) {
        console.error("Error in createGif:", error);
        throw error;
    }
}

export const getFormattedProduct = (product) =>
    product?.images?.edges[0]?.node.url

export const getFormattedCollection = (collection) =>
    collection?.products?.edges[0]?.node?.images?.edges[0]?.node?.url

export const setTimeoutPromisified = (timeout) =>
    new Promise((resolve, reject) =>
        setTimeout(() => {
            resolve()
        }, timeout)
    )

const transformPngFiles = function loadImg(images) {
    const promises = []

    images.forEach((path) => {
        promises.push(
            new Promise((resolve, reject) => {
                const img = new Image()

                img.onload = () => resolve({ img, path })
                img.onerror = () => reject(path)

                img.setAttribute('crossorigin', 'anonymous')
                img.src = path
            })
        )
    })

    return Promise.allSettled(promises)
}

export const gifFilesFormatter = async (attachmentImages) => {
    let validGifImages
    const pngFileExists =
        attachmentImages.findIndex((imageFile) => imageFile.includes('.png')) >=
        0

    if (pngFileExists) {
        validGifImages = await transformPngFiles(attachmentImages).then(
            (res) => {
                return res.map((resImg) => {
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')

                    canvas.width = 750
                    canvas.height = 750

                    ctx.fillStyle = '#ffffff'

                    ctx.fillRect(0, 0, canvas.width, canvas.height)

                    ctx.drawImage(
                        resImg.value.img,
                        0,
                        0,
                        canvas.width,
                        canvas.height
                    )
                    const url = canvas.toDataURL()

                    return url
                })
            }
        )
    } else {
        validGifImages = attachmentImages
    }

    return validGifImages
}

export function getFirstError(inputErrors) {
    if (!inputErrors || typeof inputErrors !== 'object')
        return 'Please fill out all required fields'
    const firstError = Object.keys(inputErrors)[0]
    return inputErrors[firstError]
}

export function getFirstErrorForScheduled(inputErrors) {
    if (!inputErrors || typeof inputErrors !== 'object')
        return 'Please fill out all required fields'
    const firstError = Object.keys(inputErrors)[0]

    return `${inputErrors[firstError]} before scheduling your push`
}

export function getProductIDFromString(str) {
    const regex = /(\d+)/;
    const match = str.match(regex);

    return match ? match[0] : null;
}