import { toast } from 'react-toastify'
import React from 'react'
import styled from 'styled-components'
import { boxShadow } from '@tapcartinc/tapcart-ui/dist/boxShadow'
import { inter } from '@tapcartinc/tapcart-ui/dist/inter'
import { circularStd } from '@tapcartinc/tapcart-ui/dist/circularStd'
import { swatches } from '@tapcartinc/tapcart-ui/dist/swatches'
import { ToastCheckmark, ToastAlert } from '@tapcartinc/tapcart-ui/dist/icons'

const ToastNotif = (properties) => {
    const { message, withHeader, type, withSupport } = properties

    return (
        <StyledToastWrapper type={type}>
            <StyledIcon fill="white">
                {type === 'success' ? <ToastCheckmark /> : <ToastAlert />}
            </StyledIcon>
            {withHeader && <StyledErrorHeader>{type}</StyledErrorHeader>}
            {type === 'error' && (
                <div>
                    {message.props ? message.props.children : message}
                    {withSupport && <SupportLink></SupportLink>}
                </div>
            )}
            {type === 'success' && <div>{message}</div>}
        </StyledToastWrapper>
    )
}

const successToast = (
    message,
    options = { autoClose: 3000, withHeader: false, withSupport: false }
) =>
    toast(
        <ToastNotif
            type="success"
            withHeader={options.withHeader}
            message={message}
        />,
        {
            className: 'toast__success',
            containerId: 'globalToastContainer',
            ...options,
        }
    )

const errorToast = (
    message,
    options = { autoClose: 3000, withHeader: false, withSupport: false }
) =>
    toast(
        <ToastNotif
            type="error"
            withHeader={options.withHeader}
            message={message}
            withSupport={options.withSupport}
        />,
        {
            className: 'toast__error',
            containerId: 'globalToastContainer',
            ...options,
        }
    )

const SupportLink = (message) => (
    <>
        Oops! Something went wrong, please try again. If the issue persists,
        contact{' '}
        <a style={{ color: swatches.blue }} href="mailto:help@tapcart.co">
            help@tapcart.co
        </a>
        .{' '}
    </>
)

export { successToast, errorToast }

const StyledIcon = styled.div`
    margin-right: 10px;
    svg {
        width: 15px;
        height: 15px;
    }
`

const StyledErrorHeader = styled.div`
    margin-bottom: 5px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    ${circularStd.bold}
`

const StyledToastWrapper = styled.div`
    display: flex;
    align-items: center;
    color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: ${boxShadow.medium};
    ${inter.medium}
    min-height: 52px;
    font-size: 14px;

    li {
        margin-top: 5px;
        margin-bottom: 8px;
    }

    ${(properties) =>
        properties.type === 'error' &&
        `
	background: ${swatches.red};
	`}
    ${(properties) =>
        properties.type === 'success' &&
        `
	background: #100937;
	`}
`
