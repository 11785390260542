import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr'
import { getAllIntegrations } from 'services/parse/Integrations/fetchers'
import { selectUserIntegrationEntities } from 'store/Settings/user-integration-slice'
import { formatUrl } from 'pages/Integrations/helpers'

const INTEGRATION_CATEGORY_WITH_MULTIPLE_PROVIDERS = new Set([
    'route-shipping-insurance',
])

const categoriesWithMultipleIntegrations = {
    'route-shipping-insurance': 'order-protection',
}

const integationSchema = new schema.Entity('integrations')
const integrationListSchema = [integationSchema]
const integrationsAdapter = createEntityAdapter({
    selectId: (integration) => integration.internalName,
})

export const fetchIntegrations = createAsyncThunk(
    'integrations/fetchIntegrations',
    async () => {
        const integrations = await getAllIntegrations()

        const convert = (integration) => ({
            id: integration.id,
            internalName: integration.get('internalName'),
            title: integration.get('title'),
            fields: integration.get('fields'),
            logo: formatUrl(integration.get('logo')?.url()),
            order: integration.get('order'),
            benefits: integration.get('benefits'),
            bannerImg: formatUrl(integration.get('bannerImg')?.url()),
            summary: integration.get('summary'),
            explainer: integration.get('explainer'),
            youtuberId: integration.get('youtubeId'),
            premium: integration.get('premium'),
            public: integration.get('public'),
            featured: integration.get('featured'),
            featuredImg: formatUrl(integration.get('featuredImg')?.url()),
            category: integration.get('category'),
            actions: integration.get('actions'),
            comingSoon: integration.get('comingSoon'),
            allowInstall: integration.get('allowInstall'),
            beta: integration.get('beta'),
            metadata: integration.get('metadata'),
            supported: integration.get('supported'),
            screenshots: integration.get('screenshots'),
            earlyAccess: integration.get('earlyAccess'),
            wistiaId: integration.get('wistiaId'),
        })

        const allIntegrations = integrations.map(convert)
        const filteredIntegrations = allIntegrations.filter(
            (integration) => integration.public
        )

        const normalizedAll = normalize(allIntegrations, integrationListSchema)
        const normalizedFiltered = normalize(
            filteredIntegrations,
            integrationListSchema
        )

        return {
            all: normalizedAll,
            filtered: normalizedFiltered,
        }
    }
)

export const integrationsSlice = createSlice({
    name: 'integrations',
    initialState: {
        ...integrationsAdapter.getInitialState(),
        initialized: false,
    },
    extraReducers: {
        [fetchIntegrations.pending]: (state, action) => {
            state.loading = 'pending'
        },
        [fetchIntegrations.fulfilled]: (state, action) => {
            integrationsAdapter.upsertMany(
                state,
                action.payload.filtered.entities.integrations || []
            )
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        },
        [fetchIntegrations.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
        },
    },
})

export const { fetchStarted, setIntegrations, setError, editIntegrations } =
    integrationsSlice.actions

export default integrationsSlice.reducer

export const {
    selectById: selectIntegrationByName,
    selectIds: selectIntegrationNames,
    selectEntities: selectIntegrationEntities,
    selectAll: selectAllIntegrations,
    selectTotal: selectTotalIntegrations,
} = integrationsAdapter.getSelectors((state) => state.integrations)

export const selectIntegrationByPartnerId = (state, partnerId) => {
    const integrations = selectAllIntegrations(state)
    return integrations.filter(
        (integration) => integration.id === partnerId
    )?.[0]
}

export const selectIntegrations = (state) => state.integrations

export const selectStandardIntegrations = (state) =>
    selectAllIntegrations(state)
        .filter((integration) => !integration.premium)
        .sort(compareOrder)

export const selectStandardIntegrationsActive = (state) => {
    const userIntegrations = selectUserIntegrationEntities(state)

    return selectAllIntegrations(state).filter(
        (i) => !i.premium && userIntegrations[i.internalName]?.enabled
    )
}

export const selectIntegrationsActive = (state) => {
    const userIntegrations = selectUserIntegrationEntities(state)

    return selectAllIntegrations(state).filter((i) => {
        if (INTEGRATION_CATEGORY_WITH_MULTIPLE_PROVIDERS.has(i.internalName)) {
            const integration =
                categoriesWithMultipleIntegrations[i.internalName]

            return userIntegrations[integration]?.enabled
        }
        return userIntegrations[i.internalName]?.enabled
    })
}

export const selectNavRelatedIntegrations = (state) => {
    const userIntegrations = selectUserIntegrationEntities(state)
    const navIntegrations = [
        'talkable-v2',
        'store-locator',
        'attentive',
        'barcode-scanner',
    ]

    const navIntegrationsConfig = {
        'talkable-v2': {
            enabled: false,
            url: '/contact-sharing',
        },
        'store-locator': {
            enabled: false,
            url: '/store-location',
        },
        attentive: {
            enabled: false,
            url: '/sms-optin',
        },
        'barcode-scanner': {
            enabled: false,
            url: '/barcode-scanner',
        },
    }
    Object.entries(userIntegrations).map((integration) => {
        if (
            navIntegrations.includes(integration[0]) &&
            integration[1]?.enabled
        ) {
            navIntegrationsConfig[integration[0]].enabled = true
        }
    })

    return navIntegrationsConfig
}

export const selectEnterpriseIntegrations = (state) =>
    selectAllIntegrations(state)
        .filter((integration) => integration.premium)
        .sort(compareOrder)

export const selectFeaturedIntegrations = (state) =>
    selectAllIntegrations(state)
        .filter((integration) => integration.featured)
        .sort(compareOrder)

export const compareOrder = (a, b) =>
    a.order > b.order ? 1 : b.order > a.order ? -1 : 0

export const selectIntegrationByObjectId = (state, objectId) => {
    const integrations = selectAllIntegrations(state)
    return integrations.find((integration) => integration.id === objectId)
}
