export const typedRequest = async <T>(
    url: string,
    requestDetails: RequestInit
): Promise<T> => {
    const response = await fetch(url, {
        ...requestDetails,
    })

    return <T>response.json()
}
