import {
    createSlice,
    createAsyncThunk,
    PayloadAction,
    createSelector,
    EntityState,
} from '@reduxjs/toolkit'
import { RootState } from 'store'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'
import { PhoenixBlockTemplate } from './types/blocks'
import { selectAppId } from 'store/App/app-slice'
import { customBlockTemplateAdapter } from './adapters'
import { selectAllCBTs } from './block-templates-selectors'

const baseAPIUrl = import.meta.env.VITE_TC_BACKEND_API as string
const blockTemplatesUrl = `${baseAPIUrl}/v1/app-studio/block-bank`
const appStudioAPI = `${baseAPIUrl}/v1/app-studio`
const phoenixAPI = `${baseAPIUrl}/phoenix`

type CustomBlockFetchPayload = {
    legacyCustomBlockTemplates: PhoenixBlockTemplate[]
}
type AppStudioBlockTemplatesState = {
    blockBank: CategorizedBlockTemplates[]
    blockTemplates: PhoenixBlockTemplate[]
    customBlockTemplates: EntityState<PhoenixBlockTemplate> | null
    helloWorldTemplate: PhoenixBlockTemplate | null
    legacyCustomBlockTemplates: PhoenixBlockTemplate[]
    loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
    error?: string
    initialized: boolean
}

const initialState: AppStudioBlockTemplatesState = {
    blockBank: [],
    blockTemplates: [],
    customBlockTemplates: customBlockTemplateAdapter.getInitialState(),
    helloWorldTemplate: null,
    legacyCustomBlockTemplates: [],
    loading: 'idle',
    initialized: false,
}

export type CategorizedBlockTemplates = {
    key: string
    label: string
    blockTemplates: PhoenixBlockTemplate[]
}
type FetchBlockBankResponse = {
    categorizedBlocks: CategorizedBlockTemplates[]
}

export const fetchBlockBank = createAsyncThunk<
    // Return type of the payload creator
    CategorizedBlockTemplates[],
    // First argument to the payload creator
    {
        screenType?: string
    },
    // Types for ThunkAPI
    { state: RootState }
>(
    'app-studio-block-templates/fetchBlockBank',
    async ({ screenType } = {}, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const queryParams = new URLSearchParams()
        if (screenType) queryParams.append('screenType', screenType)

        const response = await typedRequest<FetchBlockBankResponse>(
            `${blockTemplatesUrl}/${appId}?${queryParams.toString()}`,
            {
                method: 'GET',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response || !response.categorizedBlocks?.length) {
            throw new Error('Failed to fetch block bank')
        }

        const decodedCategorizedBlocks: CategorizedBlockTemplates[] =
            response.categorizedBlocks.map((category) => {
                const decodedBlockTemplates = category.blockTemplates.map(
                    (block) => {
                        return {
                            ...block,
                            code: atob(block.code),
                            // Transpiled code exists here as well but is not needed yet in the
                            // dashboard as it's transpiled on the fly in the editor.
                            // We can add it if needed
                            // transpiledCode: atob(block.transpiledCode),
                        }
                    }
                )

                return {
                    ...category,
                    blockTemplates: decodedBlockTemplates,
                }
            })

        return decodedCategorizedBlocks
    }
)

export const archiveBlockTemplate = createAsyncThunk(
    'app-studio-block-templates/archiveBlockTemplate',
    async (id: string, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<FetchBlockBankResponse>(
            `${phoenixAPI}/custom-block-templates/${appId}/${id}/archive`,
            {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )

        return { id: response?.archivedTemplate?._id }
    }
)

export const createNewBlockTemplate = createAsyncThunk<
    // Return type of the payload creator
    { data: PhoenixBlockTemplate },
    // First argument to the payload creator
    undefined,
    // Types for ThunkAPI
    { state: RootState }
>(
    'app-studio-block-templates/createNewBlockTemplate',
    async (_, { getState }) => {
        try {
            const { app, appStudioBlockTemplate } = getState()

            const appData = app.data as { id: string }
            const appId = appData.id
            const headers = await getHeaders()
            const defaultBlockTemplate =
                appStudioBlockTemplate.helloWorldTemplate

            if (!defaultBlockTemplate) {
                throw new Error('Default template not found')
            }

            const body = {
                owner: { type: 'app', id: appId },
                // hello world template
                blockTemplateId: defaultBlockTemplate._id,
            }

            const response = await typedRequest<{
                blockTemplate: PhoenixBlockTemplate
            }>(`${appStudioAPI}/block-templates`, {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                    'app-id': appId,
                },
                body: JSON.stringify(body),
            })

            const createdBlockTemplate = response.blockTemplate

            const decodedTemplate = {
                ...createdBlockTemplate,
                code: atob(createdBlockTemplate.code),
            }

            return { data: decodedTemplate }
        } catch (err) {
            console.error(err)
            throw err
        }
    }
)

export const updateBlockTemplate = createAsyncThunk<
    // Return type of the payload creator
    { data: PhoenixBlockTemplate },
    // First argument to the payload creator
    PhoenixBlockTemplate,
    // Types for ThunkAPI
    { state: RootState }
>(
    'app-studio-block-templates/updateBlockTemplate',
    async (blockTemplate, { getState, rejectWithValue }) => {
        const { app } = getState()
        const appData = app.data as { id: string }
        const appId = appData.id
        const headers = await getHeaders()

        const body = {
            owner: { type: 'app', id: appId },
            label: blockTemplate.label,
            manifestOptionsList: [],
            forceUpdate: true,
            tags: [],
            updateDescription: `Changed on ${new Date().toISOString()}`,
            code: blockTemplate.code,
            transpiledCode: blockTemplate.transpiledCode,
        }

        const response = await typedRequest<PhoenixBlockTemplate>(
            `${phoenixAPI}/blocktemplates/${blockTemplate._id}`,
            {
                method: 'PUT',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                    'app-id': appId,
                },
                body: JSON.stringify({ ...body }),
            }
        )
        if (response?.message) {
            throw new Error(
                response?.message ?? 'Error Updating Block Template'
            )
        }
        const decodedTemplate = {
            ...response,
            code: atob(response.code),
        }

        return { data: decodedTemplate }
    }
)
export const fetchCustomBlockBank = createAsyncThunk<
    // Return type of the payload creator
    CustomBlockFetchPayload,
    // First argument to the payload creator
    undefined,
    // Types for ThunkAPI
    { state: RootState }
>(
    'app-studio-block-templates/fetchCustomBlockBank',
    async (_, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<FetchBlockBankResponse>(
            `${phoenixAPI}/custom-block-templates/${appId}`,
            {
                method: 'GET',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )
        if (!response || !response.reactBlockTemplates) {
            throw new Error('Failed to fetch block bank')
        }

        const decodedBlocks = response.reactBlockTemplates.map((block) => {
            return {
                ...block,
                code: atob(block.code),
            }
        })

        return {
            customBlockTemplates: decodedBlocks,
        }
    }
)

export const fetchHelloWorldTemplate = createAsyncThunk<
    // Return type of the payload creator
    CustomBlockFetchPayload,
    // First argument to the payload creator
    undefined,
    // Types for ThunkAPI
    { state: RootState }
>(
    'app-studio-block-templates/fetchHelloWorldTemplate',
    async (_, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id
        const headers = await getHeaders()

        const response = await typedRequest<FetchBlockBankResponse>(
            `${phoenixAPI}/custom-block-templates/${appId}/hello-world`,
            {
                method: 'GET',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response || !response.customBlockTemplate) {
            throw new Error('Failed to fetch custom block template')
        }

        const decodedTemplate = {
            ...response.customBlockTemplate,
            code: atob(response.customBlockTemplate.code),
        }

        return {
            helloWorldTemplate: decodedTemplate,
        }
    }
)

export const fetchLegacyCustomBlockBank = createAsyncThunk<
    // Return type of the payload creator
    CustomBlockFetchPayload,
    // First argument to the payload creator
    undefined,
    // Types for ThunkAPI
    { state: RootState }
>(
    'app-studio-block-templates/fetchLegacyCustomBlockBank',
    async (_, { getState }) => {
        const { app, customBlocks } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<FetchBlockBankResponse>(
            `${phoenixAPI}/legacy-custom-blocks/${appId}`,
            {
                method: 'GET',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response || !response.legacyBlockTemplates) {
            throw new Error('Failed to fetch block bank')
        }

        const decodedBlocks = response.legacyBlockTemplates.map((block) => {
            return {
                ...block,
                customBlockTemplateId: block._id,
                _id: block?.manifestConfig?.legacyCustomBlockId,
                code: atob(block.code),
            }
        })

        const submittedBlocks = decodedBlocks.filter((block) => {
            return !!customBlocks.entities[
                block.manifestConfig.legacyCustomBlockId
            ]
        })

        return {
            legacyCustomBlockTemplates: submittedBlocks,
        }
    }
)

export const removeLegacyCustomBlockTemplate = createAsyncThunk(
    'app-studio-block-templates/removeLegacyCustomBlockTemplate',
    async (legacyCustomBlockId, { getState }) => {
        const { appStudioBlockTemplate } = getState()

        const indexOfLegacyCustomBlock =
            appStudioBlockTemplate.legacyCustomBlockTemplates.findIndex(
                (legacyCustomBlockTemplate) =>
                    legacyCustomBlockTemplate.manifestConfig
                        .legacyCustomBlockId === legacyCustomBlockId
            )

        return { index: indexOfLegacyCustomBlock }
    }
)

const appStudioBlockTemplatesSlice = createSlice({
    name: 'app-studio-block-templates',
    initialState,
    reducers: {
        initializeBlockBank(state) {
            state.initialized = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBlockBank.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchBlockBank.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            state.blockBank = action.payload
            state.initialized = true
            addBlockTemplatesToList(state, action.payload)
        })
        builder.addCase(fetchBlockBank.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(fetchCustomBlockBank.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchCustomBlockBank.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            state.customBlockTemplates = customBlockTemplateAdapter.setAll(
                state.customBlockTemplates,
                action.payload.customBlockTemplates
            )
            state.initialized = true
        })
        builder.addCase(fetchCustomBlockBank.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(fetchHelloWorldTemplate.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchHelloWorldTemplate.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            state.helloWorldTemplate = action.payload.helloWorldTemplate
            state.initialized = true
        })
        builder.addCase(fetchHelloWorldTemplate.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(fetchLegacyCustomBlockBank.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            fetchLegacyCustomBlockBank.fulfilled,
            (state, action) => {
                state.loading = 'fulfilled'
                state.initialized = true
                state.legacyCustomBlockTemplates =
                    action.payload.legacyCustomBlockTemplates
            }
        )
        builder.addCase(
            fetchLegacyCustomBlockBank.rejected,
            (state, action) => {
                state.loading = 'rejected'
                state.error = action.error.message
                state.initialized = true
            }
        )
        builder.addCase(createNewBlockTemplate.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(createNewBlockTemplate.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            state.customBlockTemplates = customBlockTemplateAdapter.addOne(
                state.customBlockTemplates,
                action.payload?.data
            )
            state.initialized = true
        })
        builder.addCase(createNewBlockTemplate.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(updateBlockTemplate.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateBlockTemplate.fulfilled, (state, action) => {
            state.loading = 'fulfilled'

            state.customBlockTemplates = customBlockTemplateAdapter.updateOne(
                state.customBlockTemplates,
                {
                    id: action.payload.data._id,
                    changes: action.payload?.data,
                }
            )

            state.initialized = true
        })
        builder.addCase(updateBlockTemplate.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(archiveBlockTemplate.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(archiveBlockTemplate.fulfilled, (state, action) => {
            state.loading = 'fulfilled'

            state.customBlockTemplates = customBlockTemplateAdapter.removeOne(
                state.customBlockTemplates,
                action.payload.id
            )
            state.initialized = true
        })
        builder.addCase(archiveBlockTemplate.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(removeLegacyCustomBlockTemplate.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            removeLegacyCustomBlockTemplate.fulfilled,
            (state, action) => {
                state.loading = 'fulfilled'
                state.legacyCustomBlockTemplates.splice(action.payload.index, 1)
                state.initialized = true
            }
        )
        builder.addCase(
            removeLegacyCustomBlockTemplate.rejected,
            (state, action) => {
                state.loading = 'rejected'
                state.error = action.error.message
                state.initialized = true
            }
        )
    },
})

const addBlockTemplatesToList = (
    state: AppStudioBlockTemplatesState,
    categorizedTemplates: CategorizedBlockTemplates[]
) => {
    // Flatten categories to a list
    const blockTemplatesFlatList = categorizedTemplates.reduce(
        (acc, category) => {
            return acc.concat(category.blockTemplates)
        },
        [] as PhoenixBlockTemplate[]
    )

    // Filter blockTemplates for duplicates within itself
    const uniqueBlockTemplates: PhoenixBlockTemplate[] = []
    blockTemplatesFlatList.forEach((block) => {
        // Check if the block is unique within itself, find all instances of the block
        const selfInstances = uniqueBlockTemplates.filter(
            (selfBlock) => selfBlock._id === block._id
        )

        let latestBlock: PhoenixBlockTemplate

        // If there is only one instance of the block, it is unique
        if (!selfInstances.length) {
            latestBlock = block
        } else {
            // If there are multiple instances of the block, get the latest updatedAt block
            latestBlock = selfInstances.reduce((latest, current) => {
                return latest.updatedAt > current.updatedAt ? latest : current
            })
        }

        // Check if the block is unique within the state, find all instances of the block in the state
        const stateInstances = state.blockTemplates.filter(
            (stateBlock) => stateBlock._id === block._id
        )

        // If there are no instances of the block in the state, it is unique
        if (stateInstances.length === 0) {
            uniqueBlockTemplates.push(latestBlock)
            return
        }

        // If there are instances of the block in the state, get the latest updatedAt block
        const latestStateBlock = stateInstances.reduce((latest, current) => {
            return latest.updatedAt > current.updatedAt ? latest : current
        })

        // If the block is newer than the latest block in the state, remove the old block and add the new block
        if (latestBlock.updatedAt > latestStateBlock.updatedAt) {
            state.blockTemplates = state.blockTemplates.filter(
                (stateBlock) => stateBlock._id !== block._id
            )
            uniqueBlockTemplates.push(latestBlock)
        } else {
            // If the block is older than the latest block in the state, do not add the block
            return
        }
    })

    // Update the blockTemplates in the state
    state.blockTemplates = state.blockTemplates.concat(uniqueBlockTemplates)
}

export const selectBlockTemplates = (
    state: RootState
): PhoenixBlockTemplate[] => {
    return state.appStudioBlockTemplate.blockTemplates
}

export const selectLegacyBlockTemplates = (state: RootState) => {
    return state.appStudioBlockTemplate.legacyCustomBlockTemplates
}

export const selectCustomBlockTemplates = (state: RootState) => {
    return state.appStudioBlockTemplate.customBlockTemplates
}

export const selectAllBlockTemplates = createSelector(
    [
        selectBlockTemplates,
        selectLegacyBlockTemplates,
        (state) => selectAllCBTs(state),
        selectAppId,
    ],
    (blockTemplates, legacyCustomBlocks, customBlockTemplates) => {
        return [
            ...blockTemplates,
            ...customBlockTemplates,
            ...legacyCustomBlocks,
        ]
    }
)

export const selectBlockBank = (
    state: RootState
): CategorizedBlockTemplates[] => {
    return state.appStudioBlockTemplate.blockBank
}
export default appStudioBlockTemplatesSlice.reducer
