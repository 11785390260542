import { enableMetafield, getVersionStatusByDevice } from 'services/parse'
import { errorToast } from 'components/common/Toasts'
import { Parse } from 'services/parse'

const formatConfigForSave = (currentConfig = {}, changes) => {
    let config = { ...currentConfig }
    if (changes.metafields) {
        config['shopify'] = {
            ...(config['shopify'] ?? {}),
            metafields: changes.metafields,
        }
    }
    return config
}

const enableMetafieldsOnShopify = async (metafields) => {
    metafields.map((item) => {
        enableMetafield(item.namespace, item.key, item.type)
    })
}

const versionsCheck = async (platforms) => {
    const appParseObj = await Parse.User.current().get('app')

    try {
        const [iosRes, androidRes] = await Promise.all([
            getVersionStatusByDevice(appParseObj, 'ios'),
            getVersionStatusByDevice(appParseObj, 'android'),
        ])

        return {
            ios: !!iosRes && !!platforms.ios?.appStoreId,
            android: !!androidRes && !!platforms.android?.appStoreId,
        }
    } catch (err) {
        errorToast('Could not load mobile app status')
    }
}

export { formatConfigForSave, enableMetafieldsOnShopify, versionsCheck }
