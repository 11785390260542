import Blob from 'components/common/Blob'
import FormWithErrors from 'components/common/FormWithErrors'
import FramelessLayout from 'components/layouts/Frameless'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Parse } from 'services/parse'
import styled from 'styled-components'
import { ContainerBox, Input } from 'tapcart-dashboard-components/build'
import Button from '@tapcartinc/tapcart-ui/dist/Button'
import { sofiaPro } from '@tapcartinc/tapcart-ui/dist/sofiaPro'
import { swatches } from '@tapcartinc/tapcart-ui/dist/swatches'
import usePasswordValidation from 'utils/hooks/usePasswordValidation'
import resetPasswordValidationSchema from 'utils/validation-schemas/reset-password-validation-schema'

// setup render prop for pswd validation hook
const GetPasswordValidation = ({ inputValue, children }) => {
    const state = usePasswordValidation(inputValue)
    return children(state)
}

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                password: '',
                confirmPassword: '',
            },
            username: '',
            app: '',
            token: '',
            id: '',
            passwordResetSuccess: false,
            passwordResetFailure: false,
        }
    }

    componentDidMount() {
        this.setState((prevState) => ({
            ...prevState,
            username: this.props.resetInfo.username,
            app: this.props.resetInfo.app,
            token: this.props.resetInfo.token,
            id: this.props.resetInfo.id,
        }))
        return this.props.history.replace('/reset-password', this.state)
    }

    resetPassword = (e) => {
        if (e) {
            e.preventDefault()
        }

        Parse.Cloud.run('resetPassword', {
            username: this.state.username,
            app: this.state.app,
            token: this.state.token,
            id: this.state.id,
            password: this.state.form.confirmPassword,
        })
            .then((res) => {
                if (res.message === 'Success') {
                    return this.setState((prevState) => ({
                        ...prevState,
                        passwordResetSuccess: true,
                    }))
                }
            })
            .catch((err) => {
                return this.setState((prevState) => ({
                    ...prevState,
                    passwordResetFailure: true,
                }))
            })
    }

    onChange = (event) => {
        let form = this.state.form
        form[event.target.name] = event.target.value
        this.setState({
            form: form,
        })
    }

    resetPasswordState = () => {
        this.setState((prevState) => ({
            ...prevState,
            form: {
                password: '',
                confirmPassword: '',
            },
            passwordResetSuccess: false,
            passwordResetFailure: false,
        }))
        return this.props.history.push({
            pathname: '/login',
            state: { passwordFlow: true },
        })
    }

    passwordResetContent = () => {
        if (
            !this.state.passwordResetFailure &&
            !this.state.passwordResetSuccess
        ) {
            return {
                header: 'Reset password',
                subheader: 'Manage your app',
                footer: '',
            }
        } else if (
            this.state.passwordResetSuccess &&
            !this.state.passwordResetFailure
        ) {
            return {
                header: 'Password Reset Success',
                subheader: '',
                footer: (
                    <div>
                        We have successful reset your password. Please{' '}
                        <Link style={{ color: swatches.blue }} to="/login">
                            login
                        </Link>{' '}
                        with your new password to access your account.
                    </div>
                ),
            }
        } else if (
            !this.state.passwordResetSuccess &&
            this.state.passwordResetFailure
        ) {
            return {
                header: 'Password Reset Failure',
                subheader: '',
                footer: (
                    <div style={{ display: 'flex' }}>
                        There was an error resetting your password please&nbsp;
                        <StyledTryAgain onClick={this.resetPasswordState}>
                            try again
                        </StyledTryAgain>
                        .
                    </div>
                ),
            }
        }
    }

    isSubmitEnabled = () =>
        this.state.form.password.length &&
        this.state.form.confirmPassword.length &&
        this.state.form.password === this.state.form.confirmPassword

    render() {
        return (
            <FramelessLayout
                title="Tapcart - Turn your Shopify store into an iPhone app."
                description="Tapcart turns your Shopify store into a custom iPhone app instantly. Choose from dozens of themes, import your inventory and start selling on the appstore."
            >
                <div className="login-page">
                    <Blob
                        src="/img/login/oval-oval-copy-3-mask.svg"
                        top
                        left
                        alt="blob"
                    />
                    <Blob
                        src="/img/login/oval-oval-copy-mask.svg"
                        bottom
                        right
                        alt="blob"
                    />

                    <StyledModalCenter>
                        <img
                            className="login-logo"
                            src="/img/logo-tapcart-white.svg"
                            alt=""
                        />
                        <StyledLoginContainer>
                            <BottomSVG />

                            <StyledModalHeader>
                                {this.passwordResetContent().header}
                            </StyledModalHeader>
                            <StyledModalSubheader>
                                {this.passwordResetContent().subheader}
                            </StyledModalSubheader>

                            <FormWithErrors
                                validationSchema={resetPasswordValidationSchema}
                                fields={this.state.form}
                                submitForm={this.resetPassword}
                            >
                                {({ errors, submitFormWithValidation }) => {
                                    return (
                                        <>
                                            <div>
                                                <Input
                                                    type="password"
                                                    placeholder="Enter New Password"
                                                    value={
                                                        this.state.form.password
                                                    }
                                                    onChange={this.onChange}
                                                    name="password"
                                                    label="New Password"
                                                    errors={
                                                        errors &&
                                                        errors['password']
                                                    }
                                                />
                                                <GetPasswordValidation
                                                    inputValue={
                                                        this.state.form.password
                                                    }
                                                >
                                                    {({ renderValidation }) =>
                                                        renderValidation()
                                                    }
                                                </GetPasswordValidation>
                                                <br />

                                                <Input
                                                    type="password"
                                                    placeholder="Confirm New Password"
                                                    value={
                                                        this.state.form
                                                            .confirmPassword
                                                    }
                                                    onChange={this.onChange}
                                                    name="confirmPassword"
                                                    label="Confirm Password"
                                                    errors={
                                                        errors &&
                                                        errors[
                                                            'confirmPassword'
                                                        ]
                                                    }
                                                />
                                            </div>

                                            {!this.state.passwordResetSuccess &&
                                                !this.state
                                                    .passwordResetFailure && (
                                                    <StyledBottomForgotPasswordRow>
                                                        <Button
                                                            onClick={
                                                                submitFormWithValidation
                                                            }
                                                            disabled={
                                                                !this.isSubmitEnabled()
                                                            }
                                                            type="button"
                                                        >
                                                            Update password
                                                        </Button>
                                                    </StyledBottomForgotPasswordRow>
                                                )}
                                            {this.passwordResetContent()
                                                .footer && (
                                                <StyledResetBottomRow>
                                                    {
                                                        this.passwordResetContent()
                                                            .footer
                                                    }
                                                </StyledResetBottomRow>
                                            )}
                                        </>
                                    )
                                }}
                            </FormWithErrors>
                        </StyledLoginContainer>
                    </StyledModalCenter>
                </div>
            </FramelessLayout>
        )
    }
}

export default withRouter(ResetPasswordPage)

const BottomSVG = () => {
    return (
        <StyledSvg
            width="100%"
            viewBox="0 0 477 11"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="1.0-Login"
                    transform="translate(-482.000000, -717.000000)"
                    fill={swatches.success}
                >
                    <g
                        id="Login-form"
                        transform="translate(482.000000, 297.000000)"
                    >
                        <g id="Path-9">
                            <path
                                d="M-60,420 L523,420 L523,438.890356 C350.385637,441.703215 234.392928,441.703215 175.021875,438.890356 C118.812707,436.227299 39.6027103,438.890356 -52.8972897,435.90513 C-56.0203211,435.804341 -58.3878912,436.799416 -60,438.890356 L-60,420 Z"
                                transform="translate(231.500000, 430.500000) scale(1, -1) translate(-231.500000, -430.500000) "
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </StyledSvg>
    )
}

const StyledTryAgain = styled.p`
    text-decoration: underline;
    color: ${swatches.blue};

    &:hover {
        cursor: pointer;
    }
`

const StyledResetBottomRow = styled.div`
    display: flex;
    margin-top: 60px;
    justify-content: center;
    ${sofiaPro.medium}
    font-style: normal;
    font-display: swap;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
`

const StyledModalCenter = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledSvg = styled.svg`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
`

const StyledLoginContainer = styled(ContainerBox)`
    background: white;
    max-width: 500px;
    padding: 50px;
    padding-bottom: 40px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;

    .login-logo {
        max-width: 150px;
        margin-bottom: 25px;
    }

    input {
        margin-bottom: 12px;
    }
`

const StyledBottomRow = styled.div`
    display: flex;
    margin-top: 60px;
    align-items: center;
    justify-content: space-between;
`

const StyledModalHeader = styled.h1`
    color: ${swatches.blue};
    text-align: center;
    font-size: 25px;
    ${sofiaPro.medium}
`

const StyledModalSubheader = styled.h1`
    color: ${swatches.gray};
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 25px;
    margin-top: 15px;
    ${sofiaPro.medium}
`

const StyledBottomForgotPasswordRow = styled(StyledBottomRow)`
    justify-content: flex-end;
    margin-top: 80px;
`
