import { Parse } from 'services/parse'

const BEAMER_URL = 'https://app.getbeamer.com/js/beamer-embed.js'

const BEAMER_ID = import.meta.env.VITE_TC_SHOPIFY_CLIENT_ID

let scriptRef: HTMLScriptElement | null = null

export const loadBeamer = async ({ subscription }): Promise<void> => {
    if (!BEAMER_ID) {
        console.warn(
            '[Beamer] - In order to use Beamer you need to add an appID'
        )
        return
    }

    const user = await Parse.User.current()

    const isAdmin = user?.get('ACL')?.permissionsById?.['role:admin']?.write

    const appId = Parse.User.current().attributes.app.id

    window.beamer_config = {
        product_id: import.meta.env.VITE_TC_BEAMER_ID,
        selector: 'beamer-trigger',
        display: 'right',
        top: '25%',
        left: '100px',
        user_id: user?.id,
        lazy: true,
        button: false,
        counter: true,
        notification_prompt: 'none',
        bounce: false,
        first_visit_unread: 0,
        alert: true,
        filter: `${subscription.tier};${subscription.status};${appId}`,
    }

    if (isAdmin) {
        window.beamer_config.filter =
            window?.beamer_config.filter.concat(`;is_admin`)
    }

    scriptRef = document.createElement('script')
    scriptRef.type = 'text/javascript'
    scriptRef.defer = true
    scriptRef.src = BEAMER_URL
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript?.parentNode?.insertBefore(scriptRef, firstScript)

    scriptRef.addEventListener('load', () => window?.Beamer?.init(), {
        once: true,
    })
}
