import { Parse } from 'services/parse'
import tapcartAnalytics from './index'
import { detectDashboardType } from '../../utils/helpers'

export function trackPageView() {
    // ...don't track after log out event...
    if (!Parse.User.current()) return

    tapcartAnalytics.logEvent('page-view', {
        title: window.location.pathname,
    })
}

export function trackDashboardVisited(search) {
    // don't need to track a new 'dashboard-visited' event for internally linked pages
    const params = new URLSearchParams(search)
    if (params.get('internal-link')) return


    tapcartAnalytics.logEvent('dashboard-visited', {
        type: detectDashboardType(),
    })
    window.sessionStorage.setItem('amplitude-unique-session', 'false')
}
