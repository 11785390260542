/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */

/** Required Fields: 
- type: text | toggle
- name: string
- variable: string (generally with split form fields ['form.formField'])
*/
const characterValidation = /[ !"#$%&'()*+,/:;<=>?@[\\\]^_`{|}~\-]/

export const validateMerchantInputs = (data) => {
    if (!data) return []
    const errors = []

    if (!Array.isArray(data)) {
        errors.push('Merchant input must be formatted as an array of objects')
    }

    Array.isArray(data) &&
        data.map((field) => {
            const nameErr = 'Merchant input field missing name property'
            const typeErr =
                'Merchant input fields require a type property of either text or toggle'
            const variableErr = 'Merchant input fields require a variable'
            if (!field.name && !errors.includes(nameErr)) {
                errors.push(nameErr)
            }
            if (!field.type && !errors.includes(typeErr)) {
                errors.push(typeErr)
            }
            if (!field.variable && !errors.includes(variableErr)) {
                errors.push(variableErr)
            }
            if (
                field.variable.includes(' ') ||
                characterValidation.test(field.variable)
            ) {
                errors.push(
                    'Variables cannot include any spaces or symbols besides a period'
                )
            }
            if (field.type !== 'text' && field.type !== 'toggle') {
                errors.push('Field type can only be text or toggle')
            }
        })

    return errors
}
