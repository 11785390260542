import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import media from '../../../styles/breakpoints/_media'

const BlobWrapper = styled.img`
    position: absolute;
    z-index: 0;
    transition: 400ms ease-in;
    max-width: ${(properties) => (properties.maxWidth ? `${properties.maxWidth}px` : '100%')};
    max-height: 80%;
    top: ${(properties) => properties.top && 0};
    bottom: ${(properties) => properties.bottom && 0};
    left: ${(properties) => properties.left && 0};
    right: ${(properties) => properties.right && 0};
    ${(properties) => properties.mobileOnly && `display: none`};
    ${media.sm`
  ${(properties) => properties.mobileOnly && `display: block`};
  ${(properties) => properties.desktopOnly && `display: none`};
  `}
`

/**
 * Place all of the `StyledBlob`s of each section underneath the `Section` components for propper placement and consistentsy
 * This applies to both `StyledBlobs`s being rendered on desktop and mobile screens
 *
 * ```
 * const example = () => (
    <ThemeProvider>
      <Section>
        <StyledBlob top left desktopOnly maxWidth={250} />
         <Container>
            ...
          </Container>
      </Section>
    </ThemeProvider>
  )
 * ```
 */

const Blob = (properties) => <BlobWrapper {...properties} />

export default Blob

Blob.propTypes = {
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    src: PropTypes.string.isRequired,
    maxWidth: PropTypes.number,
    alt: PropTypes.string.isRequired,
    desktopOnly: PropTypes.bool,
    mobileOnly: PropTypes.bool,
}

Blob.defaultProps = {
    top: false,
    bottom: false,
    left: false,
    right: false,
    maxWidth: null,
    desktopOnly: false,
    mobileOnly: false,
}
