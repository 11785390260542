/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { RootState } from 'store'
import { selectBillingLink } from 'store/BillingLinks'
import { BillingLink } from 'types/api-types'

type Charge = {
    _id: string
    chargeId: string
    planType: string
    appId: string
    activated: boolean
    createdAt: string
    updatedAt: string
}

const apiUrl = import.meta.env.VITE_TC_BACKEND_API as string
interface ChargeState {
    data: Charge | Record<string, never>
    loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: ChargeState = {
    data: {},
    loading: 'idle',
    error: null,
    initialized: false,
}

interface JSONResponse extends Response {
    json(): Promise<Charge>
}

export const getChargeIdFromBillingLinkUrl = (url: string): string =>
    url.split('/')[6]

// Used to reduce number of calls
let promise: Promise<JSONResponse>

export const fetchCharge = createAsyncThunk(
    'charges/fetchCharge',
    async (_, { getState }): Promise<Charge> => {
        const state = getState() as RootState

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        if (!promise) {
            const billingLink = selectBillingLink(state) as BillingLink
            const chargeId = getChargeIdFromBillingLinkUrl(billingLink.link)

            const appId = state.app.data.id

            const headers = await getHeaders()
            promise = fetch(`${apiUrl}/charges/${chargeId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'app-id': appId,
                    ...headers,
                },
            })
        }

        const response = await promise
        const res = await response.json()

        return res
    }
)

const chargesSlice = createSlice({
    name: 'charges',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCharge.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchCharge.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            state.data = action.payload
            state.initialized = true
        })
        builder.addCase(fetchCharge.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
    },
})

export const selectIsChargesLoading = (state: RootState) =>
    state.charges.loading === 'pending'

export const selectChargeInitialized = (state: RootState) =>
    state.charges.initialized

export const selectCharge = (state: RootState) => state.charges.data

export default chargesSlice.reducer
