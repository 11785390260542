export class ActivateChargeError extends Error {
    constructor(message) {
        super(message)
        this.name = 'ActivateChargeError'
    }
}

export class CreatePlatformsError extends Error {
    constructor(message) {
        super(message)
        this.name = 'CreatePlatformsError'
    }
}