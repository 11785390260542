// This slice contains all integrations, including non-published ones for use in Integration Blocks
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { fetchIntegrations } from './integrations-slice'

const allIntegrationsAdapter = createEntityAdapter({
    selectId: (integration) => integration.internalName,
})

export const allIntegrationsSlice = createSlice({
    name: 'allIntegrations',
    initialState: {
        ...allIntegrationsAdapter.getInitialState(),
        initialized: false,
    },
    extraReducers: {
        [fetchIntegrations.pending]: (state, action) => {
            state.loading = 'pending'
        },
        [fetchIntegrations.fulfilled]: (state, action) => {
            allIntegrationsAdapter.upsertMany(
                state,
                action.payload.all.entities.integrations || []
            )
            state.loading = 'fulfilled'
            state.error = null
            state.initialized = true
        },
        [fetchIntegrations.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
        },
    },
})

export const {
    fetchStarted: fetchAllIntegrationsStarted,
    setIntegrations: setAllIntegrations,
    setError: setAllIntegrationsError,
    editIntegrations: editAllIntegrations,
} = allIntegrationsSlice.actions

export default allIntegrationsSlice.reducer
