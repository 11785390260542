/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { verifyShopifyInstallStatus } from 'utils/helpers/shopify'

export const verifyInstallStatus = createAsyncThunk(
    'tapcartMerchant/verifyInstallStatus',
    async ({ shopifyStore, shopifyApiKey }, { getState }) => {
        const { app } = getState()
        const { redacted } = app?.data

        const res = await verifyShopifyInstallStatus(
            shopifyStore,
            shopifyApiKey
        )
        const unableToConnectToShopify = !res.ok

        // On uninstall a hook will be sent to redact a shop after 2 days.
        // When this happens we do not want to make API calls to SF.
        if (
            res.status === 401 ||
            res.status === 402 ||
            res.status === 403 ||
            redacted
        ) {
            return {
                tapcartUninstalled: true,
                unableToConnectToShopify: unableToConnectToShopify,
            }
        }

        return {
            tapcartUninstalled: false,
            unableToConnectToShopify: unableToConnectToShopify,
        }
    }
)

export const tapcartMerchantSlice = createSlice({
    name: 'tapcartMerchant',
    initialState: {
        loading: 'idle',
        data: {},
        error: undefined,
        initialized: false,
    },
    extraReducers: {
        [verifyInstallStatus.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
            state.error = action.error
            state.data.tapcartUninstalled = true
        },
        [verifyInstallStatus.pending]: (state) => {
            state.loading = 'pending'
        },
        [verifyInstallStatus.fulfilled]: (state, action) => {
            state.data = { ...state.data, ...action.payload }
            state.loading = 'fufilled'
            state.error = undefined
            state.initialized = true
        },
    },
})

export const selectTapcartMerchantState = (state) => state.tapcartMerchant.data

export const selectDashboardInitialized = (state) =>
    state.settings.initialized &&
    state.app.initialized &&
    state.tapcartMerchant.initialized &&
    state.user.initialized &&
    state.platforms.initialized &&
    state.subscription.initialized &&
    state.integrationBlocks.initialized

export const selectFrozenOrUninstalled = (state) =>
    state.tapcartMerchant.data.tapcartUninstalled ||
    state.app.data.frozen ||
    state.tapcartMerchant.data.unableToConnectToShopify

export default tapcartMerchantSlice.reducer
