import DeepDiff from 'deep-diff'
import flatten from 'lodash/flatten'
import tapcartAnalytics from './index'

const convertToAmplitudeValues = (listing) => ({
    'app-title': listing.title,
    'primary-category': listing.primaryCategory,
    'secondary-category': listing.secondaryCategory,
    'app-keywords': listing.keywords,
})

const trackAppStoreListing = (values) => {
    const { originalListing, listing } = values

    let changedFields = []
    const listingDiff = DeepDiff(originalListing, listing)

    // The first time they set the primary category all fields are required, fire complete
    if (!originalListing.primaryCategory && listing.primaryCategory) {
        tapcartAnalytics.logEvent('listing-complete')
    }

    if (listingDiff) {
        for (const change of listingDiff) {
            changedFields.push(change.path)
            changedFields = flatten(changedFields)
        }
        if (changedFields.length > 0) {
            tapcartAnalytics.setUserProperties(
                convertToAmplitudeValues(listing)
            )
            tapcartAnalytics.logEvent('listing-updated', {
                fields: changedFields,
            })
        }
    }
}

export default trackAppStoreListing
