/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

interface AlertModalState {
    show: boolean
    type: string
}

const initialState: AlertModalState = {
    show: false,
    type: '',
}

const alertModalSlice = createSlice({
    name: 'alertModal',
    initialState,
    reducers: {
        showModal: (state, action) => {
            state.show = true
            state.type = action.payload
        },
        hideModal: (state) => {
            state.show = false
            state.type = ''
        },
        setModalType: (state, action) => {
            state.show = true
            state.type = action.payload
        },
    },
})

export const { showModal, hideModal } = alertModalSlice.actions

export default alertModalSlice.reducer
