// param reasignment is supported in Redux-Toolkit reducers by the Immer library
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'
import { BadgesState, BadgesStateData, BadgesStateDataObject } from './state'
import {
    BadgesAPIResponse,
    FetchBadgesResponse,
    UpdateBadgesRequest,
    UpdateBadgesResponse,
} from './api-types'
import { Badge } from './types'

const badgesAPI = import.meta.env.VITE_TC_BADGES_API as string

const initialState: BadgesState = {
    data: {
        badgesArray: [],
        pageConfig: {
            plp: false,
            pdp: false,
        },
    },
    loading: 'idle',
    error: undefined,
    initialized: false,
}

const mapAPIResponseToStateData = (
    response: BadgesAPIResponse
): BadgesState['data'] => ({
    badgesArray: response.badges,
    pageConfig: {
        pdp: response.pageConfig.pdp.enabled,
        plp: response.pageConfig.plp.enabled,
    },
})

export const fetchBadges = createAsyncThunk<
    // Return type of the payload creator
    FetchBadgesResponse,
    // First argument to the payload creator
    void,
    // Types for ThunkAPI
    { state: RootState }
>('badges/fetchBadges', async (_, { getState }) => {
    const { app } = getState()

    // TODO: App data should have a better defined type
    const appData = app.data as { id: string }
    const appId = appData.id
    const headers = await getHeaders()

    const response = await typedRequest<FetchBadgesResponse>(
        `${badgesAPI}${appId}`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    return response
})

export const updateBadges = createAsyncThunk<
    UpdateBadgesResponse,
    BadgesStateData,
    { state: RootState }
>('badges/updateBadges', async (badgesStateData, { getState }) => {
    const { app } = getState()

    const requestBody: UpdateBadgesRequest = {
        badges: badgesStateData.badgesArray,
        pageConfig: {
            pdp: { enabled: badgesStateData.pageConfig.pdp },
            plp: { enabled: badgesStateData.pageConfig.plp },
        },
    }
    // TODO: App data should have a better defined type
    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()
    const response = await typedRequest<UpdateBadgesResponse>(
        `${badgesAPI}${appId}`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        }
    )

    return response
})

const badgesSlice = createSlice({
    name: 'badges',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBadges.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchBadges.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'
            state.data = mapAPIResponseToStateData(payload)
            state.initialized = true
        })
        builder.addCase(fetchBadges.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(updateBadges.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateBadges.fulfilled, (state, { payload }) => {
            state.loading = 'fulfilled'
            state.data = mapAPIResponseToStateData(payload)
        })
        builder.addCase(updateBadges.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
    },
})

export const selectBadgesState = (state: RootState): BadgesStateData => ({
    badgesArray: state.badges.data.badgesArray,
    pageConfig: {
        pdp: state.badges.data.pageConfig.pdp,
        plp: state.badges.data.pageConfig.plp,
    },
})

export const selectBadgesObject = (state: RootState): BadgesStateDataObject =>
    state.badges.data.badgesArray.reduce((acc, badge) => {
        acc[badge.id] = badge
        return acc
    }, {} as Record<string, Badge>)

export default badgesSlice.reducer
