/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { Parse } from 'services/parse'

const parseApiUrl = import.meta.env.VITE_TC_PARSE_API;
const baseApiUrl = parseApiUrl.substring(0, parseApiUrl.lastIndexOf('/'));

export const fetchBillingCustomerData = createAsyncThunk(
		'billingCustomer/fetchBillingCustomerData',
		async (_, { getState }) => {
				const headers = await getHeaders()
				const appId = Parse.User.current().attributes.app.id

				try {
						const response = await fetch(
                            `${baseApiUrl}/billing/customer-by-appId/${appId}`,
                            {
                                method: 'GET',
                                headers: {
                                    ...headers,
                                    'response-schema-normalized': 'true',
                                },
                                'app-id': appId,
                            }
                        )

						return response.status === 204 ? {} : response.json();
				} catch (error) {
						throw new Error(`Unable to fetch billing quote data: ${error}`)
				}
		}
)

export const billingCustomerSlice = createSlice({
		name: 'billingCustomer',
		initialState: {
				loading: 'idle',
				data: undefined,
				error: undefined,
				initialized: false,
		},
		reducers: {},
		extraReducers: (builder) => {
				builder.addCase(fetchBillingCustomerData.pending, (state) => {
						state.loading = 'pending'
				});
				builder.addCase(fetchBillingCustomerData.fulfilled, (state, action) => {
						state.data = action.payload
						state.loading = 'fufilled'
						state.error = undefined
						state.initialized = true
				});
				builder.addCase(fetchBillingCustomerData.rejected, (state, action) => {
						state.loading = 'rejected'
						state.initialized = true
						state.error = action.error
				});
		},
})

export const selectBillingCustomer = (state) => state.customers.data

export const selectBillingCustomerLoading = (state) =>
		Boolean(state.customers.loading === 'pending')

export const selectBillingCustomerInitialized = (state) =>
		Boolean(state.customers.initialized)

export default billingCustomerSlice.reducer