import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { Parse } from 'services/parse'
import { MobileInstallBanner } from './types'
import { MIBSettingsSchema } from 'pages/Capture/data/types'

const captureKitApi = import.meta.env.VITE_TC_CAPTURE_API

type MobileInstallBannerState = {
    data: MobileInstallBanner | null
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: MobileInstallBannerState = {
    data: null,
    loading: 'idle',
    error: null,
    initialized: false,
}

export const fetchMobileInstallBanner = createAsyncThunk(
    'captureKit/fetchMobileInstallBanner',
    async (_, { getState }) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        try {
            const response = await fetch(
                `${captureKitApi}/mobile-download-banner/?appId=${appId}`, // Route was defined before the name change to mobile-install-banner
                {
                    method: 'GET',
                    headers: {
                        ...headers,
                        'app-id': appId,
                        'Content-Type': 'application/json',
                    },
                }
            )
            return response.json()
        } catch (error) {
            throw new Error(
                `Unable to retrieve mobile install banner data. ${error}`
            )
        }
    }
)

export const saveMobileInstallBanner = createAsyncThunk(
    'captureKit/saveMobileInstallBanner',
    async (data: MIBSettingsSchema, { getState }) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        try {
            const response = await fetch(
                `${captureKitApi}/mobile-download-banner/`,
                {
                    method: 'PUT',
                    headers: {
                        ...headers,
                        'app-id': appId,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...data, appId }),
                }
            )
            return response.json()
        } catch (error) {
            throw new Error(
                `Unable to save mobile install banner data. ${error}`
            )
        }
    }
)

const mobileInstallBannerSlice = createSlice({
    name: 'mobile-install-banner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMobileInstallBanner.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchMobileInstallBanner.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(fetchMobileInstallBanner.rejected, (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
            state.error = action.error
        })
        builder.addCase(saveMobileInstallBanner.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(saveMobileInstallBanner.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = null
        })
        builder.addCase(saveMobileInstallBanner.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
    },
})

export const selectMobileInstallBannerData = (state: any) =>
    state.mobileInstallBanner.data

export const selectMobileInstallBannerLoading = (state: any) =>
    Boolean(state.mobileInstallBanner.loading === 'pending')

export default mobileInstallBannerSlice.reducer
