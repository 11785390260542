/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    createBannerScript,
    getBannerStatus,
    removeBannerScript,
    updateBannerScript,
} from 'services/parse'
import { RootState } from 'store'

type LoadingStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected'

interface ScriptData {
    enabled: boolean
    id: number
    src: string
    event: string
    created_at: string
    updated_at: string
    display_scope: string
}

interface ScriptState {
    loading: LoadingStatus
    data: ScriptData
    error: unknown
    initialized: boolean
}

const scriptBaseUrl = 'https://cdn.tapcart.com/webbridge-sdk/webbridge.umd.js'

// Thunks
export const createScript = createAsyncThunk(
    'webbridgeScript/createScript',
    async (_, { getState }): Promise<ScriptData> => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const { script } = await createBannerScript(appId, null, scriptBaseUrl)

        // Wait for the script to load on the Shopify site
        await new Promise((resolve) => setTimeout(resolve, 30000))

        return { ...script, enabled: typeof script !== 'undefined' }
    }
)

export const readScript = createAsyncThunk(
    'webbridgeScript/readScript',
    async (_, { getState }): Promise<ScriptData> => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const { script } = await getBannerStatus(appId, scriptBaseUrl)
        return { ...script, enabled: typeof script !== 'undefined' }
    }
)

export const updateScript = createAsyncThunk(
    'webbridgeScript/updateScript',
    async (_, { getState }): Promise<ScriptData> => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const { script } = await updateBannerScript(appId, scriptBaseUrl)

        return { ...script, enabled: typeof script !== 'undefined' }
    }
)

export const deleteScript = createAsyncThunk(
    'webbridgeScript/deleteScript',
    async (_, { getState }): Promise<ScriptData> => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const { id } = state.webbridgeScript.data
        await removeBannerScript(appId, { id })
        // Wait  for the script to be removed on the Shopify site
        await new Promise((resolve) => setTimeout(resolve, 30000))
        return { enabled: false }
    }
)

// Initial state
const initialState: ScriptState = {
    loading: 'idle',
    data: {
        enabled: false,
        id: 0,
        src: '',
        event: '',
        created_at: '',
        updated_at: '',
        display_scope: '',
    },
    error: undefined,
    initialized: false,
}

export const webbridgeScriptSlice = createSlice({
    name: 'webbridgeScript',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createScript.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(
                createScript.fulfilled,
                (state, action: PayloadAction<ScriptData>) => {
                    state.data = action.payload
                    state.loading = 'fulfilled'
                    state.error = undefined
                }
            )
            .addCase(createScript.rejected, (state) => {
                state.loading = 'rejected'
            })
            .addCase(readScript.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(readScript.fulfilled, (state, action) => {
                state.data = action.payload
                state.loading = 'fulfilled'
                state.error = undefined
                state.initialized = true
            })
            .addCase(readScript.rejected, (state) => {
                state.loading = 'rejected'
                state.initialized = true
            })
            .addCase(deleteScript.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(deleteScript.fulfilled, (state, action) => {
                state.data = action.payload
                state.loading = 'fulfilled'
                state.error = undefined
            })
            .addCase(deleteScript.rejected, (state) => {
                state.loading = 'rejected'
            })
    },
})

// Selectors
export const selectWebbridgeScriptEnabled = (state: RootState): boolean =>
    Boolean(state.webbridgeScript.data.enabled)

export const selectWebbridgeScriptInitialized = (state: RootState): boolean =>
    Boolean(state.webbridgeScript.initialized)

export const selectWebbridgeScriptLoading = (state: RootState): boolean =>
    state.webbridgeScript.loading === 'pending'

export default webbridgeScriptSlice.reducer
