// Will update to 4 options after release of phase 1
const toolbarOptions = [
    { value: 'preview', label: 'Preview' },
    { value: 'settings', label: 'Settings' },
]
export const releaseNoteOption = {
    value: 'change-log',
    label: 'Change Log',
}
export default toolbarOptions
