const variableRegex = /(\$.*?\;)/gi

const cssWithTokenValues = (css, colorTokens) => {
    const replaceVariables = css.replace(variableRegex, (m, i) => {
        const variable = m.split('-')
        const segment = variable[3]
        const key = variable[4].slice(0, Math.max(0, variable[4].length - 1))
        const value = colorTokens?.[segment]?.[key]
        return value
    })

    return replaceVariables
}

export default cssWithTokenValues
