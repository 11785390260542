import { combineReducers } from '@reduxjs/toolkit'
import workspaceSettingsReducer from 'pages/CustomBlocks/features/workspace-settings/workspace-settings-slice'
import projectsReducer from 'pages/CustomBlocks/features/projects/projects-slice'
import previewReducer from 'pages/CustomBlocks/features/preview/preview-slice'
import editorReducer from 'pages/CustomBlocks/features/editor/editor-slice'

const customBlocksWorkspaceReducer = combineReducers({
    workspaceSettings: workspaceSettingsReducer,
    projects: projectsReducer,
    preview: previewReducer,
    editor: editorReducer,
})

export default customBlocksWorkspaceReducer
