import { normalize } from 'normalizr'
import {
    PaymentMethodsFormField,
    PaymentMethod,
    PaymentMethodType,
} from 'types/forms/settings-types'
import { settingsSchema } from '../_schemas'

export function formatSettingsResponse(settingsParseObject) {
    const settings = {
        appClip: settingsParseObject.attributes.appClip,
        id: settingsParseObject.id,
        filters: settingsParseObject.attributes.filters,
        installmentPayment: settingsParseObject.attributes.installmentPayment,
        integrations: settingsParseObject.attributes.integrations,
        menu: settingsParseObject.attributes.menu,
        multiBlockSections:
            settingsParseObject.attributes?.multiBlockSections || [],
        navigation: settingsParseObject.attributes.navigation,
        paymentMethods: settingsParseObject.attributes.paymentMethods || [],
        pdpBlocks: settingsParseObject.attributes.pdpBlocks,
        themeOptions: settingsParseObject.attributes.themeOptions,
        config: settingsParseObject.attributes.config,
        blocks: settingsParseObject.attributes.blocks,
    }
    const normalized = normalize(settings, settingsSchema)
    return normalized
}

export function getUpdatedThemeOptions(themeOptions, updatedValues) {
    return {
        ...themeOptions,
        ...updatedValues,
    }
}

export const formatSquareImages = (value) => {
    if (value.value === 'square') return true
    return false
}

export const shapePaymentMethodsForParse = (
    obj: PaymentMethodsFormField
): PaymentMethod[] => {
    const shapedArray = [] as PaymentMethod[]
    Object.entries(obj).forEach((payment) => {
        if (payment[1]) {
            const shapedPayment: PaymentMethod = {
                name: payment[0] as PaymentMethodType,
                image: '/test.png',
            }
            shapedArray.push(shapedPayment)
        }
    })
    return shapedArray
}
