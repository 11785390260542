/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import { Parse } from 'services/parse'
import { ParseUser } from 'types/api-types'
import { User } from 'types/dashboard-types'

interface UserState {
    data: User | Record<string, never>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: UserState = {
    data: {},
    loading: 'idle',
    error: null,
    initialized: false,
}

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
    const userParseObject: ParseUser = await Parse.User.current()

    const {
        email,
        firstName,
        lastName,
        phone,
        salesforceId,
        sessionToken,
        username,
    } = userParseObject.attributes
    return {
        email,
        firstName,
        lastName,
        phone,
        salesforceId,
        sessionToken,
        username,
        id: userParseObject.id,
        createdAt: userParseObject.createdAt,
    }
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
    },
})

export const selectChiliPiperFormFields = (state: {
    user: { data: User }
}): Record<string, string> => {
    const { firstName, lastName, email } = state.user.data
    return {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
    }
}

export const selectUser = (state: { user: { data: User } }): User =>
    state.user.data

export default userSlice.reducer
