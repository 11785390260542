export const validateJson = async (content) => {
    try {
        const validate = await JSON.parse(content)
        return validate
    } catch (error) {
        console.log('error', error)

        return error
        // TODO: Error handling in another ticket
    }
}
