import * as yup from 'yup'

yup.addMethod(yup.string, 'newPasswordCheck', function (arguments_) {
    const message = arguments_
    return this.test('password-match', message, function (value) {
        const { path, createError, parent } = this

        if (value !== parent.password) {
            return createError({ path, message })
        }
        return true
    })
})

const resetPasswordValidationSchema = yup.object().shape({
    password: yup.string().required('Enter new password'),
    confirmPassword: yup
        .string()
        .required('Password confirmation is required')
        .newPasswordCheck("Password doesn't match"),
})

export default resetPasswordValidationSchema
