import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'
import { AppStudioPage } from './types/pages'
import { AppStudioScreenType, PhoenixLayout } from './types/layouts'

const baseAPIUrl = import.meta.env.VITE_TC_BACKEND_API as string
const appStudioAPIUrl = `${baseAPIUrl}/v1/app-studio`

type AppStudioPageState = {
    pages: AppStudioPage[]
    categorizedPages: Partial<Record<AppStudioScreenType, AppStudioPage[]>>
    loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
    error?: string
    initialized: boolean
}

const initialState: AppStudioPageState = {
    pages: [],
    categorizedPages: {},
    loading: 'idle',
    initialized: false,
}

type FetchPageByIdResponse = {
    page: AppStudioPage
}
export const fetchPageById = createAsyncThunk<
    AppStudioPage,
    string,
    { state: RootState }
>('app-studio-pages/fetchPageById', async (pageId, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<FetchPageByIdResponse>(
        `${appStudioAPIUrl}/pages/${appId}/${pageId}`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    if (!response) {
        throw new Error('Failed to fetch page')
    }

    const page = response.page

    return page
})

type FetchPagesByScreenTypeResponse = {
    pages: AppStudioPage[]
}
export const fetchPagesByScreenType = createAsyncThunk<
    // Return type of the payload creator
    AppStudioPage[],
    // First argument to the payload creator
    { screenType: AppStudioScreenType },
    // Types for ThunkAPI
    { state: RootState }
>(
    'app-studio-pages/fetchPagesByScreenType',
    async ({ screenType }, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<FetchPagesByScreenTypeResponse>(
            `${appStudioAPIUrl}/pages/${appId}?screenType=${screenType}`,
            {
                method: 'GET',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response || !response.pages?.length) {
            throw new Error('Failed to fetch block bank')
        }

        return response.pages
    }
)

type CreatePageResponse = {
    page: AppStudioPage
    layout: PhoenixLayout
}
export const createPage = createAsyncThunk<
    CreatePageResponse,
    {
        screenType: AppStudioScreenType
        title?: string
        contentType?: 'list' | 'web'
    },
    { state: RootState }
>(
    'app-studio-pages/createPage',
    async ({ screenType, title, contentType }, { getState }) => {
        const { app } = getState()

        const appData = app.data as { id: string }
        const appId = appData.id

        const headers = await getHeaders()

        const response = await typedRequest<CreatePageResponse>(
            `${appStudioAPIUrl}/pages/${appId}`,
            {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ screenType, title, contentType }),
            }
        )

        if (!response || !response?.page) {
            throw new Error('Failed to create page')
        }

        return response
    }
)

export const updatePage = createAsyncThunk<
    {
        page: AppStudioPage
    },
    {
        title?: string
        pageId: string
    },
    { state: RootState }
>('app-studio-pages/updatePage', async ({ title, pageId }, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<CreatePageResponse>(
        `${appStudioAPIUrl}/pages/${appId}/${pageId}`,
        {
            method: 'PUT',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title }),
        }
    )

    if (!response || !response?.page) {
        throw new Error('Failed to create page')
    }

    return response
})

type ArchivePageResponse = {
    page: AppStudioPage
}
export const archivePage = createAsyncThunk<
    ArchivePageResponse,
    string,
    { state: RootState }
>('app-studio-pages/archivePage', async (pageId, { getState }) => {
    const { app } = getState()

    const appData = app.data as { id: string }
    const appId = appData.id

    const headers = await getHeaders()

    const response = await typedRequest<ArchivePageResponse>(
        `${appStudioAPIUrl}/pages/${appId}/${pageId}/archive`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    )

    if (!response || !response?.page) {
        throw new Error('Failed to archive page')
    }

    return response
})

const appStudioPagesSlice = createSlice({
    name: 'app-studio-pages',
    initialState,
    reducers: {
        initializeBlockBank(state) {
            state.initialized = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPageById.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchPageById.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            const page = action.payload

            addPageToState(state, page)

            state.initialized = true
        })
        builder.addCase(fetchPageById.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(fetchPagesByScreenType.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchPagesByScreenType.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            action.payload.forEach((page) => addPageToState(state, page))
            state.initialized = true
        })
        builder.addCase(fetchPagesByScreenType.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
            state.initialized = true
        })
        builder.addCase(createPage.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(createPage.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            const { page } = action.payload
            const pageType = page.screenType || 'custom'

            if (!state.categorizedPages[pageType])
                state.categorizedPages[pageType] = []
            // If category exists and already has the page, remove it so we can add the new one
            else {
                state.categorizedPages[pageType] = state.categorizedPages[
                    pageType
                ].filter((p) => p._id !== page._id)
                state.pages.filter((p) => p._id !== page._id)
            }

            state.categorizedPages[pageType].push(page)
            state.pages.push(page)
            state.initialized = true
        })
        builder.addCase(createPage.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(updatePage.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updatePage.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            const { page } = action.payload

            addPageToState(state, page)

            state.initialized = true
        })
        builder.addCase(updatePage.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
        builder.addCase(archivePage.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(archivePage.fulfilled, (state, action) => {
            state.loading = 'fulfilled'
            state.initialized = true

            const { page } = action.payload

            addPageToState(state, page)
        })
        builder.addCase(archivePage.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        })
    },
})

const addPageToState = (state: AppStudioPageState, page: AppStudioPage) => {
    const pageType = page.screenType || 'custom'

    if (!state.categorizedPages[pageType]) state.categorizedPages[pageType] = []

    // If category exists and already has the page, remove it so we can add the new one
    state.categorizedPages[pageType] = state.categorizedPages[pageType].filter(
        (p) => p._id !== page._id
    )
    state.categorizedPages[pageType].push(page)

    // Update pages array as well
    state.pages = state.pages.filter((p) => p._id !== page._id)
    state.pages.push(page)
}

export const selectPagesByScreenType = (
    state: RootState,
    screenType: AppStudioScreenType
) =>
    state.appStudioPages?.categorizedPages?.[screenType]?.filter(
        (p) => !p.archivedAt
    )

export const selectPageById = (state: RootState, pageId: string) =>
    state.appStudioPages.pages.find((page) => page._id === pageId)

export default appStudioPagesSlice.reducer
