import { Parse } from 'services/parse'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import validateFonts from 'pages/CustomBlocks/utils/validate-fonts'
import cssWithTokenValues from 'pages/CustomBlocks/utils/css-with-token-values'

const initialState = {
    srcDoc: '',
    src: '',
    error: null,
    syntaxErrors: null,
    loading: true,
}

export const getSrcDoc = createAsyncThunk(
    'customBlocksWorkspace/getSrcDoc',
    async (
        { html, css, js, libraries, variables, fonts, openProjectId },
        { getState }
    ) => {
        const state = getState()
        const { activeThemeId } = state.themes
        const theme = state.themes?.entities?.[activeThemeId]
        const colorTokens = theme?.tokens?.colors

        const transformCssVariables = cssWithTokenValues(css, colorTokens)

        let parsedVariables = {}
        if (variables) {
            try {
                parsedVariables = JSON.parse(variables)
            } catch (e) {
                parsedVariables = {}
            }
        }

        const validFonts = validateFonts(fonts)
        try {
            const response = await Parse.Cloud.run('processCustomBlock', {
                code: [
                    { type: 'html', content: html },
                    { type: 'css', content: transformCssVariables },
                    { type: 'js', content: js },
                ],
                resources: [...libraries, ...validFonts],
                mockVariables: parsedVariables,
                appId: Parse.User.current().attributes.app.id,
                id: openProjectId,
            })

            const errors = {}
            response.code.map((file) => {
                if (file.errors.length > 0) {
                    errors[file.type] = file.errors
                }
            })
            return { response, errors }
        } catch (error) {
            console.log('error', error)
        }
    }
)

const previewSlice = createSlice({
    name: 'preview',
    initialState,
    reducers: {
        resetSrcDoc: (state, action) => {
            state = initialState
            return state
        },
    },
    extraReducers: {
        [getSrcDoc.pending]: (state) => {
            state.loading = true
        },
        [getSrcDoc.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.syntaxErrors = payload.errors
            state.srcDoc = payload.response.output
            state.src = payload.response.url
        },
        [getSrcDoc.rejected]: (state, action) => {
            state.loading = false
            state.error = action.error.message
        },
    },
})

export const { resetSrcDoc } = previewSlice.actions
export default previewSlice.reducer
