/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import { RootState } from 'store'
import { typedRequest } from 'store/_utils/api-fetch'
import getHeaders from 'store/_utils/get-headers'

type MobileDownloadBannerInfo = {
    enabled: boolean
}

interface MobileDownloadBannerState {
    data: MobileDownloadBannerInfo | Record<string, never>
    loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: MobileDownloadBannerState = {
    data: { enabled: false },
    loading: 'idle',
    error: null,
    initialized: false,
}
const baseUrl = import.meta.env.VITE_TC_BACKEND_API as string
const mobileDownloadBannerUrl = `${baseUrl}/mobile-download-banner`

// This should be renamed to updateMobileDownloadBanner for whenever the backend updates the script on Shopify.
// Right now this endpoint only updates the status in the DB
export const updateMobileDownloadBannerStatus = createAsyncThunk(
    'mobileDownloadBannerUpdate/update',
    async (
        status: boolean,
        { getState }
    ): Promise<MobileDownloadBannerInfo> => {
        const state = getState() as RootState
        const appId = state.app.data.id

        const body = {
            enabled: status,
        }
        const headers = await getHeaders()
        const response = await typedRequest<MobileDownloadBannerInfo>(
            `${mobileDownloadBannerUrl}/${appId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
                body: JSON.stringify(body),
            }
        )

        return response
    }
)

export const mobileDownloadBannerSlice = createSlice({
    name: 'mobileDownloadBanner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateMobileDownloadBannerStatus.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(
            updateMobileDownloadBannerStatus.fulfilled,
            (state, action) => {
                state.data = action.payload
                state.loading = 'fulfilled'
                state.error = null
            }
        )
        builder.addCase(
            updateMobileDownloadBannerStatus.rejected,
            (state, action) => {
                state.error = action.error
                state.loading = 'rejected'
                state.error = action.error
            }
        )
    },
})

export const selectMobileDownloadBannerInfo = (state: RootState) => {
    const { storeInfo } = state.app.data

    return {
        enabled: !!storeInfo.mobile_download_banner_enabled,
    }
}

export default mobileDownloadBannerSlice.reducer
