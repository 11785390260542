import { createSlice } from '@reduxjs/toolkit'
import { find } from 'lodash'
import { Parse } from 'services/parse'
import { selectAllUserIntegrations } from 'store/Settings/user-integration-slice'
import getDropInfo from 'utils/helpers/get-drop-info'
import blocksBank from 'utils/constants/blocksBank'

export const dropsSlice = createSlice({
    name: 'drops',
    initialState: {
        loading: 'idle',
        data: {
            drop: undefined,
        },
        error: undefined,
    },
    reducers: {
        setActiveDrop: (state, action) => {
            state.data.drop = action.payload
        },
    },
})

export const { setActiveDrop } = dropsSlice.actions

export default dropsSlice.reducer

export const selectActiveDrop = (state) => state.drops.data?.drop

export const selectDropById = async (id) => {
    const dropsQuery = new Parse.Query('Drops')
    const dropResponse = await dropsQuery.get(id)
    const dropData = getDropInfo(dropResponse)
    return dropData
}

export const selectDropBlocksBank = (state) => {
    const userIntegrations = selectAllUserIntegrations(state)

    const shoppableInstagram = find(userIntegrations || [], {
        name: 'shoppable-instagram',
    })

    const yotpoUGC = find(userIntegrations || [], {
        name: 'yotpo-ugc',
    })

    const areProductRecommendationsEnabled = find(userIntegrations || [], {
        name: 'nosto',
    })?.enabled

    const filteredBlocksBank = blocksBank.filter((item) => {
        const isShoppableInstagramType = item.type === 'shoppable-instagram'
        const yotpoUGCDisabled = !yotpoUGC?.enabled
        const shoppableInstagramDisabled = !shoppableInstagram?.enabled

        if (
            isShoppableInstagramType &&
            yotpoUGCDisabled &&
            shoppableInstagramDisabled
        ) {
            return false
        }

        if (
            !areProductRecommendationsEnabled &&
            item.type === 'product-recommendations'
        ) {
            return false
        }

        return true
    })
    return filteredBlocksBank
}
