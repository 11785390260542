import { createSlice } from '@reduxjs/toolkit'

export const integrationDetailsStore = createSlice({
    name: 'integrationDetails',
    initialState: {
        fields: {},
    },
    reducers: {
        setIntegrationDetailsFields(state, action) {
            return {
                ...state,
                fields: action.payload,
            }
        },
    },
})

export const fieldsSelector = (state) => state.integrationDetailsReducer.fields

export const { setIntegrationDetailsFields } = integrationDetailsStore.actions

export default integrationDetailsStore.reducer
