import React from 'react'

const AppContext = React.createContext({
    blocks: [],
    collectionMenu: [],
    themeOptions: [],
    parse: null,
    menu: [],
})

export default AppContext
