import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
    fetchSettings,
    updateAppSettings,
    updateDesignSettings,
} from './settings-slice' // designPages are pulled off Settings.multiBlockSections
import blocksBankDefaults from 'utils/constants/blocksBank'
import { selectUserIntegrationEntities } from './user-integration-slice'

const designPagesAdapter = createEntityAdapter({ selectId: (i) => i.name })

export const slice = createSlice({
    name: 'designPages',
    initialState: designPagesAdapter.getInitialState(),
    reducers: {},
    extraReducers: {
        [fetchSettings.fulfilled]: (state, action) => {
            designPagesAdapter.setAll(
                state,
                action.payload.entities.multiBlockSections || []
            )
        },
        [updateAppSettings.fulfilled]: (state, action) => {
            designPagesAdapter.setAll(
                state,
                action.payload.entities.multiBlockSections || []
            )
        },
        [updateDesignSettings.fulfilled]: (state, action) => {
            designPagesAdapter.setAll(
                state,
                action.payload.entities.multiBlockSections || []
            )
        },
    },
})

export default slice.reducer

export const {
    selectById: selectDesignPageById,
    selectIds: selectDesignPageIds,
    selectEntities: selectDesignPageEntities,
    selectAll: selectAllDesignPages,
    selectTotal: selectTotalDesignPages,
} = designPagesAdapter.getSelectors((state) => state.designPages)

export const selectActiveDesignPageObj = (state) => {
    const { designPages } = state
    return designPages.ids.reduce((acc, i) => {
        let { name } = designPages.entities[i]
        return { ...acc, [name]: true }
    }, {})
}

export const selectStaticPreviewBlocks = (state) => {
    const { designPages } = state
    const defaultPageId = designPages.ids[0]
    return designPages.entities[defaultPageId]?.blocks || []
}

export const selectMultipageCheck = (state) => {
    const { designPages } = state
    return designPages.ids?.length > 1
}
export const selectMultipageSections = (state) => {
    const { designPages } = state
    return Object.values(designPages.entities)
}

export const selectBlocksBank = (state) => {
    const userIntegrations = selectUserIntegrationEntities(state)

    return blocksBankDefaults.filter((item) => {
        if (item.type === 'shoppable-instagram') {
            return userIntegrations['shoppable-instagram']?.enabled
        }

        if (item.type === 'yotpo-ugc') {
            return userIntegrations['yotpo-ugc']?.enabled
        }

        if (item.type === 'product-recommendations') {
            return userIntegrations.nosto?.enabled
        }
        return true
    })
}
