import React from 'react'
import { ToastContainer } from 'react-toastify'

function Toaster() {
    return (
        <ToastContainer
            position="bottom-left"
            autoClose={1200}
            hideProgressBar={true}
            closeOnClick={false}
            rtl={false}
            closeButton={false}
            draggable={false}
            enableMultiContainer
            containerId="globalToastContainer"
            toastId="globalToastContainer"
        />
    )
}

export default Toaster
