function makeId() {
    let text = ''
    const charList =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let index = 0; index < 8; index++) {
        text += charList.charAt(Math.floor(Math.random() * charList.length))
    }
    return text
}

function formatPrice(amount, currencyCode) {
    return new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
    }).format(amount)
}

function formatCompareAt(compareAtPriceRange) {
    if (typeof arguments[0] === 'undefined') return false
    const { maxVariantPrice } = compareAtPriceRange
    if (maxVariantPrice && maxVariantPrice.amount != 0) {
        return formatPrice(maxVariantPrice.amount, maxVariantPrice.currencyCode)
    }
    return false
}

function formatNumber(amount) {
    return new Intl.NumberFormat('en-US').format(amount)
}

function setCookie(name, value, options = {}) {
    if (options.expires && options.expires.toUTCString) {
        options.expires = options.expires.toUTCString()
    }

    let updatedCookie = `${encodeURI(name)}=${encodeURI(value)}`

    for (const optionKey in options) {
        updatedCookie += `; ${optionKey}`
        const optionValue = options[optionKey]
        if (optionValue !== true) {
            updatedCookie += `=${optionValue}`
        }
    }

    document.cookie = updatedCookie
}

function isEmbeddedWindow() {
    return window.top !== window.self
}

function checkObjectProperties(object) {
    const objectValueArray = Object.values(object)

    for (const element of objectValueArray) {
        if (element === null || element === '' || element === []) return false
    }

    return true
}

function isFileOver10MB(fileSize) {
    // Convert bytes to megabytes
    const megabytes = fileSize / (1024 * 1024);

    return megabytes > 10
}

export {
    makeId,
    formatPrice,
    formatNumber,
    setCookie,
    isEmbeddedWindow,
    checkObjectProperties as checkObjProperties,
    formatCompareAt,
    isFileOver10MB
}
