/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { Parse } from 'services/parse'

const parseApiUrl = import.meta.env.VITE_TC_PARSE_API;
const baseApiUrl = parseApiUrl.substring(0, parseApiUrl.lastIndexOf('/'));

export const fetchBillingLinkData = createAsyncThunk(
    'billingLink/fetchBillingLinkData',
    async (_, { getState }) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        const response = await fetch(`${baseApiUrl}/billinglinks/${appId}`, {
            method: 'GET',
            headers,
        })
        const data = await response.json();

        return data;
    }
)

export const billingLinkSlice = createSlice({
    name: 'billingLink',
    initialState: {
        loading: 'idle',
        data: {},
        error: undefined,
        initialized: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBillingLinkData.pending, (state) => {
            state.loading = 'pending'
        });
        builder.addCase(fetchBillingLinkData.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
            state.initialized = true
        });
        builder.addCase(fetchBillingLinkData.rejected, (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
            state.error = action.error
        });
    },
})

export const selectBillingLink = (state) => state.billingLink.data

export const selectIsBillingLinkLoading = (state) =>
    Boolean(state.billingLink.loading === 'pending')

export const selectBillingLinkInitialized = (state) =>
    Boolean(state.billingLink.initialized)

export default billingLinkSlice.reducer