const updateMenuItems = (menuItems, itemIndex, url, assetID) => {
    return menuItems.map((menuItem, index) => {
        if (index === itemIndex) {
            return {
                ...menuItem,
                image: {
                    type: 'web',
                    url,
                    assetID,
                },
            }
        }
        return menuItem
    })
}
export default updateMenuItems
