/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { Parse } from 'services/parse'

const parseApiUrl = import.meta.env.VITE_TC_PARSE_API;
const baseApiUrl = parseApiUrl.substring(0, parseApiUrl.lastIndexOf('/'));

export const navigateUserToCollectNowHostedPage = createAsyncThunk('billingQuotes/navigateUserToCollectNowHostedPage',
    async (customerId) => {
        try {
            const headers = await getHeaders()
            const appId = Parse.User.current().attributes.app.id
            const response = await fetch(`${baseApiUrl}/billing/customers/${customerId}/collect-payment`, {
                method: 'POST',
                headers: {
                    ...headers,
                    'app-id': appId,
                    'Content-Type': 'application/json',
                }
            })

            return response.json()
        } catch (error) {
            console.log(error)
            throw new Error(`Unable to navigate to Collect Now page.`)
        }
    }
)

export const fetchBillingQuoteData = createAsyncThunk(
    'billingQuotes/fetchBillingQuoteData',
    async (_, { getState }) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        try {
            const response = await fetch(`${baseApiUrl}/billing/latest-quote-by-app-id/${appId}`, {
                method: 'GET',
                headers: {
                    ...headers,
                    'response-schema-normalized': 'true',
                }
            })

            return response.status === 204 ? {} : response.json();
        } catch (error) {
            throw new Error(`Unable to fetch billing quote data: ${error}`)
        }
    }
)

export const acceptBillingQuote = createAsyncThunk(
    'billingQuotes/acceptBillingQuote',
    async (quoteId) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        try {
            const response = await fetch(`${baseApiUrl}/billing/quotes/${quoteId}/accept/`, {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                    'app-id': appId,
                }
            })

            if (response.status === 204) {
                return {}
            } 
            return response.json()
        
        } catch (error) {
            throw new Error(`Unable to accept billing quote: ${error}`)
        }
    }
)

export const generateCheckout = createAsyncThunk(
    'billingQuotes/generateCheckout',
    async ({ customerId, requestBody }) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        try {
            const response = await fetch(`${baseApiUrl}/billing/customers/${customerId}/checkout`, {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                    'app-id': appId,
                },
                body: JSON.stringify(requestBody),
            })

            if (response.status === 204) {
                return {}
            } 
                return response.json();
        
        } catch (error) {
            throw new Error(`Unable to generate checkout: ${error}`)
        }
    }
)


export const createPortalSessionWithChargeBee = createAsyncThunk(
    'billingQuotes/createPortalSessionWithChargeBee',
    async (_, { getState }) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        try {
            const response = await fetch(`${baseApiUrl}/billing/chargebee/portal-session`, {
                method: 'POST',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ appId }),
            })
            const data = await response.json();
    
            return data;
        } catch (error) {
            throw new Error(`Failed to create portal session with Chargebee: ${error}`)
        }
    }
)


export const billingQuoteSlice = createSlice({
    name: 'billingQuotes',
    initialState: {
        loading: 'idle',
        data: undefined,
        error: undefined,
        initialized: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBillingQuoteData.pending, (state) => {
            state.loading = 'pending'
        });
        builder.addCase(fetchBillingQuoteData.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
            state.initialized = true
        });
        builder.addCase(fetchBillingQuoteData.rejected, (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
            state.error = action.error
        });
    },
})

export const selectBillingQuote = (state) => state.billingQuote.data

export const selectActiveBillingQuote = (state) => {
    const billingQuote = selectBillingQuote(state)
    
    return billingQuote && Object.keys(billingQuote).length > 0 && billingQuote.status === 'open'
}

export const selectHasBillingQuoteAddons = (state) => {
    const billingQuote = selectBillingQuote(state)

    return billingQuote?.lineItems?.filter(item => item.type === 'addon').length > 0
}

export const selectBillingQuoteAddons = (state) => {
    const billingQuote = selectBillingQuote(state)

    return billingQuote?.lineItems?.filter(item => item.type === 'addon')
}

export const selectIsBillingQuoteLoading = (state) =>
    Boolean(state.billingQuote.loading === 'pending')

export const selectBillingQuoteInitialized = (state) =>
    Boolean(state.billingQuote.initialized)

export default billingQuoteSlice.reducer