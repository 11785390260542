import posthog from 'posthog-js';

function initializePostHog() {
	posthog.init(
		import.meta.env.VITE_TC_POSTHOG_KEY,
		{
			api_host: import.meta.env.VITE_TC_POSTHOG_HOST,
		}
	);
}

export const PostHogOptions = {
  api_host: import.meta.env.VITE_TC_POSTHOG_HOST,
}

export default initializePostHog;
