const weights = [
    'thin',
    'extra light',
    'extralight',
    'light',
    'normal',
    'regular',
    'medium',
    'semi bold',
    'semibold',
    'bold',
    'extra bold',
    'extrabold',
    'black',
    'heavy',
]
const styles = ['italic']

const weightMap = {
    thin: 100,
    ultralight: 200,
    'ultra thin': 100,
    'ultra-thin': 100,
    ultrathin: 100,
    'extra-light': 100,
    'extra light': 100,
    extralight: 100,
    light: 300,
    normal: 400,
    regular: 400,
    medium: 500,
    bold: 700,
    'extra-bold': 800,
    'extra bold': 800,
    extrabold: 800,
    semibold: 600,
    black: 900,
    heavy: 900,
}

const parseFontAttributes = (metadata) => {
    const metadataToMap = { ...metadata }
    delete metadataToMap.file
    let fontWeight = null
    let weightName = ''

    const fullName = metadata.fullName.toLowerCase()
    const fontStyle = fullName.toLowerCase().includes('italic')
        ? 'italic'
        : 'normal'

    weights.map((weight) => {
        if (fullName.includes(weight)) {
            fontWeight = weightMap[weight]
            weightName = weight
            return
        }
    })

    return {
        weight: !fontWeight ? 400 : fontWeight,
        weightName: !weightName ? 'regular' : weightName,
        style: fontStyle,
    }
}

export default parseFontAttributes
