import * as Sentry from '@sentry/react'

import initLaunchDarkly from 'services/feature-flags/launch-darkly-initialize'
import _ from 'lodash'
import React from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import tapcartAnalytics from 'services/user-tracking'
import { trackDashboardVisited } from 'services/user-tracking/track-dashboard-navigation'
import { initializeParse, Parse } from 'services/parse'
import { fetchApp } from 'store/App/app-slice'
import { setActiveDrop } from 'store/Drops/drops-slice'
import { fetchPlatforms } from 'store/Platforms/platforms-slice'
import { fetchCustomBlocks } from 'store/CustomBlocks/custom-blocks-slice'
import { fetchBillingLinkData } from 'store/BillingLinks'
import { fetchIntegrations } from 'store/Integrations/integrations-slice'
import { fetchSettings } from 'store/Settings/settings-slice'
import { fetchUser } from 'store/User/user-slice'
import { fetchThemes } from 'store/Themes/themes-slice'
import { fetchBillingInvoices } from 'store/Invoices/invoices-slice'
import {
    fetchFontAsset,
    fetchIconAssets,
    fetchFontAssets,
    fetchDefaultIconAssets,
} from 'store/Assets/assets-slice'
import { fetchSubscription } from 'store/Subscription/subscription-slice'
import { fetchBillingQuoteData } from 'store/BillingQuotes/billing-quotes-slice'
import { fetchBillingCustomerData } from 'store/Customers'
import { fetchScreens } from 'store/Screens/screens-slice'
import AppContext from 'utils/context/AppContext'
import { setCookie } from 'utils/helpers/misc'
import { verifyInstallStatus } from 'store/TapcartMerchant/tapcart-merchant-slice'
import { isValidPathToInitApp } from 'utils/helpers'
import { fetchIntegrationBlocks } from './store/IntegrationBlocks/slice'
import Routes from './Routes'
import { loadBeamer } from 'utils/libraries/load-beamer'

// Turn this on to audit accessibility issues in the dashboard
// import initializeAccessibilityAudit from 'utils/helpers/initializeAccessibilityAudit'
// initializeAccessibilityAudit()
class App extends React.Component {
    constructor(props) {
        super(props)
        // window.Parse = Parse;
        this.state = {
            app: null,
            settings: null,
            platforms: null,
            isLoaded: false,
            refreshContext: this.refreshContext,
            clearUserSession: this.clearUserSession,
        }
    }

    // ***************
    // Fetch all Parse info and initialize the dashboard
    // ***************
    initializeApp = () => {
        let app, platforms, subscription

        initializeParse()
            .then(async ([currentApp, currentPlatforms]) => {
                app = currentApp
                platforms = currentPlatforms
                this.props.fetchUser()
                this.props.fetchThemes()
                this.props.fetchFontAsset()
                this.props.fetchPages()
                this.props.fetchIntegrations()
                this.props.fetchSettings()
                this.props.fetchIconAssets()
                this.props.fetchDefaultIconAssets()
                this.props.fetchFontAssets()
                this.props.fetchApp()
                this.props.fetchPlatforms()
                this.props.fetchCustomBlocks()
                this.props.fetchBillingLinkData()
                subscription = await this.props.fetchSubscription()

                this.props.fetchBillingCustomerData()
                this.props.fetchBillingQuoteData()
                this.props.fetchBillingInvoices()
                this.props.fetchIntegrationBlocks()
                this.props.verifyInstallStatus({
                    // TODO: Wait for fetchApp to be loaded before verifying install status
                    shopifyStore: app.get('shopifyStore'),
                    shopifyApiKey: app.get('shopifyApiKey'),
                })
                Sentry.setUser({
                    id: currentApp.id,
                    username: currentApp.get('title') || 'Unknown User',
                })

                tapcartAnalytics.setUserProperties({
                    'app-id': currentApp.id,
                })
            })
            .then(() => {
                loadBeamer({
                    subscription: subscription?.payload ?? {
                        tier: 'trial',
                        status: 'trial',
                    },
                })
                this.setState({
                    isLoaded: true,
                    ...this.prepareContext(
                        Parse.User.current(),
                        app,
                        platforms
                    ),
                })
            })
            .catch((err) => {
                Parse.User.logOut()
                    .then(this.clearUserSession)
                    .catch((logoutError) => {
                        console.error(logoutError)
                    })
            })
    }

    render() {
        return (
            <AppContext.Provider value={this.state}>
                <Routes isLoaded={this.state.isLoaded} />
            </AppContext.Provider>
        )
    }

    componentDidUpdate() {
        const currentUser = Parse.User.current()
        const { pathname } = this.props.location

        if (
            currentUser &&
            currentUser.get('sessionToken') &&
            !this.state.isLoaded &&
            isValidPathToInitApp(pathname)
        ) {
            this.initializeApp()
        }
    }

    componentDidMount() {
        const { pathname, search } = this.props.location
        const currentUser = Parse.User.current()

        trackDashboardVisited(search)

        // Do not request initial data on /install as the page will log out and handle it
        if (
            currentUser &&
            currentUser.get('sessionToken') &&
            !this.state.isLoaded &&
            isValidPathToInitApp(pathname)
        ) {
            this.initializeApp()
        }

        // Analytics
        ReactGA.initialize('UA-61575804-1')
    }

    prepareContext = (user, app, platforms, trial) => {
        const imagePlaceholders = {
            appIcon: '',
            launchScreen: '',
            placeholder: '',
        }

        // if assets object doesn't exist
        if (!app.get('assets')) {
            app.set('assets', imagePlaceholders)
        }

        const updatedSettings = _.cloneDeep(app.get('settings'))

        if (!updatedSettings.attributes.themeOptions.mainLogo) {
            const updatedThemeOptions = app.get('settings').get('themeOptions')

            updatedSettings.set('themeOptions', {
                ...updatedThemeOptions,
                mainLogo: '',
            })
        }

        // if integrations array doesn't exist within settings
        if (!updatedSettings.attributes.integrations) {
            const integrations = []
            updatedSettings.set('integrations', integrations)
        }

        this.props.setActiveDrop(app.get('drop'))

        return {
            user,
            app,
            platforms,
            trial,
            settings: updatedSettings,
            listing: app.get('listing'),
            assets: app.get('assets'),
            drop: app.get('drop'),
            access: app.get('access') || {},
        }
    }

    clearUserSession = () => {
        this.setState({
            isLoaded: false,
        })

        //Get rid of Parse Variables from localStarage
        const localStorageKeys = Object.keys(window.localStorage)
        const localStorageRemovableValues = []
        for (const key of localStorageKeys) {
            if (key.includes('Parse')) {
                localStorageRemovableValues.push(key)
            }
        }
        for (const key of localStorageRemovableValues) {
            window.localStorage.removeItem(key)
        }

        // Unset login cookie for subdomains
        setCookie('parseSessionId', '', {
            domain: import.meta.env.VITE_TC_COOKIE_DOMAIN || '.tapcart.com',
            'max-age': 0,
        })
    }

    refreshContext = (options) => {
        const userSpecifiedPlatforms = options && options.platforms
        // Fetch latest app/user/settings from context
        // Unfortunately we *currently* need to refresh the Redux store AND context
        this.props.fetchApp()
        this.props.fetchSettings()
        this.props.fetchIconAssets()
        this.props.fetchDefaultIconAssets()
        this.props.fetchThemes()
        this.props.fetchFontAsset()
        this.props.fetchFontAssets()
        this.props.fetchCustomBlocks()
        this.props.fetchBillingLinkData()
        this.props.fetchUser()
        this.props.fetchThemes()
        this.props.fetchPages()
        this.props.fetchPlatforms()
        this.props.fetchSubscription()
        this.props.fetchBillingCustomerData()
        this.props.fetchBillingQuoteData()
        this.props.fetchBillingInvoices()
        this.props.fetchIntegrationBlocks()
        this.state.app
            .fetch()
            .then((refreshedApp) => {
                this.setState((prevState) => ({
                    ...this.prepareContext(
                        Parse.User.current(),
                        refreshedApp,
                        userSpecifiedPlatforms || prevState.platforms,
                        prevState.trial
                    ),
                }))
            })
            .then(() => {
                if (options && options.callback) options.callback()
            })
            .catch((err) => console.log(err))
    }
}

const mapDispatchToProps = {
    fetchIntegrations,
    fetchSettings,
    fetchIconAssets,
    fetchDefaultIconAssets,
    fetchFontAssets,
    fetchFontAsset,
    fetchApp,
    fetchCustomBlocks,
    fetchBillingLinkData,
    verifyInstallStatus,
    setActiveDrop,
    fetchThemes,
    fetchPages: fetchScreens,
    fetchUser,
    fetchPlatforms,
    fetchSubscription,
    fetchBillingCustomerData,
    fetchBillingQuoteData,
    fetchBillingInvoices,
    fetchIntegrationBlocks,
}

const AppWithRouter = withRouter(connect(null, mapDispatchToProps)(App))
export default initLaunchDarkly(AppWithRouter)
