import '@tapcartinc/app-studio-ui/dist/styles.css'
import App from 'App'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PostHogProvider } from 'posthog-js/react'
import store from 'store/index'
import 'styles/main.scss'
import { ErrorBoundary } from '@sentry/react'
import history from 'utils/helpers/history'
import logBuildVersion from 'utils/helpers/log-build-version'
import LegacyToaster from 'components/common/Toaster'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import initializeSentry from 'utils/libraries/sentry'
import initializePostHog, { PostHogOptions } from 'utils/libraries/posthog'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { Auth0Provider } from '@auth0/auth0-react'

logBuildVersion()
initializeSentry()
initializePostHog()

const DOMAIN = import.meta.env.VITE_TC_AUTH_AUTH0_DOMAIN
const CLIENT_ID = import.meta.env.VITE_TC_AUTH_AUTH0_CLIENT_ID
const AUDIENCE = import.meta.env.VITE_TC_AUTH_AUTH0_AUDIENCE
const REDIRECT_URI = `${window.location.origin}`

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <Router history={history}>
                <CompatRouter>
                    <PostHogProvider
                        apiKey={import.meta.env.VITE_TC_POSTHOG_KEY}
                        options={PostHogOptions}
                    >
                        <Auth0Provider
                            domain={DOMAIN}
                            clientId={CLIENT_ID}
                            authorizationParams={{
                                audience: AUDIENCE,
                                redirect_uri: REDIRECT_URI,
                            }}
                            cacheLocation={'localstorage'}
                            useRefreshTokens={true}
                            useRefreshTokensFallback={true}
                        >
                            <App />
                            <LegacyToaster />
                        </Auth0Provider>
                    </PostHogProvider>
                </CompatRouter>
            </Router>
        </Provider>
    </ErrorBoundary>,
    document.querySelector('#root')
)
