import { Parse } from 'services/parse'

async function getHeaders() {
    let token
    const user = Parse.User.current()
    if (user && user.get('sessionToken')) token = user.get('sessionToken')
    const session = await Parse.Session.current()
    const installationId = session.get('installationId')
    const headers = {
        'session-token': token,
        'installation-id': installationId,
    }
    return headers
}

export default getHeaders
