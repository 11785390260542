import ResetPasswordPage from 'pages/ResetPasswordPage'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Parse } from 'services/parse'

function resetPasswordInfo(properties) {
    const query = new URLSearchParams(properties.location.search)
    const username = query.get('username')
    const token = query.get('token')
    const id = query.get('id')
    const app = query.get('app')

    return {
        username,
        token,
        id,
        app,
    }
}

function ProtectedRoute({ component: Component, render, ...rest }) {
    return (
        <Route
            {...rest}
            render={(properties) => {
                if (Parse.User.current()) {
                    if (render) {
                        return render(properties)
                    }
                    if (component) {
                        return <Component {...properties} />
                    }
                    return null
                }
                if (properties.match.url === '/reset-password') {
                    const resetInfo = resetPasswordInfo(properties)

                    return (
                        <Route
                            path="/reset-password"
                            render={(properties_) => (
                                <ResetPasswordPage
                                    {...properties_}
                                    resetInfo={resetInfo}
                                />
                            )}
                        />
                    )
                }
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: properties.location },
                        }}
                    />
                )
            }}
        />
    )
}

export default ProtectedRoute
