import React from 'react'
import { HandWaveIcon } from '@tapcartinc/tapcart-ui/dist/icons/index'
import theme from 'tc-theme'
import AbandonCartIcon from 'components/common/svgs/AbandonCart'
import CustomerWinbackIcon from 'components/common/svgs/CustomerWInback'
import PostPurchaseIcon from 'components/common/svgs/PostPurchase'

const campaignCopy = {
    post_purchase: {
    icon: <PostPurchaseIcon fill={theme.foundationBlack} />,
        description: 'Send customers a message after they make a purchase',
        title: 'Post Purchase',
        triggerCopy: 'When a user makes a purchase',
        data: {
            title: 'Post Purchase',
            job_type: 'post_purchase',
            config: {
                push: {
                    type: 'custom',
                    message: '',
                    title: '',
                    attachment: null,
                    notification_id: null,
                    segment_id: 'string',
                    destination: { type: 'internal', url: '/home' },
                },
                send_delay: '30 minutes',
            }
        }
    },
    win_back: {
        icon: <CustomerWinbackIcon fill={theme.foundationBlack} />,
        description: 'Re-engage lapsed customers that have not made a recent purchase',
        title: 'Customer Winback',
        triggerCopy: 'When a user made a purchase but has not re-purchased',
        data: {
            title: 'Customer Winback',
            job_type: 'win_back',
            config: {
                push: {
                    type: 'custom',
                    message: '',
                    title: '',
                    attachment: null,
                    notification_id: null,
                    segment_id: 'string',
                    destination: { type: 'internal', url: '/home' },
                },
                send_delay: '30 days',
                frequency: '15 minutes',
            }
        }
    },
    welcome_push: {
        icon: <HandWaveIcon iconRight={true} fill={theme.foundationBlack} />,
        description: 'Onboard new users with a discount or friendly hello',
        title: 'New User Welcome',
        triggerCopy: 'When a new user opts-in to Push',
        data: {
            title: 'New User Welcome',
            job_type: 'welcome_push',
            config: {
                push: {
                    type: 'custom',
                    message: '',
                    title: '',
                    attachment: null,
                    notification_id: null,
                    segment_id: 'string',
                    destination: { type: 'internal', url: '/home' },
                },
                send_delay: '10 minutes',
            },
        },
    },
    abandon_cart: {
        icon: <AbandonCartIcon color={theme.foundationBlack} />,
        title: 'Abandoned Cart',
        description: 'User puts an item in cart and leaves without ordering',
        triggerCopy: 'When a user abandons their cart',
        data: {
            title: 'Abandoned Cart',
            job_type: 'abandon_cart',
            config: {
                push: {
                    type: 'custom',
                    message: '',
                    title: '',
                    attachment: null,
                    notification_id: null,
                    segment_id: 'string',
                    destination: {
                        type: 'internal',
                        url: '/cart',
                    },
                },
                cart_age: '30 minutes',
                frequency: '15 minutes',
                no_repeat: '10 days',
            },
        },
    },
}

export default campaignCopy
