/* eslint-disable no-console */

export default function logBuildVersion() {
    const tapcartASCII =
        '______   ______     ______   ______     ______     ______     ______  \r\n/\\__  _\\ /\\  __ \\   /\\  == \\ /\\  ___\\   /\\  __ \\   /\\  == \\   /\\__  _\\ \r\n\\/_/\\ \\/ \\ \\  __ \\  \\ \\  _-/ \\ \\ \\____  \\ \\  __ \\  \\ \\  __<   \\/_/\\ \\/ \r\n   \\ \\_\\  \\ \\_\\ \\_\\  \\ \\_\\    \\ \\_____\\  \\ \\_\\ \\_\\  \\ \\_\\ \\_\\    \\ \\_\\ \r\n    \\/_/   \\/_/\\/_/   \\/_/     \\/_____/   \\/_/\\/_/   \\/_/ /_/     \\/_/ '
    console.log(`%c ${tapcartASCII}`, `font-weight: 700; color: #000;`)
    console.log(`%c MERCHANT DASHBOARD`, `font-weight: 700; color: #000;`)
    return console.log(
        `%c v${__APP_VERSION__}`,
        `font-weight: 700; color: #555;`
    )
}
