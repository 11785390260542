import { schema } from 'normalizr'

export const userIntegration = new schema.Entity(
    'userIntegrations',
    {},
    { idAttribute: 'name' }
)
export const multiBlockSection = new schema.Entity(
    'multiBlockSections',
    {},
    { idAttribute: 'name' }
)
export const settingsSchema = new schema.Object(
    {
        integrations: new schema.Array(userIntegration),
        multiBlockSections: new schema.Array(multiBlockSection),
    },
    { processStrategy: (object) => omit('createdAt') }
)

export const automatedPushSchema = new schema.Entity(
    'automatedPushCampaigns',
    {},
    {
        idAttribute: 'job_type',
        processStrategy: (value) => {
            if (!value) return value
            return {
                ...value,
                config: {
                    ...value.config,
                    push: {
                        ...value.config?.push,
                        type: 'custom',
                    },
                },
            }
        },
    }
)
export const automatedPushArray = new schema.Array(automatedPushSchema)
