/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import { getPlatforms } from 'services/parse'
import { Platform, Version, PlatformType } from 'types/dashboard-types'
import { PlatformParseResponse } from 'types/api-types'
import { RootState } from 'store'

type PlatformData = Record<PlatformType, Platform>

interface PlatformState {
    data: PlatformData | Record<string, never>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: PlatformState = {
    data: {},
    loading: 'idle',
    error: null,
    initialized: false,
}

const formatVersion = (versionInput: Version) => {
    if (!versionInput) return null

    const {
        type,
        createdAt,
        updatedAt,
        version,
        status,
        pipeline,
        build,
        salesforceId,
    } = versionInput

    return {
        type,
        createdAt,
        updatedAt,
        version,
        status,
        pipeline,
        build,
        salesforceId,
    }
}

const formatPlatforms = (platforms: PlatformParseResponse[]) =>
    platforms.reduce((acc, platform) => {
        const { id } = platform
        const { attributes } = platform || {}
        const { type, appStoreId, connected, version } = attributes
        acc[type] = {
            id,
            type,
            appStoreId,
            connected,
            version: formatVersion(version.attributes),
        }
        return acc
    }, {} as PlatformData)

export const fetchPlatforms = createAsyncThunk(
    'platform/fetchPlatforms',
    async () => {
        const platformParseObject =
            (await getPlatforms()) as PlatformParseResponse[]

        return formatPlatforms(platformParseObject)
    }
)

export const platformsSlice = createSlice({
    name: 'platforms',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPlatforms.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchPlatforms.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(fetchPlatforms.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
    },
})

export const selectPlatformStatus = (
    state: RootState,
    platformType: PlatformType
) => {
    const platform = state.platforms.data[platformType]
    const { status } = platform?.version || {}

    if (!platform || !platform.connected) return 'Not Connected'
    if (status === 'created' && platform.connected === true) return 'Connected'
    if (status === 'submitted' || status === 'review') return 'Submitted'
    return status === 'live' ? 'Live' : ''
}

export default platformsSlice.reducer
