/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { RootState } from 'store'

const backendURL = import.meta.env.VITE_TC_BACKEND_API as string
const aiURL = `${backendURL}/tap-gpt`

type ExecutePromptRequest = {
    prompt: string
    model: 'text-davinci-003' | string
}
type ExecutePromptResponse = {
    result: string
}

interface AIState {
    data: ExecutePromptResponse | Record<string, never>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: AIState = {
    data: {},
    loading: 'idle',
    error: null,
    initialized: false,
}

interface JSONResponse extends Response {
    json(): Promise<ExecutePromptResponse>
}

export const executePrompt = createAsyncThunk(
    'ai/executePrompt',
    async (
        params: ExecutePromptRequest,
        { getState }
    ): Promise<ExecutePromptResponse> => {
        const state = getState() as RootState
        const appId = state.app.data.id

        const body = params
        const headers = await getHeaders()
        const response: JSONResponse = await fetch(`${aiURL}/prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'app-id': appId,
                ...headers,
            },
            body: JSON.stringify(body),
        })
        const res = await response.json()
        return res
    }
)

const aiSlice = createSlice({
    name: 'ai',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(executePrompt.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(executePrompt.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.data = action.payload
        })
        builder.addCase(executePrompt.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
    },
})

export default aiSlice.reducer
