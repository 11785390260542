import { createSlice } from '@reduxjs/toolkit'
import projectsAdapter from './projects-adapter'

const initialState = projectsAdapter.getInitialState({
    openProjectId: '',
})

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        projectAddOne: projectsAdapter.addOne,
        projectUpdateOne: (state, action) => {
            return projectsAdapter.updateOne(state, {
                id: action.payload.id,
                changes: action.payload.changes,
            })
        },
        projectRemoveOne: (state, action) => {
            projectsAdapter.removeOne(state, action.payload.id)
        },
        projectsRemoveAll: projectsAdapter.removeAll,
        setOpenProjectId: (state, action) => {
            state.openProjectId = action.payload
        },
        setActiveFileInProject: (state, action) => {
            state.entities[action.payload.id].activeFile = action.payload.file
        },
        setUnsavedChanges: (state, action) => {
            state.entities[action.payload.id].unsavedChanges =
                action.payload.unsavedChanges
        },
        projectAddOrUpdateOne: (state, action) => {
            projectsAdapter.upsertOne(state, action.payload)
        },
    },
})

export const {
    projectAddOne,
    projectUpdateOne,
    projectRemoveOne,
    projectsRemoveAll,
    setOpenProjectId,
    setActiveFileInProject,
    setUnsavedChanges,
    projectAddOrUpdateOne,
} = projectsSlice.actions

export default projectsSlice.reducer
