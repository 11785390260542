const shapeTopNavItemPayload = (topNavItem) => {
    if (topNavItem.type === 'nav-item-country-picker') {
        return [{ ...topNavItem, image: null }]
    }
    const emptyConfig = !topNavItem.image?.url && !topNavItem.image?.url
    if (emptyConfig) {
        return []
    }
    return [{ ...topNavItem }]
}
const shapeAppConfigPayload = (data, appConfigState, defaultLanguage) => {
    const payload = {
        navigation: {
            type: data.type,
            data: {
                sideMenuBackgroundImage: data.sideMenuBackgroundImage,
                items: data.menuItems,
                showTabTitles: data.showTabTitles,
                showMenuIcons: data.showMenuIcons,
            },
            styles: {
                tabSelectedColor: data.tabSelectedColor,
                tabUnselectedColor: data.tabUnselectedColor,
                tabBarBackgroundColor: data.tabBarBackgroundColor,
            },
        },
        titlebar: {
            data: {
                start: shapeTopNavItemPayload(data.topNavItems.start),
                center: appConfigState.data.titlebar.data.center,
                end: shapeTopNavItemPayload(data.topNavItems.end),
            },
        },
        defaultLanguage:
            defaultLanguage?.isoCode.toLowerCase() ??
            appConfigState.data.defaultLanguage.toLowerCase() ??
            'en',
        flags: { ...appConfigState.data.flags },
    }

    return payload
}

export { shapeAppConfigPayload }
