/* eslint-disable no-underscore-dangle */
import shapeBlocksPageForSave from 'pages/CustomScreens/utils/helpers/shape-blocks-page-for-save'
import { Screen, ScreenTypes } from 'types/api-types'
import { CustomScreenForm } from 'types/forms/custom-screen-types'

type CreateScreenPayload = {
    screenFormData: CustomScreenForm
    screenType: ScreenTypes
    appId: string
}

export const transformScreenFormToCreateScreen = ({
    screenFormData,
    screenType,
    appId,
}: CreateScreenPayload): Screen => {
    if (screenType === 'cart-page') {
        return {
            title: screenFormData.title,
            destination: screenFormData.destination,
            type: screenType,
            data: screenFormData.data,
            appId,
        }
    }
    if (screenType === 'account-page') {
        return {
            title: screenFormData.title,
            type: screenType,
            data: screenFormData,
            appId,
        }
    }

    if (screenType === 'blocks-page') {
        return shapeBlocksPageForSave(screenFormData, screenType, appId)
    }

    return {
        title: screenFormData.title,
        type: screenType,
        appId,
        data: {
            isCustomerAuthRequired: screenFormData.data?.isCustomerAuthRequired,
            contentUrl: screenFormData.data?.contentUrl,
            displayTitle: screenFormData.data?.displayTitle,
            titlebar: {
                type: 'titlebar',
                data: {
                    center: {
                        type: 'nav-item-title-bar-center',
                        ...(screenFormData.data?.displayTitle && {
                            title: screenFormData?.title,
                        }),
                    },
                    start: [],
                    end: [],
                },
            },
        },
    }
}

// TODO : UPDATE SHAPE
type UpdateScreenPayload = {
    screenId: string
    screenFormData: CustomScreenForm
    screenType: ScreenTypes
    appId: string
    currentScreen: Screen | undefined
}

export const transformeScreenFormToUpdateScreen = ({
    screenId,
    screenFormData,
    screenType,
    appId,
    currentScreen,
}: UpdateScreenPayload): Screen => {
    if (screenType === 'cart-page') {
        return {
            title: screenFormData.title,
            destination: screenFormData.destination,
            type: screenType,
            data: screenFormData.data,
            appId,
        }
    }
    if (screenType === 'account-page') {
        return {
            title: screenFormData.title,
            type: screenType,
            data: screenFormData,
            appId,
        }
    }

    if (screenType === 'blocks-page') {
        return shapeBlocksPageForSave(
            screenFormData,
            screenType,
            appId,
            screenId,
            currentScreen
        )
    }
    return {
        ...currentScreen,
        _id: screenId,
        title: screenFormData.title,
        type: screenType,
        appId,
        ...(screenId && {
            destination: { type: 'internal', url: `/hybrid-pages/${screenId}` },
        }),
        data: {
            ...currentScreen?.data,
            isCustomerAuthRequired: screenFormData.data?.isCustomerAuthRequired,
            contentUrl: screenFormData.data?.contentUrl,
            displayTitle: screenFormData.data?.displayTitle,
            thumbnailAssetId: currentScreen.data?.thumbnailAssetId,
            titlebar: {
                ...currentScreen?.data?.titlebar,
                type: 'titlebar',
                data: {
                    ...currentScreen?.data?.titlebar?.data,
                    center: {
                        ...currentScreen?.data?.titlebar?.data?.center,
                        type: 'nav-item-title-bar-center',
                        ...(screenFormData.data?.displayTitle && {
                            title: screenFormData.title,
                        }),
                    },
                },
            },
        },
    }
}
