import urlRegex from 'utils/regex/urlRegex'

const validateFonts = (fonts) => {
    const validFonts = []
    fonts.map((font) => {
        const isValidFont = font.url.match(urlRegex)

        if (font && isValidFont) {
            validFonts.push({ type: 'font', url: font.url.trim() })
        }
    })

    return validFonts
}

export default validateFonts
