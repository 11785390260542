import DeepDiff from 'deep-diff'
import flatten from 'lodash/flatten'
import tapcartAnalytics from './index'

const convertToAmplitudeValues = (appSettings) => ({
    'notification-opt-in-headline-text-set': !!appSettings.notificationMessage,
    'user-accounts-setting': appSettings.accounts,
    'quick-add-products-setting': appSettings.isQuickAddProductEnabled,
    'discount-codes-setting': appSettings.discounts === 'true',
    'gift-cards-setting': appSettings.giftCardsEnabled,
    'size-chart-setting': !!appSettings.sizeGuideUrl,
    'shipping-info-setting': !!appSettings.shippingInfoUrl,
    'allow-for-native-checkout-setting': appSettings.nativeCheckout,
    'recover-abandoned-checkout-setting': appSettings.isCheckoutAlertEnabled,
    'recover-abandoned-cart-setting': appSettings.isCartAlertEnabled,
    'notes-in-checkout-setting': appSettings.isNotesFieldEnabled,
    'required-phone-checkout-setting': appSettings.phoneNumberRequired,
    'apple-pay-setting': appSettings.applepay,
    'app-icon-set': !!appSettings.appIcon,
    'launch-screen-set': !!appSettings.launchScreen,
    'placeholder-image-set': !!appSettings.placeholder,
})

const trackSettings = (values) => {
    const { originalAppSettings, appSettings } = values

    let changedFields = []
    const settingsDiff = DeepDiff(originalAppSettings, appSettings)

    if (settingsDiff) {
        for (const change of settingsDiff) {
            changedFields.push(change.path)
            changedFields = flatten(changedFields)
        }
        if (changedFields.length > 0) {
            tapcartAnalytics.setUserProperties(
                convertToAmplitudeValues(appSettings)
            )
            tapcartAnalytics.logEvent('settings-updated', {
                fields: changedFields,
            })
        }
    }
}

export default trackSettings
