import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { selectAppAccess } from 'store/App/app-slice'
import { Parse } from 'services/parse'
import { calculateTrialPeriod } from './trial-calculator'

const parseApiUrl = import.meta.env.VITE_TC_PARSE_API
const baseApiUrl = parseApiUrl.substring(0, parseApiUrl.lastIndexOf('/'))

export const fetchSubscription = createAsyncThunk(
    'subscription/fetchSubscription',
    async (_, { getState }) => {
        try {
            const headers = await getHeaders()
            const appId = Parse.User.current().attributes.app.id

            const response = await fetch(
                `${baseApiUrl}/billing/latest-subscription-by-app-id/${appId}`,
                {
                    method: 'GET',
                    headers: {
                        ...headers,
                        'response-schema-normalized': 'true',
                    },
                }
            )

            if (response.status === 204) {
                throw new Error('No subscription found')
            }

            return await response.json()
        } catch (error) {
            throw new Error(error)
        }
    }
)

export const changePlan = createAsyncThunk(
    'subscription/changeSubscriptionPlan',
    async ({ subscriptionId, requestBody }) => {
        try {
            const headers = await getHeaders()
            const appId = Parse.User.current().attributes.app.id

            const response = await fetch(
                `${baseApiUrl}/billing/subscriptions/${subscriptionId}/change-plan`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'app-id': appId,
                        ...headers,
                    },
                    body: JSON.stringify(requestBody),
                }
            )

            return response
        } catch (error) {
            throw new Error(error)
        }
    }
)

export const addSubscriptionAddon = createAsyncThunk(
    'subscription/addSubscriptionAddon',
    async ({ subscriptionId, requestBody }, { dispatch }) => {
        try {
            const headers = await getHeaders()
            const appId = Parse.User.current().attributes.app.id

            const response = await fetch(
                `${baseApiUrl}/billing/subscriptions/${subscriptionId}/addons`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'app-id': appId,
                        ...headers,
                    },
                    body: JSON.stringify(requestBody),
                }
            )
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
)

export const removeSubscriptionAddon = createAsyncThunk(
    'subscription/removeSubscriptionAddon',
    async ({ subscriptionId, requestBody }) => {
        try {
            const headers = await getHeaders()
            const appId = Parse.User.current().attributes.app.id

            const response = await fetch(
                `${baseApiUrl}/billing/subscriptions/${subscriptionId}/remove-addon`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'app-id': appId,
                        ...headers,
                    },
                    body: JSON.stringify(requestBody),
                }
            )

            return response
        } catch (error) {
            throw new Error(error)
        }
    }
)

export const fetchBillingUsage = createAsyncThunk(
    'subscription/fetchBillingUsage',
    async (_, { getState }) => {
        const headers = await getHeaders()

        const state = getState()
        const { subscription = {}, app = {} } = state
        const appId = app.data.id

        const startDate = subscription?.data?.billing?.previousUsageBillingDate || subscription?.data?.billing?.previousBillingDate
        const endDate = subscription?.data?.billing?.nextUsageBillingDate || subscription?.data?.billing?.nextBillingDate

        const queryString = `startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`

        try {
            const response = await fetch(`${baseApiUrl}/billing/get-current-gmv/${appId}?${queryString}`, {
                method: 'GET',
                headers
            })

            switch (response.status) {
                case 200:
                    return await response.json()
                case 204:
                    return
                case 401:
                case 500:
                    return 0
                default:
                    throw new Error(`Response status unrecognized fetching GMV data: ${response.statusText}`)
            }
        } catch (error) {
            throw new Error(`Unable to fetch Usage App GMV Data: ${error}`)
        }
    }
)

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        loading: 'idle',
        data: undefined,
        error: undefined,
        initialized: false,
    },
    reducers: {
        updateSubscription: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSubscription.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchSubscription.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
            state.initialized = true
        })
        builder.addCase(fetchSubscription.rejected, (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
            state.error = action.error
        })
    },
})

export const selectSubscription = (state) => state.subscription.data || {}

export const selectSubscriptionPlanStatus = (state) => state.subscription.status

export const selectSubscriptionInitialized = (state) =>
    Boolean(state.subscription.initialized)

export const selectHasActiveSubscription = (state) => {
    const subscription = selectSubscription(state)

    return subscription.status === 'active' || subscription.status === 'trial'
}

export const selectPlanType = (state) => {
    const currentSubscription = selectSubscription(state)

    if (
        currentSubscription.status === 'active' ||
        currentSubscription.status === 'trial'
    ) {
        return currentSubscription.tier
    }

    if (!currentSubscription.tier) {
        return 'core'
    }

    return currentSubscription.tier
}

export const selectSubscriptionAddOnTotal = (state) => {
    const currentSubscription = selectSubscription(state)

    const { lineItems = [] } = currentSubscription || {}

    return lineItems.reduce((accum, lineItem) => {
        if (lineItem.type === 'addon') {
            return accum + lineItem.price.total
        }

        return accum
    }, 0)
}

export const selectIsPlanEnterprise = (state) =>
    selectPlanType(state) === 'enterprise'

export const selectSubscriptionLoading = (state) =>
    Boolean(state.subscription.loading === 'pending')

export const selectIsAutomationEnabled = (state) => {
    const currentSubscription = selectSubscription(state)
    const { automation } = selectAppAccess(state)
    const planType = selectPlanType(state)

    if (currentSubscription && automation) return true
    if (!planType) return false

    return ['enterprise', 'ultimate'].includes(selectPlanType(state))
}

export const selectTrial = (state) => calculateTrialPeriod(state)

export const selectTrialDays = (state) => {
    const { trialDays } = selectTrial(state)

    return trialDays
}

export const selectTrialActive = (state) => {
    const { active } = selectTrial(state)

    return active
}

export const selectSubscriptionLineItems = (state) => {
    const subscription = selectSubscription(state)

    return subscription?.lineItems || []
}

export const selectScheduledChangesSubscriptionLineItems = (state) => {
    const subscription = selectSubscription(state)

    const { scheduledChanges = {} } = subscription || {}
    const { lineItems = [] } = scheduledChanges?.data || {}

    return lineItems
}

export const selectInsightsAddOn = (state) => {
    const lineItems = selectSubscriptionLineItems(state)
    const { insightsProBeta } = selectAppAccess(state)
    const insightsProEnabled =
        Boolean(insightsProBeta) ||
        !!lineItems?.find((lineItem) => lineItem.key === 'insights-pro')

    return {
        enabled: insightsProEnabled,
        price:
            lineItems?.find((lineItem) => lineItem.key === 'insights-pro')
                ?.price?.total || 200,
    }
}

export const selectDeveloperToolsAddon = (state) => {
    const lineItems = selectSubscriptionLineItems(state)

    return {
        enabled: !!lineItems?.find(
            (lineItem) => lineItem.key === 'developer-tools'
        ),
        price:
            lineItems?.find((lineItem) => lineItem.key === 'developer-tools')
                ?.price?.total || 1500,
    }
}

export const selectInsightsProRemoved = (state) => {
    const { enabled: insightsProEnabled } = selectInsightsAddOn(state)
    const lineItemsScheduledChanges =
        selectScheduledChangesSubscriptionLineItems(state)

    const hasScheduledInsightsProRemoval =
        insightsProEnabled &&
        lineItemsScheduledChanges.length > 0 &&
        !lineItemsScheduledChanges.find(
            (lineItem) => lineItem.key === 'insights-pro'
        )

    return hasScheduledInsightsProRemoval
}

export const selectDeveloperToolsRemoved = (state) => {
    const { enabled: developerToolsEnabled } = selectDeveloperToolsAddon(state)
    const lineItemsScheduledChanges =
        selectScheduledChangesSubscriptionLineItems(state)

    const hasDeveloperToolsRemoval =
        developerToolsEnabled &&
        lineItemsScheduledChanges.length > 0 &&
        !lineItemsScheduledChanges.find(
            (lineItem) => lineItem.key === 'developer-tools'
        )

    return hasDeveloperToolsRemoval
}

// Shopify core and ultimate cannot see the TapAI button at all
export const selectCanViewTapAI = (state) => {
    const currentSubscription = selectSubscription(state)
    const isChargebeeSubscription =
        currentSubscription?.provider?.name === 'chargebee'
    const isEnterpriseSubscription = currentSubscription?.tier === 'enterprise'

    // Allow merchants without a subscription yet to have the same access
    // as a user on a Core plan
    const subscriptionDoesNotExist =
        Object.keys(currentSubscription).length === 0

    return (
        isChargebeeSubscription ||
        isEnterpriseSubscription ||
        subscriptionDoesNotExist
    )
}

// Chargebee Ultimate does have access to TapAI. All Enterprise plans have access to TapAI
export const selectHasAccessToTapAI = (state) => {
    const currentSubscription = selectSubscription(state)
    const tier = currentSubscription?.tier

    const isChargebeeSubscription =
        currentSubscription?.provider?.name === 'chargebee'

    return (
        tier === 'enterprise' ||
        (tier === 'ultimate' && isChargebeeSubscription)
    )
}

// GIVEN a merchant is on either of the following plans:
// Enterprise where provider=Shopify
// Enterprise where provider=Chargebee
// Ultimate where provider=Chargebee

// THEN display the new product images feature
export const selectHasAccessToImageAspectRatios = (state) => {
    const subscription = selectSubscription(state)
    const tier = subscription?.tier

    const isChargebeeSubscription =
        subscription?.billing?.provider === 'chargebee'

    return (
        tier === 'enterprise' ||
        (tier === 'ultimate' && isChargebeeSubscription)
    )
}

export const { updateSubscription } = subscriptionSlice.actions

export default subscriptionSlice.reducer
