import React from 'react'
import { Helmet } from 'react-helmet'

const FramelessLayout = ({ title, description, image, children }) => (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={document.URL} />
                <meta
                    property="og:image"
                    content="https://storage.googleapis.com/tapcart-assets/seo/ogImage.png"
                />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@tapcart_app" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta
                    name="twitter:image"
                    content="https://storage.googleapis.com/tapcart-assets/seo/ogImage.png"
                />
            </Helmet>
            {children}
        </div>
    )

export default FramelessLayout
