const shapeBlocksPageForSave = (
    formData,
    screenType,
    appId,
    screenId,
    currentScreen
) => {
    const shapedBlocks = []
    formData.data.scrollableContent.map((block) => {
        const shapedBlock = {
            type: block.type,
            specifications: null,
            properties: null,
            data: {
                customBlockId: block.data.customBlockId,
                source: block.data.source,
                multiplier: block.data.multiplier,
                version: block.data.version,
                partnerBlockAsCustom: block.data?.partnerBlockAsCustom,
            },
            id: block.id,
        }
        if (block.data?.blockConfig) {
            shapedBlock.data['blockConfig'] = block.data.blockConfig
        }

        shapedBlocks.push(shapedBlock)
    })

    const shapedScreen = {
        type: screenType,
        title: formData.title,
        ...(screenId && {
            _id: screenId,
        }),
        appId,
        ...(screenId && {
            destination: { type: 'internal', url: `/blocks-page/${screenId}` },
        }),
        data: {
            previewData: formData.data?.previewData,
            displayTitle: formData.data.displayTitle,
            ...(currentScreen?.data?.thumbnailUrl && {
                thumbnailUrl: currentScreen.data?.thumbnailUrl,
            }),
            ...(currentScreen?.data?.thumbnailAssetId && {
                thumbnailAssetId: currentScreen.data?.thumbnailAssetId,
            }),
            isCustomerAuthRequired: formData.data.isCustomerAuthRequired,
            stickyHeader: [],
            scrollableContent: shapedBlocks.map((block) => {
                let returnBlock = {
                    ...block,
                }
                delete returnBlock.id
                return returnBlock
            }),
            stickyFooter: [],
        },
    }

    return shapedScreen
}

export default shapeBlocksPageForSave
