import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    archiveCustomBlock,
    getAllCustomBlocks,
    saveCustomBlock,
    saveIntegrationCustomBlock,
} from 'services/parse/CustomBlocks'
import { Parse } from 'services/parse'
import { validateJson } from 'pages/CustomBlocks/utils/validate-json'
import customBlocksAdapter from './custom-blocks-adapter'
import formatCode from 'pages/CustomBlocks/utils/format-code'
import { validateMerchantInputs } from 'pages/CustomBlocks/utils/validate-merchant-inputs'
import { errorToast } from 'components/common/Toasts'
import cssWithTokenValues from 'pages/CustomBlocks/utils/css-with-token-values'

export const fetchCustomBlocks = createAsyncThunk(
    'customBlocks/fetchCustomBlocks',
    async () => {
        try {
            const res = await getAllCustomBlocks()
            const shapedBlocks = res.customBlocks.map((item) => ({
                ...item,
                type: 'custom',
            }))

            return shapedBlocks
        } catch (error) {
            return error
        }
    }
)

export const saveBlock = createAsyncThunk(
    'customBlocks/saveCustomBlock',
    async ({ values, id, type }, { rejectWithValue, dispatch, getState }) => {
        try {
            const state = getState()
            const { activeThemeId } = state.themes
            const theme = state.themes?.entities?.[activeThemeId]
            const colorTokens = theme?.tokens?.colors

            let updatedBlock = {
                ...values,
                id,
            }

            if (!updatedBlock.title) {
                updatedBlock = { ...updatedBlock, title: 'Untitled Block' }
            }

            const parsedVariables = updatedBlock.variables
                ? await validateJson(updatedBlock.variables)
                : {}

            const fontValues = updatedBlock.fonts
                .filter((font) => font?.url)
                .map((font) => ({ type: 'font', url: font?.url }))
            const mergedResources = [...updatedBlock.libraries, ...fontValues]

            const transformCssVariables = cssWithTokenValues(
                updatedBlock.css,
                colorTokens
            )

            const shapedPayload = {
                appId: Parse.User.current().attributes.app.id,
                id: updatedBlock.id,
                title: updatedBlock.title,
                type,
                html: updatedBlock.html,
                css: transformCssVariables,
                js: updatedBlock.js,
                sdkVersion: updatedBlock.sdkVersion,
                editorSettings: {
                    mockVariables: parsedVariables,
                },
                resources: mergedResources,
            }

            if (updatedBlock.slugHash) {
                shapedPayload.slugHash = updatedBlock.slugHash
            }

            const response = await saveCustomBlock(shapedPayload)

            if (!response.slugHash) {
                return rejectWithValue(response)
            }

            return { block: response, id }
        } catch (error) {
            if (error.message[0]) {
                const errorContent = error.message[0].split(' ')

                if (errorContent[0].includes('resources')) {
                    errorContent[0] = 'Font'
                    return errorContent.join(' ')
                }
            }

            return rejectWithValue(error.message[0])
        }
    }
)

export const archiveBlock = createAsyncThunk(
    'customBlock/archiveCustomBlock',
    async ({ id, forceArchive }, { rejectWithValue }) => {
        try {
            const response = await archiveCustomBlock(id, forceArchive)
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export const saveIntegrationBlock = createAsyncThunk(
    'customBlocks/saveIntegrationBlock',
    async ({ values, id }, { rejectWithValue, dispatch, getState }) => {
        const { partnerId } = getState().app.data
        try {
            let updatedBlock = {
                ...values,
                id,
            }

            if (!updatedBlock.title) {
                updatedBlock = { ...updatedBlock, title: 'Untitled Block' }
            }

            const parsedVariables = updatedBlock.variables
                ? await validateJson(updatedBlock.variables)
                : {}

            const fontValues = updatedBlock.fonts
                .filter((font) => font?.url)
                .map((font) => ({ type: 'font', url: font?.url }))
            const mergedResources = [...updatedBlock.libraries, ...fontValues]

            const shapedPayload = {
                appId: Parse.User.current().attributes.app.id,
                id: updatedBlock.id,
                title: updatedBlock.title,
                type: 'integration',
                html: updatedBlock.html,
                css: updatedBlock.css,
                js: updatedBlock.js,
                sdkVersion: updatedBlock.sdkVersion,
                editorSettings: {
                    mockVariables: parsedVariables,
                },
                resources: mergedResources,
                partnerFields: {
                    ...updatedBlock.partnerFields,
                    json: updatedBlock.partnerFields.json,
                    partnerId,
                },
            }

            if (updatedBlock.slugHash) {
                shapedPayload.slugHash = updatedBlock.slugHash
            }

            const response = await saveIntegrationCustomBlock(shapedPayload)
            if (!response.slugHash) {
                return rejectWithValue(response)
            }

            return { block: response, id }
        } catch (error) {
            if (error.message[0]) {
                const errorContent = error.message[0].split(' ')

                if (errorContent[0].includes('resources')) {
                    errorContent[0] = 'Font'
                    return errorContent.join(' ')
                }
            }

            return rejectWithValue(error.message[0])
        }
    }
)

function getBlockVersion(releaseNotes) {
    const latestRelease = releaseNotes[0]
    return latestRelease.version
}

export const submitIntegrationBlock = createAsyncThunk(
    'customBlocks/submitIntegrationBlock',
    async ({ values, id }, { rejectWithValue, dispatch, getState }) => {
        const { partnerId } = getState().app.data

        const formattedJson = formatCode(values.json, 'json')

        const manifestString = formattedJson.trim()

        const manifestFormData = JSON.parse(
            manifestString.replace(/"\s+|\s+"/g, '"')
        )

        const jsonErrors = validateMerchantInputs(manifestFormData)

        if (jsonErrors.length > 0) {
            return jsonErrors.map((jsonError) => {
                rejectWithValue(jsonError)
                return errorToast(jsonError)
            })
        }

        const activeCustomBlockId = values.id
        const blockVersion =
            getState().customBlocks.entities[activeCustomBlockId]
                ?.blockVersion ??
            values?.partnerFields?.releaseNotes?.[0]?.version ??
            '1.0.0'

        const slugHash =
            values?.slugHash ??
            getState().customBlocks.entities[activeCustomBlockId]?.slugHash

        const isDefaultJson =
            values.json ===
            JSON.stringify([
                {
                    name: 'Slot ID',
                    variable: 'test',
                    type: 'text',
                    required: false,
                    placeholder:
                        'Placeholder Text Visible to the Merchant before they type',
                    tooltip: 'Tooltip for additional field context',
                },
            ])
        try {
            let updatedBlock = {
                ...values,
                json: !isDefaultJson ? formattedJson : '',
                id,
            }

            if (slugHash) {
                updatedBlock = { ...updatedBlock, slugHash }
            }

            if (!updatedBlock.title) {
                updatedBlock = { ...updatedBlock, title: 'Untitled Block' }
            }

            const parsedVariables = updatedBlock.variables
                ? await validateJson(updatedBlock.variables)
                : {}

            const fontValues = updatedBlock.fonts
                .filter((font) => font?.url)
                .map((font) => ({ type: 'font', url: font?.url }))
            const mergedResources = [...updatedBlock.libraries, ...fontValues]
            const shapedPayload = {
                appId: Parse.User.current().attributes.app.id,
                id: updatedBlock.id,
                title: updatedBlock.title,
                type: 'integration',
                html: updatedBlock.html,
                css: updatedBlock.css,
                js: updatedBlock.js,
                sdkVersion: updatedBlock.sdkVersion,
                editorSettings: {
                    mockVariables: parsedVariables,
                },

                resources: mergedResources,
                partnerFields: {
                    json: updatedBlock.json ?? '',
                    partnerId,
                    previewUrl: updatedBlock.partnerFields.previewUrl,
                    releaseNotes: updatedBlock.partnerFields.releaseNotes,
                    status: updatedBlock.partnerFields.status,
                },
                blockVersion,
            }
            shapedPayload.blockVersion = getBlockVersion(
                updatedBlock.partnerFields.releaseNotes
            )

            const response = await saveCustomBlock(shapedPayload)
            if (!response?.slugHash) {
                return rejectWithValue(response)
            }

            return { block: response, id: response.id }
        } catch (error) {
            console.log('error', error)
            if (error.message[0]) {
                const errorContent = error.message[0].split(' ')

                if (errorContent[0].includes('resources')) {
                    errorContent[0] = 'Font'
                    return errorContent.join(' ')
                }
            }

            return rejectWithValue(error.message[0])
        }
    }
)

export const customBlockSlice = createSlice({
    name: 'customBlocks',
    initialState: customBlocksAdapter.getInitialState({
        initialized: false,
        loading: 'idle',
        error: null,
    }),
    reducers: {
        customBlocksUpdateOne: (state, action) => {
            if (state.entities[action.payload.id]) {
                return customBlocksAdapter.updateOne(
                    state,
                    action.payload.block
                )
            }
            return customBlocksAdapter.addOne(state, action.payload.block)
        },
    },
    extraReducers: {
        [fetchCustomBlocks.pending]: (state) => {
            state.loading = 'pending'
        },
        [fetchCustomBlocks.fulfilled]: (state, { payload }) => {
            state.loading = 'fulfilled'
            customBlocksAdapter.upsertMany(state, payload)
            state.initialized = true
        },
        [fetchCustomBlocks.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        },
        [saveBlock.pending]: (state, { payload }) => {
            state.loading = 'pending'
        },
        [saveBlock.fulfilled]: (state, action) => {
            const {
                payload: { block, id },
            } = action
            state.loading = 'fulfilled'
            state.error = null
            const shapedPayload = {
                type: 'custom',
                id: block.id,
                slugHash: block.slugHash,
                title: block.title,
                resources: block.resources,
                thumbnail: block.thumbnail,
                editorSettings: block.editorSettings,
                urls: block.urls,
                status: block.status,
                metadata: block.metadata,
                html: block.html,
                css: block.css,
                js: block.js,
                lastSavedAt: block.lastSavedAt,
                createdAt: block.createdAt,
                updatedAt: block.updatedAt,
                sdkVersion: block.sdkVersion,
            }

            if (state.entities[block.id]) {
                return customBlocksAdapter.updateOne(state, {
                    id: block.id,
                    changes: shapedPayload,
                })
            }

            return customBlocksAdapter.addOne(state, shapedPayload)
        },
        [saveBlock.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        },
        [saveIntegrationBlock.pending]: (state) => {
            state.loading = 'pending'
        },
        [saveIntegrationBlock.fulfilled]: (state, action) => {
            state.loading = 'fulfilled'
            state.error = null
            customBlocksAdapter.upsertOne(state, action.payload.block)
        },
        [saveIntegrationBlock.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        },
        [submitIntegrationBlock.pending]: (state) => {
            state.loading = 'pending'
        },
        [submitIntegrationBlock.fulfilled]: (state, action) => {
            state.loading = 'fulfilled'
            state.error = null
            customBlocksAdapter.upsertOne(state, action.payload.block)
        },
        [submitIntegrationBlock.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        },
        [archiveBlock.fulfilled]: (state, action) => {
            state.loading = 'fulfilled'
            state.error = null
            customBlocksAdapter.removeOne(state, action.payload.id)
        },
        [archiveBlock.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.payload.message
        },
    },
})

export const { customBlocksUpdateOne } = customBlockSlice.actions

export default customBlockSlice.reducer
