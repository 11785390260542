import getHeaders from 'store/_utils/get-headers'
import { JSONResponse } from './assets-slice'

const assetsURL = import.meta.env.VITE_TC_ASSETS_API as string

const fetchAppIcons = async (appId: string) => {
    const headers = await getHeaders()
    const response = await fetch(`${assetsURL}?category=icon`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
            'app-id': appId,
        },
    })

    const data = await response.json()
    const dataWithFileNames = data.assets.map((iconData, index) => ({
        ...iconData,
        metadata: {
            ...iconData.metadata,
            fileName: iconData.metadata.fileName ?? `icon-${index}`,
        },
    }))

    return { assets: dataWithFileNames }
}

const fetchDefaultIcons = async (appId: string) => {
    const headers = await getHeaders()
    const response = await fetch(`${assetsURL}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
            'app-id': appId,
            'default-icon-options': true,
        },
    })

    const data = await response.json()

    const dataWithFileNames = data.assets.map((iconData, index) => ({
        ...iconData,
        metadata: {
            ...iconData.metadata,
            fileName: iconData.metadata.fileName ?? `icon-${index}`,
        },
    }))

    return { assets: dataWithFileNames }
}

const createAppIcon = async (appId: string, file: string, fileName: string) => {
    const headers = await getHeaders()

    const body = {
        type: 'image',
        data: file,
        appId,
        category: 'icon',
        fileName,
    }

    const response: JSONResponse = await fetch(assetsURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(body),
    })
    const res = await response.json()
    return res
}

const deleteAsset = async (appId: string, assetId: string) => {
    const headers = await getHeaders()
    const response = await fetch(`${assetsURL}/${assetId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
            'app-id': appId,
        },
    })

    return assetId
}

const replaceIcon = async (
    appId: string,
    iconValues: { id: string; base64String: string; fileName: string }
) => {
    const headers = await getHeaders()

    const body = {
        type: 'image',
        category: 'icon',
        appId,
        data: iconValues.base64String,
        fileName: iconValues.fileName,
    }

    const response = await fetch(`${assetsURL}/${iconValues.id}`, {
        method: 'PUT',
        headers: {
            accept: 'application/json',

            ...headers,
            'app-id': appId,
        },
        body: JSON.stringify(body),
    })

    const data = await response.json()

    return data
}

const normalizeFontData = (data) => {
    const normalizedFonts = {}

    data.assets.map((font) => {
        const { fontMetaData } = font.metadata

        fontMetaData.map((fontData) => {
            if (normalizedFonts[fontData.postScriptName]) {
                return
            }
            normalizedFonts[fontData.postScriptName] = {
                fontFamily: font.metadata.fontFamily,
                fontFace: fontData.fontFamily,
                fontWeight: fontData.fontWeight,
                fontStyle: fontData.fontStyle,
                fullName: fontData.fullName,
                fontSubfamily: fontData.fontSubfamily,
                postScriptName: fontData.postScriptName,
                url: font.url,
                fileExtension: font.metadata.fileType,
                ID: fontData.ID,
                fontAssetID: font.id,
            }
        })
    })
    return normalizedFonts
}

const fetchDefaultFonts = async (appId: string) => {
    const headers = await getHeaders()

    const response = await fetch(assetsURL, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            ...headers,
            'default-font-options': true,
            'app-id': appId,
        },
    })
    const data = await response.json()

    const normalized = normalizeFontData(data)

    return normalized
}

const fetchCustomFontAssets = async (appId: string) => {
    const headers = await getHeaders()

    const response = await fetch(`${assetsURL}?type=font`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            ...headers,
            'app-id': appId,
        },
    })
    const data = await response.json()

    const normalized = normalizeFontData(data)
    return normalized
}

export {
    fetchAppIcons,
    createAppIcon,
    deleteAsset,
    replaceIcon,
    fetchDefaultFonts,
    fetchCustomFontAssets,
    fetchDefaultIcons,
}
