import { createQueryString } from '.'
import AuthClient from 'utils/authClient';
import { jwtDecode } from 'jwt-decode';
import { Parse } from 'services/parse';

const redirectUrl = `${import.meta.env.VITE_TC_DASHBOARD_URL}/create/`
const clientId = import.meta.env.VITE_TC_SHOPIFY_CLIENT_ID
const shopifyAPIVersion = import.meta.env.VITE_TC_SHOPIFY_API_VERSION

const scopes = [
    "write_cart_transforms",
    "read_content",
    "read_all_orders",
    "read_product_listings",
    "write_metaobjects",
    "read_metaobjects",
    "unauthenticated_read_metaobjects",
    "read_products",
    "unauthenticated_read_selling_plans",
    "read_customers",
    "unauthenticated_read_product_inventory",
    "read_analytics",
    "write_orders",
    "write_script_tags",
    "read_inventory",
    "write_draft_orders",
    "write_customers",
    "write_mobile_platform_applications",
    "read_mobile_payments",
    "read_discounts",
    "read_checkouts",
    "unauthenticated_read_product_tags",
    "read_themes",
    "read_draft_orders",
    "read_locations",
    "read_script_tags",
    "write_mobile_payments",
    "unauthenticated_read_customers",
    "write_translations",
    "write_discounts",
    "write_products",
    "read_metaobject_definitions",
    "unauthenticated_read_product_listings",
    "unauthenticated_read_product_pickup_locations",
    "write_metaobject_definitions",
    "unauthenticated_read_checkouts",
    "write_product_listings",
    "read_orders",
    "read_mobile_platform_applications",
    "unauthenticated_read_collection_listings",
    "write_content",
    "write_inventory",
    "read_translations",
    "customer_read_orders",
    "unauthenticated_write_customers",
    "customer_read_draft_orders",
    "unauthenticated_read_customer_tags",
    "unauthenticated_read_content",
    "unauthenticated_write_checkouts",
    "read_cart_transforms",
    "read_fulfillments",
]

const createGid = (id, type) => {
    if (typeof id === 'string' && id.startsWith('gid://shopify/')) {
        return id
    }
    return `gid://shopify/${type}/${id}`
}

const decodeGid = (id) => {
    let decodedId = id

    try {
        decodedId = window.atob(id)
    } catch (error) {
        if (import.meta.env.DEV) console.log(error)
    }

    if (typeof id === 'string' && decodedId.includes('gid://shopify/')) {
        return Number(decodedId.split('/').pop())
    }
    return decodedId
}

export const decodeIfBase64 = (id) => {
    let decodedId = id

    // value is already in the format we're looking for if it is a number
    if (typeof id === 'number') {
        return id
    }

    try {
        decodedId = window.atob(id)
    } catch (error) {
        if (import.meta.env.DEV) console.log(error)
    }

    if (typeof id === 'string' && decodedId.includes('gid://shopify/')) {
        return Number(decodedId.split('/').pop())
    }
    return decodedId
}

export const getIdFromGid = (gid) => {
    if (typeof gid === 'string' && gid.includes('gid://shopify/')) {
        return Number(gid.split('/').pop())
    }
    if (typeof gid === 'number') {
        return gid
    }
    if (typeof gid === 'string' && !Number.isNaN(gid)) {
        return Number(gid)
    }
    return decodeIfBase64(gid)
}

const extractDomain = (url) => {
    let domain
    domain = url.includes('://') ? url.split('/')[2] : url.split('/')[0]
    domain = domain.split(':')[0]
    return domain
}

const shopifyStoreParseName = (input) => {
    if (input.includes('.myshopify.com')) {
        return extractDomain(input)
    }
    if (!input.includes('.myshopify.com') && !input.includes('.')) {
        return `${extractDomain(input)}.myshopify.com`
    }
    return extractDomain(input)
}

const randomNonce = () =>
    Math.random().toString(36).slice(2, 15) +
    Math.random().toString(36).slice(2, 15)

const getNonce = (query) => {
    if (query) {
        try {
            return btoa(query)
        } catch (error) {
            console.error(error)
            return btoa(randomNonce())
        }
    }
    return btoa(randomNonce())
}

const decodeHost = (host) => {
    if (host) {
        return atob(host)
    }
    return null
}

const getConnectLink = ({
    host,
    storeUrl,
    siteQuery,
    customRedirectPath = '',
}) => {
    let permissionLink = ''
    // if-else is more readable here
    // eslint-disable-next-line unicorn/prefer-ternary
    // if (host) permissionLink = `${decodeHost(host)}/oauth/authorize?`
    // else
    permissionLink = `${shopifyStoreParseName(storeUrl)}/admin/oauth/authorize?`

    const shopifyQuery = {
        client_id: clientId,
        scope: scopes.join(','),
        redirect_uri: customRedirectPath
            ? `${import.meta.env.VITE_TC_DASHBOARD_URL}/${customRedirectPath}/`
            : redirectUrl,
        state: getNonce(createQueryString(siteQuery)),
    }

    return `https://${permissionLink}${createQueryString(shopifyQuery)}`
}

const verifyShopifyInstallStatus = (domain, accessToken) => {
    const graphqlUrl = `https://${domain}.myshopify.com/api/${shopifyAPIVersion}/graphql.json`

    const request = new Request(graphqlUrl, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/graphql',
            'X-Shopify-Storefront-Access-Token': accessToken,
        }),
        body: `{shop{name}}`,
    })
    return fetch(request)
}

const redirectToShopifyAuth = (
    shop,
    siteQuery = null,
    customRedirectPath = null
) => {
    const connectLink = getConnectLink({
        storeUrl: shop,
        siteQuery,
        customRedirectPath,
    })

    // Let the user see 1 second of the toast, then redirect
    setTimeout(() => {
        window.location.href = connectLink
    }, 1000)
}

const connectStore = (shopifyStore, redirectPath) => {
    window.top.location.href = getConnectLink({
        storeUrl: shopifyStore,
        siteQuery: null,
        customRedirectPath: redirectPath,
    })
}

const installShopifyApp = (params) => {
    const query = {
        utm_source: 'shopify-app-store',
        mkto_utm_campaign__c: 'shopify-app-store',
        mkto_utm_content__c: 'shopify-app-store-content',
        mkto_utm_medium__c: 'shopify-app-store-medium',
        mkto_utm_source__c: 'shopify-app-store-source',
        mkto_utm_term__c: 'shopify-app-store-terms',
    }

    redirectToShopifyAuth(params.shop, query)
}

const generateAuth0JWT = async (params) => {
  const API_URL = import.meta.env.VITE_TC_AUTH_AUTH0_AUDIENCE;

  try {
    const response = await fetch(`${API_URL}/auth0/shopify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
}

const loginWithAuth0 = async (params, retries = 3, delay = 2000) => {
    for (let attempt = 0; attempt < retries; attempt++) {
        try {
            const { token } = await generateAuth0JWT(params);
            const decodedToken = jwtDecode(token.access_token);
            const email = decodedToken.email;

            const authData = {
                id: email,
                hmac: token.access_token,
                timestamp: new Date(),
            };

            await Parse.User.logInWith('auth0', { authData });
            AuthClient.saveLocalTokens(Parse.User.current(), token.access_token, '');
            return;
        } catch (error) {
            if (attempt < retries - 1) {
                await new Promise(resolve => setTimeout(resolve, delay));
            } else {
                throw new Error("Failed to log in with Auth0 after multiple attempts.");
            }
        }
    }
}

const loginWithShopify = async (params, retries = 3, delay = 2000) => {
    for (let attempt = 0; attempt < retries; attempt++) {
        try {
            await Parse.User.logInWith('shopify', {
                authData: { id: params.shop, hmac: params },
            });
            return;
        } catch (error) {
            if (attempt < retries - 1) {
                await new Promise(resolve => setTimeout(resolve, delay));
            } else {
                throw new Error("Failed to log in with Shopify after multiple attempts.");
            }
        }
    }
}

export {
    createGid,
    decodeGid,
    getConnectLink,
    getNonce,
    verifyShopifyInstallStatus,
    redirectToShopifyAuth,
    connectStore,
    installShopifyApp,
    loginWithAuth0,
    loginWithShopify,
}
