import { RootState } from 'store'
import { CustomScreenForm } from 'types/forms/custom-screen-types'
import screensAdapter from './screens-adapter'

const screensSelectors = screensAdapter.getSelectors(
    (state: RootState) => state.screens
)

const {
    selectEntities: selectScreenEntities,
    selectById: selectScreenById,
    selectAll: selectAllScreens,
} = screensSelectors

const selectSortedHybridPages = (state: RootState) => {
    const screens = selectAllScreens(state)
    const sortedScreens = screens.sort((a, b) =>
        b.updatedAt.localeCompare(a.updatedAt)
    )
    return sortedScreens.filter((screen) => screen.type === 'hybrid-page')
}

const selectSortedScreens = (
    state: RootState,
    appStudioCustomScreens: boolean
) => {
    const screens = selectAllScreens(state)

    const sortedScreens = screens.sort((a, b) =>
        b.updatedAt.localeCompare(a.updatedAt)
    )

    if (appStudioCustomScreens) {
        return sortedScreens.filter(
            (screen) =>
                screen.type === 'hybrid-page' ||
                screen.type === 'blocks-page' ||
                screen.type === 'single'
        )
    }

    const filteredScreens = sortedScreens.filter(
        (screen) => screen.__t === 'hybrid-page' || screen.__t === 'blocks-page'
    )
    return filteredScreens.map((screen) => {
        return { ...screen, type: screen.__t }
    })
}

const selectScreensInitialized = (state: RootState) =>
    Boolean(state.screens.initialized)

const selectScreenFormById = (
    state: RootState,
    id: string,
    screenType: 'web-based' | 'block-based'
): CustomScreenForm | null => {
    if (!id) return null
    // If id is 'create', return a blank form
    if (id === 'create') {
        if (screenType === 'web-based') {
            return {
                type: 'hybrid-page',
                title: '',
                data: {
                    contentUrl: '',
                    isCustomerAuthRequired: false,
                    displayTitle: true,
                },
            }
        } else {
            return {
                type: 'blocks-page',
                title: '',
                data: {
                    stickyHeader: [],
                    stickyFooter: [],
                    scrollableContent: [],
                    isCustomerAuthRequired: false,
                    displayTitle: true,
                },
            }
        }
    }

    const screen = selectScreenById(state, id)
    if (!screen) return null

    const sharedScreenData = {
        type: screen?.type,
        title: screen?.title,
        data: {
            isCustomerAuthRequired: screen?.data.isCustomerAuthRequired,
            displayTitle: Boolean(screen.data?.displayTitle),
        },
    }

    if (screenType === 'web-based') {
        sharedScreenData.data.contentUrl = screen.data?.contentUrl
    }
    if (screenType === 'block-based') {
        sharedScreenData.data.scrollableContent =
            screen.data?.scrollableContent?.map((block) => {
                return { ...block, id: block._id }
            })
    }

    return sharedScreenData
}

const selectCountOfCustomScreens = (state: RootState) => {
    const screens = selectAllScreens(state)
    return screens.length
}

export {
    selectScreenEntities,
    selectScreenById,
    selectAllScreens,
    selectSortedHybridPages,
    selectSortedScreens,
    selectScreensInitialized,
    selectScreenFormById,
    selectCountOfCustomScreens,
}
