import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'
import { Parse } from 'services/parse'
import { selectBillingCustomer } from 'store/Customers'

const parseApiUrl = import.meta.env.VITE_TC_PARSE_API
const baseApiUrl = parseApiUrl.substring(0, parseApiUrl.lastIndexOf('/'))

interface Invoice {
    adminId: string
    appId: string
    billingDateRange: {
        startDate: string
        endDate: string
    }
    createdAt: string
    customerId: string
    discounts: {
        discountList: []
    }
    id: string
    provider: 'chargebee'
    status: 'paid' | 'payment_requested'
    subtotal: number
    test: boolean
    total: number
    type: 'recurring' | string
    updatedAt: string
}

interface InvoicesState {
    data: Invoice[]
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: InvoicesState = {
    data: [],
    loading: 'idle',
    error: null,
    initialized: false,
}

export const fetchBillingInvoices = createAsyncThunk(
    'invoices/fetchBillingInvoices',
    async (_, { getState }) => {
        const headers = await getHeaders()
        const appId = Parse.User.current().attributes.app.id

        try {
            const response = await fetch(
                `${baseApiUrl}/billing/list-invoices-by-app-id/${appId}`,
                {
                    method: 'GET',
                    headers: {
                        ...headers,
                        'app-id': appId,
                        'Content-Type': 'application/json',
                    },
                }
            )
            return response.json()
        } catch (error) {
            throw new Error(`Unable to retrieve billing invoices. ${error}`)
        }
    }
)

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBillingInvoices.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchBillingInvoices.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(fetchBillingInvoices.rejected, (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
            state.error = action.error
        })
    },
})

export const selectInvoiceData = (state: any) => state.invoices.data

export const selectUnpaidInvoicesOverThirtyDays = (state: any) => {
    const customer = selectBillingCustomer(state)

    // Dashboard header and lock screen can be overridden from the customer object (set in toolshed)
    if (customer && customer.overrides?.invoiceDashboardUnlock) {
        return false
    }

    const invoices = selectInvoiceData(state)

    return invoices.some((invoice: Invoice) => {
        const currentDate = new Date()
        const invoiceDate = new Date(invoice.createdAt)

        const timeDifference = currentDate.getTime() - invoiceDate.getTime()
        const daysDifference = timeDifference / (1000 * 3600 * 24)

        return daysDifference >= 30 && invoice.status === 'payment_requested'
    })
}

export const selectNumberOfUnpaidInvoices = (state: any) => {
    const customer = selectBillingCustomer(state)

    // Dashboard header and lock screen can be overridden from the customer object (set in toolshed)
    if (customer && customer.overrides?.invoiceDashboardUnlock) {
        return 0
    }

    const invoices = selectInvoiceData(state)

    const numberOfUnpaidInvoices = invoices.reduce(
        (accum: number, invoice: Invoice) =>
            invoice.status === 'payment_requested' ? accum + 1 : accum + 0,
        0
    )

    return numberOfUnpaidInvoices
}

export default invoicesSlice.reducer
