import { Parse } from 'services/parse'
import { grabShopifyDomain } from 'services/parse/Integrations/fetchers.jsx'

export const compareOrder = (a, b) =>
    a.order > b.order ? 1 : b.order > a.order ? -1 : 0

export function checkForTypeOfIntegrationEnabled(integrations, integrationSet) {
    const enabledIntegrations = integrations.filter(
        (integration) =>
            integrationSet.has(integration.name) && integration.enabled
    )

    return enabledIntegrations?.length > 0
}

export const runRechargeOauth = () => {
    const url = `https://shopifysubscriptions.com/partners/app/b03271aecb019c863f0324b8d164fc595be1e4aac4e659cb9512daa386f81ef6/install?myshopify_domain=${grabShopifyDomain()}`
    window.location = url
}

export const stripPlusSign = (string) => string.replace(/\+$/, '')

export const getRechargeCheckoutPlatform = async (token) => {
    try {
        const platform = await Parse.Cloud.run(
            'integrations_recharge_getCheckoutPlatform',
            {
                _ApplicationId: 'e7zOnAnUJdteQlnooWsAgTL9VU9QmpgFO3G8Qaon',
                apiToken: token,
            }
        )
        return platform.checkout_platform
    } catch (error) {
        throw new Error(`Unable to checkout recharge platform: ${error}`)
    }
}

const PARSE_APP_ID = import.meta.env.VITE_TC_PARSE_APPLICATION_ID;
const ASSETS_API_URL = import.meta.env.VITE_TC_ASSETS_API.replace('/assets', '');
const ASSETS_CDN_URL = ASSETS_API_URL.includes('.xyz') ? 'https://assets.tapcart.xyz' : 'https://assets.tapcart.com';

export const formatUrl = (url) => url?.replace(`${ASSETS_API_URL}/api/files/${PARSE_APP_ID}`, ASSETS_CDN_URL);
