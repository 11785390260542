import DeepDiff from 'deep-diff'
import flatten from 'lodash/flatten'
import tapcartAnalytics from './index'

const convertToAmplitudeValues = (customizations) => ({
    'gift-card-banner-image-set': !!customizations.giftCardBackground,
    'gift-card-message-set': !!customizations.giftCardMessage,
    'discount-banner-image-set': !!customizations.discountBackground,
    'discount-message-set': !!customizations.discountMessage,
    'favorites-banner-image-set': !!customizations.favoritesBackground,
    'favorite-message-set': !!customizations.favoriteMessage,
    'square-images-setting': customizations.squareImages === 'true',
    'filters-set': customizations.filters.length > 0,
})

// Remove parse ID's to match new customizations
const processOriginalFilters = (originalFilters = []) => {
    for (const filterGroup of originalFilters) {
        delete filterGroup.id
        for (const filter of filterGroup.filters) {
            delete filter.tagId
        }
    }
}

const trackCustomizations = (values) => {
    const { originalCustomizations, customizations } = values

    const { filters: originalFilters, ...originalToDiff } =
        originalCustomizations
    const { filters, ...customizationsToDiff } = customizations

    let changedFields = []
    processOriginalFilters(originalFilters)
    const customizationsDiff = DeepDiff(originalToDiff, customizationsToDiff)
    const filtersDiff = DeepDiff(originalFilters, filters)
    // Determine if there is a diff on filters
    if (filtersDiff) changedFields.push('filters')

    if (customizationsDiff) {
        for (const change of customizationsDiff) {
            changedFields.push(change.path)
            changedFields = flatten(changedFields)
        }
    }

    if (changedFields.length > 0) {
        tapcartAnalytics.setUserProperties(
            convertToAmplitudeValues(customizations)
        )
        tapcartAnalytics.logEvent('customizations-updated', {
            fields: changedFields,
        })
    }
}

export default trackCustomizations
