/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { updateSettingsProperty } from 'store/Settings/settings-slice'
import getHeaders from 'store/_utils/get-headers'
import { readWidget, createWidget, deleteWidget } from './widget-slice'
import { updateQrImage } from './qr-image-slice'

// CaptureKit is stored and updated in 2 places:
// 1. Config data in our MongoDB database
//    - ${captureKitAPI}/api/qrcode/:appId
// 2. CaptureKit Widget script hosted on the Merchants' web stores
//    - https://unpkg.com/tapcart-embed@latest/dist/tc-plugins.iife.js?appId=:appId
const captureKitAPI = import.meta.env.VITE_TC_CAPTURE_API

export const createCaptureKit = createAsyncThunk(
    'captureKit/createCaptureKit',
    async (
        { captureKitV2Enabled, activate, qrCode, captureKitSettings },
        { dispatch, getState }
    ) => {
        const state = getState()
        const appId = state.app?.data?.id

        const response = await dispatch(
            updateCaptureKit({ activate, qrCode, captureKitSettings })
        )

        // Prevent creating CaptureKit widget if CaptureKit V2 is enabled
        // V2 attaches widget via the backend
        if (
            !captureKitV2Enabled &&
            response.meta.requestStatus === 'fulfilled'
        ) {
            await dispatch(createWidget(appId))
        }

        if (response.meta.requestStatus !== 'fulfilled') {
            throw new Error('Failed to create CaptureKit')
        }

        return response.payload
    }
)

export const readCaptureKit = createAsyncThunk(
    'captureKit/readCaptureKit',
    async (arg, { dispatch, getState }) => {
        const headers = await getHeaders()
        const state = getState()
        const appId = state.app?.data?.id

        dispatch(initializeAasa(headers))
        dispatch(readWidget(appId))
        const res = fetch(`${captureKitAPI}/api/qrcode/${appId}`, {
            method: 'GET',
            headers,
        }).then((response) => response.json())

        return res
    }
)

export const updateCaptureKit = createAsyncThunk(
    'captureKit/updateCaptureKit',
    async ({ activate, qrCode, captureKitSettings }, { dispatch }) => {
        const results = await Promise.all([
            dispatch(updateQrImage({ qrCode })),
            dispatch(
                updateCaptureKitSettings({ activate, captureKitSettings })
            ),
        ])

        results.forEach((result) => {
            if (result.error) throw result.error
        })

        return results[1].payload
    }
)

export const updateCaptureKitSettings = createAsyncThunk(
    'captureKit/updateCaptureKitSettings',
    async ({ activate, captureKitSettings }, { getState }) => {
        const headers = await getHeaders()
        const state = getState()
        const appId = state.app.data.id

        const captureKitSettingsBody = JSON.stringify({
            appId,
            settings: captureKitSettings,
        })

        if (activate)
            await fetch(`${captureKitAPI}/api/qrcode/${appId}/activate`, {
                method: 'PUT',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ appId }),
            })

        const res = await fetch(`${captureKitAPI}/api/qrcode/${appId}`, {
            method: 'PUT',
            headers: { ...headers, 'Content-Type': 'application/json' },
            body: captureKitSettingsBody,
        })

        if (res.status === 200) {
            return res.json()
        }
        throw new Error('Failed to update Capture Kit Settings')
    }
)

export const deleteCaptureKit = createAsyncThunk(
    'captureKit/deleteCaptureKit',
    async (args, { captureKitV2Enabled, dispatch, getState }) => {
        const state = getState()
        const appId = state.app.data.id

        // Prevent deleting CaptureKit widget if CaptureKit V2 is enabled
        // V2 handles widget attachment/detachment via the backend
        if (!captureKitV2Enabled) await dispatch(deleteWidget(appId))

        const headers = await getHeaders()
        const res = await fetch(
            `${captureKitAPI}/api/qrcode/${appId}/deactivate`,
            {
                method: 'PUT',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body: JSON.stringify({ appId }),
            }
        ).then((response) => response.json())

        return res
    }
)

export const initializeAasa = createAsyncThunk(
    'captureKit/initializeAasa',
    async (headers, { getState }) => {
        const state = getState()
        const appId = state.app.data.id
        const body = JSON.stringify({ appId })

        const initializeLinks = fetch(
            `${captureKitAPI}/api/qrcode/${appId}/links`,
            {
                method: 'PUT',
                body,
                headers: { ...headers, 'Content-Type': 'application/json' },
            }
        )
        const initiliazeAasa = fetch(
            `${captureKitAPI}/api/qrcode/${appId}/aasa`,
            {
                method: 'PUT',
                body,
                headers: { ...headers, 'Content-Type': 'application/json' },
            }
        )

        const res = await Promise.all([initializeLinks, initiliazeAasa])
        return res
    }
)

export const createAppClip = createAsyncThunk(
    'captureKit/createAppClip',
    async (updatedValues, { dispatch, getState }) => {
        const state = getState()
        const headers = await getHeaders()
        const appId = state.app.data.id
        const body = JSON.stringify({ appId })

        const activateRes = await fetch(
            `${captureKitAPI}/api/qrcode/${appId}/activate-app-clips`,
            {
                method: 'PUT',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body,
            }
        )

        if (activateRes.status === 200) {
            return dispatch(updateSettingsProperty(updatedValues))
        }

        throw new Error('Failed to activate App Clip')
    }
)

export const removeAppClip = createAsyncThunk(
    'captureKit/removeAppClip',
    async (updatedValues, { dispatch, getState }) => {
        const state = getState()
        const headers = await getHeaders()
        const appId = state.app.data.id
        const body = JSON.stringify({ appId })

        const deactivateRes = await fetch(
            `${captureKitAPI}/api/qrcode/${appId}/deactivate-app-clips`,
            {
                method: 'PUT',
                headers: { ...headers, 'Content-Type': 'application/json' },
                body,
            }
        )
        if (deactivateRes.status === 200) {
            return dispatch(updateSettingsProperty(updatedValues))
        }

        throw new Error('Failed to remove App Clip')
    }
)

export const captureKitSlice = createSlice({
    name: 'captureKit',
    initialState: {
        loading: 'idle',
        data: {},
        error: undefined,
        initialized: false,
    },
    extraReducers: {
        [createCaptureKit.pending]: (state) => {
            state.loading = 'pending'
        },
        [createCaptureKit.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        },
        [createCaptureKit.rejected]: (state) => {
            state.loading = 'rejected'
        },
        [readCaptureKit.pending]: (state) => {
            state.loading = 'pending'
        },
        [readCaptureKit.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
            state.initialized = true
        },
        [readCaptureKit.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.initialized = true
            state.error = action.error
        },
        [updateCaptureKit.pending]: (state) => {
            state.loading = 'pending'
        },
        [updateCaptureKit.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        },
        [updateCaptureKit.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        },
        [deleteCaptureKit.pending]: (state) => {
            state.loading = 'pending'
        },
        [deleteCaptureKit.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        },
        [deleteCaptureKit.rejected]: (state) => {
            state.loading = 'rejected'
        },
    },
})

export const selectCaptureKit = (state) => state.captureKit.data.settings

export const selectShortId = (state) => state.captureKit.data.sid

export const selectCaptureKitInitialized = (state) =>
    Boolean(state.captureKit.initialized)

export const selectIsCaptureKitLoading = (state) =>
    Boolean(state.captureKit.loading === 'pending')

export default captureKitSlice.reducer
