import queryString from 'query-string'
import { isEmbeddedWindow } from './misc'
import { DateTime } from 'luxon'

const detectDashboardType = () => {
    if (window && isEmbeddedWindow()) {
        return 'embedded-dashboard'
    }

    return 'dashboard'
}

const getQueryObject = (query) => {
    if (typeof query === 'string') {
        try {
            if (query) {
                return queryString.parse(query)
            }
            return null
        } catch (error) {
            console.error('Utils: Failed to parse query params', error)
        }
    }

    return query
}

const createQueryString = (queryObject) => {
    if (queryObject) {
        return queryString.stringify(queryObject)
    }
    return ''
}

const toRelativeTimeFormat = ({ iso, date }) => {
    let now = DateTime.now()
    let given
    if (iso) {
        given = DateTime.fromISO(iso)
    } else if (date) {
        given = DateTime.fromJSDate(date)
    }

    if (!given) {
        return null
    }

    let { days } = now.diff(given, 'days').toObject()

    if (days > 7) {
        return given.toFormat('EEE, DD')
    } else {
        return given.toRelative()
    }
}

function isValidPathToInitApp(pathname) {
    // these pages happen outside of the User being authenticated
    // so running 'initializeApp()' on them will cause session errors
    const patterns = [
        /install/i,
        /login/i,
        /invalidsession/i,
        /redirect-login/i,
    ]

    return !patterns.some((pattern) => pattern.test(pathname))
}

export {
    createQueryString,
    detectDashboardType,
    getQueryObject,
    toRelativeTimeFormat,
    isValidPathToInitApp,
}
