import DeepDiff from 'deep-diff'
import tapcartAnalytics from './index'

const trackPaymentMethodChange = (values) => {
    const { originalPaymentMethods, paymentMethods } = values

    if (paymentMethods.length > 0) {
        const paymentMethodsChanged = DeepDiff(
            originalPaymentMethods,
            paymentMethods
        )

        if (paymentMethodsChanged) {
            const data = {
                'payment-methods-enabled': paymentMethods.map(
                    (method) => method.name
                ),
                'count-of-payment-methods': paymentMethods.length,
            }

            tapcartAnalytics.setUserProperties(data)
            tapcartAnalytics.logEvent('payment-methods-updated')
        }
    }
}

export default trackPaymentMethodChange
