import ProtectedRoute from 'components/common/ProtectedRoute'
import React, { useEffect, Suspense } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import tapcartAnalytics from 'services/user-tracking'
import { Parse } from 'services/parse'
import ProtectedRoutes from 'utils/constants/protectedRoutes'
import Loader from 'components/common/Loader'
import classNames from 'classnames'

const Dashboard = React.lazy(() => import('pages/Dashboard'))
const InstallPage = React.lazy(() => import('pages/InstallPage'))
const LoginPage = React.lazy(() => import('pages/LoginPage'))
const PageNotFound = React.lazy(() => import('pages/PageNotFound'))
const InvalidSession = React.lazy(() => import('pages/InvalidSession'))
const SignupPage = React.lazy(() => import('pages/SignupPage'))
const ChargebeeRedirect = React.lazy(() => import('pages/ChargebeeRedirect'))
const SubscriptionUpdates = React.lazy(() => import('pages/SubscriptionUpdates'))
const SignIn = React.lazy(() => import('pages/SignIn'));
import AuthClient from 'utils/authClient';

export default function Routes({ isLoaded }) {
    const history = useHistory()

    useEffect(() => {
        // To track the first pageview upon load
        tapcartAnalytics.logPageView();
        // To track the subsequent pageviews
        history.listen(tapcartAnalytics.logPageView);

        const validateSession = async () => {
            if (!Parse.User.current()) return;

            try {
                await Parse.User.current().fetch();
                if (!AuthClient.validateSession()) {
                    Parse.User.logOut();
                    clearUserSession();
                }
            } catch (error) {
                Parse.User.logOut().finally(clearUserSession);
            }
        };

        const clearUserSession = () => {
            // Get rid of Parse Variables from localStorage
            const localStorageKeys = Object.keys(window.localStorage);
            const localStorageRemovableValues = [];
            for (const key of localStorageKeys) {
                if (key.includes('Parse')) {
                    localStorageRemovableValues.push(key);
                }
            }
            for (const key of localStorageRemovableValues) {
                window.localStorage.removeItem(key);
            }

            // Clear the Auth token as well
            AuthClient.clearSessionToken();

            history.push('/login');
        };

        validateSession();
    }, [history]);

    return (
        <Suspense
            fallback={
                <div className={classNames('dashboard', 'loading')}>
                    <Loader type="logo" style={{ height: 100, width: 100 }} />
                </div>
            }
        >
            <Switch>
                <Route
                    exact
                    path="/"
                    render={(properties) =>
                        Parse.User.current() ? (
                            <Redirect to="/design" />
                        ) : (
                            <SignIn {...properties} />
                        )
                    }
                />
                <Route
                    exact
                    path="/login"
                    render={(properties) =>
                        Parse.User.current() ? (
                            <Redirect to="/design" />
                        ) : (
                            <SignIn {...properties} />
                        )
                    }
                />
                <ProtectedRoute
                    exact
                    path={ProtectedRoutes}
                    render={(properties) => (
                        <Dashboard {...properties} isLoaded={isLoaded} />
                    )}
                />
                <Route exact path="/create" component={SignupPage} />
                <Route exact path="/install" component={InstallPage} />
                <Route path="/invalidSession" component={InvalidSession} />
                <Route path="/redirect-login" component={SignIn} />
                <Route
                    path="/chargebee-subscription"
                    component={ChargebeeRedirect}
                />
                <Route
                    path="/downgrade"
                    component={SubscriptionUpdates}
                />
                <Route
                    path="/upgrade"
                    component={SubscriptionUpdates}
                />
                <Route path="*" component={PageNotFound} />
            </Switch>
        </Suspense>
    )
}
