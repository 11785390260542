import { Parse } from 'services/parse';
import { errorToast } from 'components/common/Toasts';

class AuthClient {
  static AUTH_HEADER = 'Authorization';

  static getAuthUrl() {
    const baseUrl = import.meta.env.VITE_TC_AUTH_AUTH0_AUDIENCE;
    return baseUrl ? baseUrl + '/auth' : 'http://localhost:8010/auth';
  }

  static getHeaders() {
    return {
      'Content-Type': 'application/json',
      [this.AUTH_HEADER]: this.getJwtToken()
    };
  }

  static async logInWithAuth0(userToken, accessToken) {
    try {
      this.saveLocalTokens(userToken, accessToken, '');
    } catch (error) {
      const msg = `Error in AuthClient.login.  msg: ${error.message}`;
      console.log(msg);
      throw error;
    }
    return null;
  }

  static getJwtToken() {
    return sessionStorage.getItem('jwtToken');
  }

  static saveSessionToken(token, decodedToken) {
    sessionStorage.setItem('jwtToken', token);
    sessionStorage.setItem('decodedToken', JSON.stringify(decodedToken));
  }

  static clearSessionToken() {
    sessionStorage.removeItem('jwtToken');
    sessionStorage.removeItem('decodedToken');
  }

  static getClientToken() {
    return localStorage.getItem('userToken');
  }

  static getServerJwtToken() {
    return localStorage.getItem('accessToken');
  }

  static saveLocalTokens(userToken, accessToken, decodedToken) {
    localStorage.setItem('userToken', JSON.stringify(userToken));
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('decodedToken', JSON.stringify(decodedToken));
  }

  static clearLocalTokens() {
    localStorage.removeItem('userToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('decodedToken');
  }

  static closeSession() {
    Parse.User.logOut();
    const localStorageKeys = Object.keys(window.localStorage);
    const localStorageRemovableValues = [];
    for (const key of localStorageKeys) {
      if (key.includes('Parse')) {
        localStorageRemovableValues.push(key);
      }
    }
    for (const key of localStorageRemovableValues) {
      window.localStorage.removeItem(key);
    }
    this.clearSessionToken();
    errorToast('You have been logged out due to inactivity.');
  }

  static validateSession() {
    try {
      if (!!localStorage.getItem('accessToken') && Parse.User.current()) {
        return true;
      } else {
        throw new Error('access token or parse user is missing');
      }
    } catch (error) {
      console.log(`Invalid token.  msg: ${error.message}`);
      return false;
    }
  }
}

export default AuthClient;