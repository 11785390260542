import prettier from 'prettier/standalone'
import babelParser from 'prettier/parser-babel'
import postCss from 'prettier/parser-postcss'
import htmlParser from 'prettier/parser-html'
import { errorToast } from 'components/common/Toasts'

const formatCode = (code, activeFile) => {
    let returnedCode = code

    try {
        returnedCode = prettier.format(code, {
            parser: activeFile === 'js' ? 'babel' : activeFile,
            plugins: [postCss, htmlParser, babelParser],
            endOfLine: 'auto',
        })
        if (activeFile === 'json') {
            const parsedString = JSON.parse(returnedCode)
            return JSON.stringify(parsedString, null, ' ')
        }
    } catch (error) {
        console.log('error', error)
        errorToast(`${activeFile} syntax error`)
    }

    return returnedCode
}
export default formatCode
