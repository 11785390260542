import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { swatches } from '@tapcartinc/tapcart-ui/dist/swatches'

const usePasswordValidation = (inputValue) => {
    // Define our password rules
    const validationRules = {
        symbol: {
            label: 'Requires at least 1 symbol (?,!,etc)',
            rule: '[-!@#$%^&*()+=<>;:{}\\|?/~`]',
        },
        capital: {
            label: 'Requires at least 1 capital letter',
            rule: '[A-Z]',
        },
        number: {
            label: 'Requires at least 1 number (0-9)',
            rule: '[0-9]',
        },
        len: {
            label: 'Requires at least 12 characters',
            rule: '.{12,}',
        },
    }
    // initialze our validation state
    const initialRulesState = {}
    for (const key of Object.keys(validationRules)) {
        initialRulesState[key] = false
    }
    // setup our initial state for validation rules, input value
    const [isPasswordValidated, setPasswordValidation] =
        useState(initialRulesState)
    // given a string value, run the validation test for each rule
    // update state w/ the result of the test once they've all run
    const validateRules = (value) => {
        const updatedRules = { ...isPasswordValidated }
        for (const item in validationRules) {
            updatedRules[item] = new RegExp(validationRules[item].rule).test(
                value
            )
        }
        setPasswordValidation(updatedRules)
    }
    // anytime our input state value changes,
    // run `validateRules` on the new value
    useEffect(() => {
        validateRules(inputValue)
    }, [inputValue])

    // provides a nice little reactive validation table
    const renderValidation = () => (
        <StyledPasswordValidationWrapper>
            {Object.keys(isPasswordValidated).map((validationKey) => (
                <StyledPasswordValidationRule
                    key={`rule-${validationKey}`}
                    validated={isPasswordValidated[validationKey]}
                >
                    {isPasswordValidated[validationKey] ? (
                        <span>&#10003;</span>
                    ) : (
                        <span>&times;&nbsp;</span>
                    )}
                    {validationRules[validationKey].label}
                </StyledPasswordValidationRule>
            ))}
        </StyledPasswordValidationWrapper>
    )

    // return the set of validation states, the defition of the rules, and the render func
    return { isPasswordValidated, validationRules, renderValidation }
}

const StyledPasswordValidationWrapper = styled.div`
    margin-top: 10px;
`

const StyledPasswordValidationRule = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 3px;
    color: ${(properties) =>
        properties.validated ? swatches.success : swatches.gray};

    span {
        margin-right: 10px;
    }
`

export default usePasswordValidation
