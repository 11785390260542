import { css } from 'styled-components'
import { sizes } from './sizes'

export default Object.keys(sizes).reduce((accumulator, label) => {
    accumulator[label] = (...arguments_) => css`
        @media (max-width: ${sizes[label]}px) {
            ${css(...arguments_)};
        }
    `
    return accumulator
}, {})
