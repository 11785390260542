/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import { App } from 'types/dashboard-types'
import { RootState } from 'store'
import { Parse } from 'services/parse'
import { formatAppResponse } from './helpers'
import { shapePaymentMethodsForParse } from 'store/Settings/helpers'
import trackAppStoreListing from 'services/user-tracking/track-app-store-listing-change'

interface AppState {
    data: App | Record<string, never>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: AppState = {
    data: {},
    loading: 'idle',
    error: null,
    initialized: false,
}
export const fetchApp = createAsyncThunk('app/fetchApp', async () => {
    const appParseObject = await Parse.User.current().get('app').fetch()

    const app = formatAppResponse(appParseObject)
    return app
    // TODO: normalize app data
    // const normalized = normalize(app.attributes, appSchema)
    // return normalized
})

const protocol = (value, url) => {
    if (!url) return ''
    if (value) return 'https://'
    return 'http://'
}

const shapeListingUrls = (values) => {
    return {
        marketingURL: `${protocol(
            values.marketingURL.sslEnabled,
            values.marketingURL.value
        )}${values.marketingURL.value}`,
        supportURL: `${protocol(
            values.supportURL.sslEnabled,
            values.supportURL.value
        )}${values.supportURL.value}`,
        privacyURL: `${protocol(
            values.privacyURL.sslEnabled,
            values.privacyURL.value
        )}${values.privacyURL.value}`,
        termsOfServiceURL: `${protocol(
            values.termsOfServiceURL.sslEnabled,
            values.termsOfServiceURL.value
        )}${values.termsOfServiceURL.value}`,
    }
}

export const updateAppSettings = createAsyncThunk(
    'app/updateAppSettings',
    async (updatedValues) => {
        const appParseObj = await Parse.User.current().get('app').fetch()

        const updatedPaymentMethods = shapePaymentMethodsForParse(
            updatedValues.paymentMethods
        )
        appParseObj.set('title', updatedValues.title)
        appParseObj.set(
            'applepay',
            updatedPaymentMethods.some((method) => method.name === 'applepay')
        )
        appParseObj.set('nativeCheckout', updatedValues.nativeCheckout)

        const response = await appParseObj.save()
        return formatAppResponse(response)
    }
)

export const updateListingSettings = createAsyncThunk(
    'app/updateListingSettings',
    async ({ values, initialValues }, { getState, dispatch }) => {
        const assets = {
            appIcon: values.appIcon,
            launchScreen: values.launchScreen,
            placeholder: values.placeholder,
        }
        const { app } = getState()

        const shapedListingUrls = shapeListingUrls(values)
        const shapedInitialListingUrls = shapeListingUrls(initialValues)

        await Parse.Cloud.run('editPlatforms', {
            appId: app.data.id,
            assets,
            listing: { ...values, ...shapedListingUrls },
        }).then((res) => {
            trackAppStoreListing({
                originalListing: { ...initialValues, shapedInitialListingUrls },
                listing: { ...values, ...shapedListingUrls },
            })
        })

        return { ...values, ...shapedListingUrls }
    }
)

export const setAppAssets = createAsyncThunk(
    'app/setAppAssets',
    async (updatedAssets, { getState }) => {
        const { app } = getState() as RootState

        await Parse.Cloud.run('editPlatforms', {
            appId: app.data.id,
            assets: updatedAssets,
            listing: {
                ...app.data.listing,
                appIcon: updatedAssets.appIcon,
                placeholder: updatedAssets.placeholder,
                launchScreen: updatedAssets.launchScreen,
            },
        })
        return { ...app.data.listing, ...updatedAssets }
    }
)

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchApp.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchApp.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(fetchApp.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(updateAppSettings.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateAppSettings.fulfilled, (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = null
        })
        builder.addCase(updateAppSettings.rejected, (state, action) => {
            state.error = action.error
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(updateListingSettings.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateListingSettings.fulfilled, (state, action) => {
            state.data.assets = {
                appIcon: action.payload.appIcon,
                placeholder: action.payload.placeholder,
                launchScreen: action.payload.launchScreen,
            }
            state.data.listing = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        })
        builder.addCase(updateListingSettings.rejected, (state, action) => {
            state.error = action.error
            state.loading = 'rejected'
        })
        builder.addCase(setAppAssets.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(setAppAssets.fulfilled, (state, action) => {
            state.data.listing = action.payload
            state.data.assets = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        })
        builder.addCase(setAppAssets.rejected, (state, action) => {
            state.error = action.error
            state.loading = 'rejected'
        })
    },
})

export const selectApp = (state: RootState) => state.app.data

export const selectAppAndSettingsInitialized = (state: RootState) =>
    state.settings.initialized &&
    state.app.initialized &&
    state.integrations.initialized

export const selectAppId = (state: RootState) => state.app.data.id

export const selectAppIcon = (state: RootState) =>
    state.app.data?.assets?.appIcon || '/img/app-icon-placeholder.jpg'

export const selectShopifyStore = (state: RootState) =>
    state.app.data?.shopifyStore

export const selectStoreEnabledCurrencies = (state: RootState) =>
    state.app.data.storeInfo?.enabled_presentment_currencies || []

export const selectListing = (state: RootState) => state.app.data.listing || {}

export const selectAppTitle = (state: RootState) => {
    const { title } = selectListing(state)
    return title || state.app.data.title || 'Untitled App'
}

export const selectAppTitleRaw = (state: RootState) =>
    state.app.data.title || 'Untitled App'

export const selectAppAccess = (state: RootState) => state.app.data.access || {}

export const selectAppAssets = (state: RootState) => state.app.data.assets || {}

export const selectAppStoreDomain = (state: RootState) =>
    state.app.data.storeInfo?.domain || ''

export const selectInsightsProAppInfo = (state: RootState) => {
    /* 
        Currently, the merchant currency & location meta data defaults to USA based values
        if they don't have those configured values stored on Parse
    */

    const app = state.app.data
    const merchantCountryCodeDefault = 'US'
    const merchantCurrencyDefault = 'USD'
    const merchantLongitudeDefault = -101.2996
    const merchantLatitudeDefault = 47.1164

    return {
        id: app.id,
        merchantIcon: selectAppIcon(state),
        shopifyStore: selectShopifyStore(state),
        currency: {
            countryCode:
                app.storeInfo?.country_code || merchantCountryCodeDefault,
            type: app.storeInfo?.currency || merchantCurrencyDefault,
        },
        merchantLocation: {
            longitude: app.storeInfo?.longitude || merchantLongitudeDefault,
            latitude: app.storeInfo?.latitude || merchantLatitudeDefault,
        },
    }
}

export const selectIsInternalReviewAccount = (state: RootState) =>
    state.app.data.id === 'by2hrBW56R' || state.app.data.id === 'UdCOWYXpSG'

export const selectPartnerId = (state: RootState) => state.app.data.partnerId

export default appSlice.reducer
