const getBase64FromUrl = async (url) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsBinaryString(blob)
        reader.onloadend = () => {
            const base64data = window.btoa(reader.result)
            resolve(base64data.toString('base64'))
        }
    })
}

export default getBase64FromUrl
