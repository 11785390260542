import { Parse } from 'services/parse'

const getAllCustomBlocks = async () => {
    const blocks = await Parse.Cloud.run('getCustomBlocks', {
        appId: Parse.User.current().attributes.app.id,
    })
    return blocks
}

const getCustomBlock = (id) => {}

const deleteCustomBlock = (id) => {}

const archiveCustomBlock = async (id, forceArchive) => {
    const response = await Parse.Cloud.run('archiveCustomBlock', {
        id,
        appId: Parse.User.current().attributes.app.id,
        forceArchive,
    })
    return response
}

const checkIfCustomBlockIsLive = async (id) => {
    let isCustomBlockLive = false
    if (id) {
        try {
            isCustomBlockLive = await Parse.Cloud.run(
                'isCustomBlockLiveInApp',
                {
                    id,
                    appId: Parse.User.current().attributes.app.id,
                }
            )
            return isCustomBlockLive
        } catch (error) {
            return isCustomBlockLive
        }
    }
    return isCustomBlockLive
}

const saveCustomBlock = async (customBlock) => {
    try {
        const customBlockResponse = await Parse.Cloud.run('saveCustomBlock', {
            ...customBlock,
        })
        return customBlockResponse
    } catch (error) {
        console.error('error', error)
    }
}

const saveIntegrationCustomBlock = async (customBlock) => {
    try {
        const customBlockResponse = await Parse.Cloud.run(
            'saveIntegrationCustomBlock',
            {
                ...customBlock,
            }
        )
        return customBlockResponse
    } catch (error) {
        console.log('error', error)
    }
}

const publishIntegrationCustomBlock = async (data) => {
    try {
        const customBlockResponse = await Parse.Cloud.run(
            'updatePartnerBlocks',
            {
                ...data,
            }
        )
        return customBlockResponse
    } catch (error) {
        console.log('error', error)
    }
}

export {
    getAllCustomBlocks,
    deleteCustomBlock,
    archiveCustomBlock,
    getCustomBlock,
    saveCustomBlock,
    checkIfCustomBlockIsLive,
    saveIntegrationCustomBlock,
    publishIntegrationCustomBlock,
}
