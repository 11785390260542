/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    createBannerScript,
    getBannerStatus,
    removeBannerScript,
    updateBannerScript,
} from 'services/parse'

const scriptBaseUrl =
    'https://unpkg.com/tapcart-embed@latest/dist/tc-plugins.iife.js?'

export const createWidget = createAsyncThunk(
    'captureKitWidget/createWidget',
    async (appId) => {
        const { script } = await createBannerScript(
            appId,
            { appId },
            scriptBaseUrl
        )
        return { ...script, enabled: typeof script !== 'undefined' }
    }
)

export const readWidget = createAsyncThunk(
    'captureKitWidget/readWidget',
    async (appId) => {
        const { script } = await getBannerStatus(appId, scriptBaseUrl)
        return {
            ...script,
            enabled: typeof script !== 'undefined',
        }
    }
)

export const updateWidget = createAsyncThunk(
    'captureKitWidget/updateWidget',
    async (appId) => {
        const { script } = await updateBannerScript(appId, scriptBaseUrl)
        return {
            ...script,
            enabled: typeof script !== 'undefined',
        }
    }
)

export const deleteWidget = createAsyncThunk(
    'captureKitWidget/deleteWidget',
    async (appId, { getState }) => {
        const { captureKitWidget } = getState()
        const { id } = captureKitWidget.data

        const res = await removeBannerScript(appId, {
            id,
        })

        return { enabled: false }
    }
)

export const captureKitWidgetSlice = createSlice({
    name: 'widget',
    initialState: {
        loading: 'idle',
        data: {
            enabled: false,
        },
        error: undefined,
        initialized: false,
    },
    extraReducers: {
        [createWidget.pending]: (state) => {
            state.loading = 'pending'
        },
        [createWidget.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        },
        [createWidget.rejected]: (state) => {
            state.loading = 'rejected'
        },
        [readWidget.pending]: (state) => {
            state.loading = 'pending'
        },
        [readWidget.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
            state.initialized = true
        },
        [readWidget.rejected]: (state) => {
            state.loading = 'rejected'
            state.initialized = true
        },
        [deleteWidget.pending]: (state) => {
            state.loading = 'pending'
        },
        [deleteWidget.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        },
        [deleteWidget.rejected]: (state) => {
            state.loading = 'rejected'
        },
    },
})

export const selectCaptureWidgetEnabled = (state) =>
    Boolean(state.captureKit.data.active) // Used to be captureKitWidget, but we have an active status we can use on capture kit data

export const selectCaptureWidgetInitialized = (state) =>
    Boolean(state.captureKitWidget.initialized)

export default captureKitWidgetSlice.reducer
