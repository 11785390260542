/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import promiseTimeout from 'utils/helpers/promise-timeout'
import getHeaders from 'store/_utils/get-headers'

const captureKitAPI = import.meta.env.VITE_TC_CAPTURE_API

export const updateQrImage = createAsyncThunk(
    'captureKit/updateQrImage',
    async ({ qrCode }, { getState }) => {
        const headers = await getHeaders()
        const state = getState()
        const appId = state.app.data.id

        // qrCode.getRawData() will fail without rejecting the promise
        // this adds a timeout to the promise in case it fails
        const file = await promiseTimeout(1000, qrCode.getRawData('svg'))

        const qrImageBody = new FormData()
        qrImageBody.append('qrcode', file)

        const res = await fetch(
            `${captureKitAPI}/api/storage/qrcode/${appId}`,
            {
                method: 'POST',
                headers: {
                    ...headers,
                },
                body: qrImageBody,
            }
        )

        if (res.status === 200) {
            return res.json()
        }
        throw new Error('Failed to update QR image')
    }
)

export const qrImageSlice = createSlice({
    name: 'qrImage',
    initialState: {
        loading: 'idle',
        data: {},
        error: undefined,
    },
    extraReducers: {
        [updateQrImage.pending]: (state) => {
            state.loading = 'pending'
        },
        [updateQrImage.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
        },
        [updateQrImage.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error.message
        },
    },
})

export default qrImageSlice.reducer
