/* eslint-disable no-underscore-dangle */
import { RootState } from 'store'
import moment from 'moment'

type TrialPeriod = {
    trialDays: number
    active: boolean
}

// Take fields from the app data that are needed to calculate the trial period
// Using this model rather than updating the App model to keep scope to a minimum
type TrialAppDataFields = {
    trialLength?: number
    trialExtendedAt?: Date
    createdAt: Date
}

/**
 * @description Calculate the trial period for the current app.
 * If subscription does not exist or is v1, it will use the app trial fields.
 * If subscription is v2, it will use the trialEndDate field to calculate the days remaining.
 *
 * @param state RootState of dashboard
 * @returns TrialPeriod object with trialDays and active boolean
 */
export const calculateTrialPeriod = (state: RootState): TrialPeriod => {
    // Fetch default values (from app data)
    const appData = state.app.data as unknown as TrialAppDataFields
    const {
        createdAt: appCreatedAt,
        trialLength: appTrialLength,
        trialExtendedAt: appTrialExtendedAt,
    } = appData

    // Fetch subscription data
    const subscription =
        state.subscription.data &&
        Object.keys(state.subscription.data).length > 0
            ? state.subscription.data
            : null

    const isChargebeeSubscription =
        subscription && subscription?.provider?.name === 'chargebee'

    // Set default values (in the case of no subscription) - these variables will be used to calculate the trial period
    let trialLength = appTrialLength
    let trialStartedAt = appTrialExtendedAt || appCreatedAt

    // Still display the dashboard if subscription object doesn't exist,
    // we want to expose it for users who are not on a trial yet
    const isChargebeeTrialActive =
        !!!subscription ||
        (isChargebeeSubscription && subscription.status === 'trial')

    // If subscription exists and is v2, use the trialEndDate and trialStartDate fields to calculate the days remaining
    if (
        isChargebeeSubscription &&
        subscription.terms.trialStartDate &&
        subscription.terms.trialEndDate
    ) {
        const { trialStartDate, trialEndDate } = subscription.terms

        // Calculate trial length by taking the difference between the end date and start date
        trialLength = moment(trialEndDate)
            .endOf('day')
            .diff(moment(trialStartDate).startOf('day'), 'days')

        // Set trial start date
        trialStartedAt = trialStartDate
    }

    const trialDaysRemaining = moment(trialStartedAt)
        .startOf('day')
        .add(trialLength, 'days') // if trial length is undefined, this will add 0
        .diff(moment().startOf('day'), 'days')

    return {
        trialDays: trialDaysRemaining > 0 ? trialDaysRemaining : 0,
        active: trialDaysRemaining > 0 || isChargebeeTrialActive,
    }
}