/* eslint-disable no-void */
import { Parse } from 'services/parse'
import segment from './init-segment'
import { TapcartAnalytics, IdentifyTraits } from './types'

const window = global.window as unknown as Window & {
    tapcartAnalytics: TapcartAnalytics
}

const tapcartAnalytics = {
    identify: (userId: string, traits: IdentifyTraits): void => {
        void segment.identify(userId, traits)
    },
    setUserProperties: (traits: Record<string, unknown>): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, unicorn/no-null
        const userId = (Parse.User?.current()?.id as string) || null
        void segment.identify(userId, traits)
    },
    incrementUserProperty: (key: string, value: number): void => {
        // https://segment.com/docs/connections/destinations/catalog/amplitude/#increment-traits
        void segment.track('Increment User Property', { [key]: value })
    },
    logEvent: (
        eventName: string,
        eventProperties: Record<string, unknown> | undefined
    ): void => {
        void segment.track(eventName, eventProperties)
    },
    logPageView: (): void => {
        // Don't track after log out event
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call,
        if (!Parse.User?.current()) return
        void segment.page()
    },
    logPageViewNoLogin: (): void => {
        // logPageView requires a user to be logged in order to log a page view event
        // There are some cases though where we don't care if a user is logged out or not like the /create page
        void segment.page()
    },
}

if (window) {
    window.tapcartAnalytics = tapcartAnalytics
    // AppCues and other tools are expecting this global variable.
    window.analytics = segment
}

export default tapcartAnalytics
