import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import toolbarOptions from './Toolbar/toolbar-options'

const initialState = {
    initialized: false,
    activeSegment: toolbarOptions[0],
    darkMode: true,
    loading: 'idle',
    libraryOptions: {},
    latestVersion: {
        tag: null,
        releaseNotes: [],
        releaseDate: null,
    },
    baseVersion: {
        tag: null,
        releaseNotes: [],
        releaseDate: null,
    },
    sdkVersions: null,
    previousRoute: null,
}

const customBlocksStorage = import.meta.env.VITE_TC_CUSTOM_BLOCKS_RESOURCES

export const fetchWorkspaceProperties = createAsyncThunk(
    'customBlocksWorkspace/fetchLibraries',
    async (appId) => {
        const versions = await fetch(
            `${customBlocksStorage}/_static/versions.json`
        )
            .then((res) => res.json())
            .then((data) => data)

        let releases = versions.versions
        const groups = releases.reduce((acc, release) => {
            const majorMinor = release.tag.split('.').slice(0, 2).join('.')

            if (!acc[majorMinor] || acc[majorMinor].tag < release.tag) {
                acc[majorMinor] = release
            }
            return acc
        }, {})

        const sdkVersions = groups

        const latestVersion = versions.latest.baseVersionTag
        const sdkReleaseInfo = versions.versions.find(
            (el) => el.tag === versions.latest.baseVersionTag
        )

        const globalLibraries = await fetch(
            `${customBlocksStorage}/_static/available-resources.json`
        )
            .then((res) => res.json())
            .then((data) => data)

        try {
            const appSpecificLibraries = await fetch(
                `${customBlocksStorage}/${appId}/available-resources.json`
            )
                .then((res) => res.json())
                .then((data) => data)

            return {
                imports: [...appSpecificLibraries, ...globalLibraries],
                latestVersion,
                sdkReleaseInfo,
            }
        } catch (err) {
            return {
                imports: globalLibraries,
                latestVersion,
                sdkReleaseInfo,
                sdkVersions,
            }
        }
    }
)

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setActiveSegment: (state, action) => {
            state.activeSegment = action.payload
        },
        toggleDarkMode: (state) => !state.darkMode,
        setPreviousRoute: (state, action) => {
            state.previousRoute = action.payload
        },
    },
    extraReducers: {
        [fetchWorkspaceProperties.pending]: (state) => {
            state.loading = 'pending'
        },
        [fetchWorkspaceProperties.fulfilled]: (state, { payload }) => {
            state.loading = 'fulfilled'
            const shapedLibraryOptions = {}
            payload.imports.map((currentImport) => {
                shapedLibraryOptions[currentImport.url] = {
                    label: currentImport.name,
                    type: currentImport.fileType,
                }
            })
            state.latestVersion.tag = payload.latestVersion
            state.latestVersion.releaseNotes =
                payload.sdkReleaseInfo?.release_notes?.changes || []
            state.latestVersion.releaseDate =
                payload.sdkReleaseInfo?.release_notes?.mobile_release_date ||
                null

            state.libraryOptions = shapedLibraryOptions
            state.sdkVersions = payload.sdkVersions
            state.initialized = true
        },
        [fetchWorkspaceProperties.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.libraryOptions = action.error.message
        },
    },
})

export const { setActiveSegment, toggleDarkMode, setPreviousRoute } =
    workspaceSlice.actions

export default workspaceSlice.reducer
