/* eslint-disable unicorn/prefer-reflect-apply */
import cloneDeep from 'lodash/cloneDeep'
import Parse from 'parse/dist/parse.min.js'
import { ActivateChargeError, CreatePlatformsError } from 'utils/errors'
import tapcartAnalytics from '../user-tracking'

Parse.initialize(
    import.meta.env.VITE_TC_PARSE_APPLICATION_ID,
    import.meta.env.VITE_TC_PARSE_KEY
)
Parse.serverURL = import.meta.env.VITE_TC_PARSE_API

const initializeParse = () =>
    Parse.User.current()
        .fetch()
        .then((user) => {
            const appId = user.get('app').id
            return Promise.all([
                Parse.Cloud.run('getDashboardApp', { appId }),
                getPlatforms(),
            ])
        })

const getPlatforms = () => {
    const platformsQuery = new Parse.Query('Platforms')
    platformsQuery.equalTo('app', Parse.User.current().get('app'))
    platformsQuery.include(['version', 'deploymentConfig', 'nextVersion'])
    return platformsQuery.find()
}

const getVersionStatusByDevice = (app, device) => {
    const versionQuery = new Parse.Query('Platforms')
    versionQuery.equalTo('app', app)
    versionQuery.equalTo('type', device)
    versionQuery.include('version')

    function getVersionStatus(query) {
        return new Promise((resolve, reject) =>
            query
                .find()
                .then((platform) => {
                    const versionStatus = platform[0]
                        ? platform[0].get('version').get('status')
                        : null
                    return resolve(versionStatus)
                })
                .catch((error) => {
                    console.log('err', error)
                    return reject(error)
                })
        )
    }
    return getVersionStatus(versionQuery)
}

/** ***************
 PUSH FUNCTIONS
 *************** */

const getScheduledNotifs = () => {
    const appId = Parse.User.current().attributes.app.id
    const App = Parse.Object.extend('Apps')
    const scheduledNotifQuery = new Parse.Query('Notifications')
    scheduledNotifQuery.equalTo('app', new App({ id: appId }))
    scheduledNotifQuery.equalTo('status', 'scheduled')
    scheduledNotifQuery.ascending('scheduled')
    const notifs = scheduledNotifQuery.find().then((res) => res)
    return scheduledNotifQuery.find()
}

const getPastNotifs = () => {
    const appId = Parse.User.current().attributes.app.id
    const App = Parse.Object.extend('Apps')
    const pastNotifquery = new Parse.Query('Notifications')
    pastNotifquery.equalTo('app', new App({ id: appId }))
    pastNotifquery.descending('createdAt')
    pastNotifquery.notEqualTo('status', 'scheduled')
    pastNotifquery.limit(10)
    return pastNotifquery.find()
}

/** ***************
 STATS FUNCTIONS
 *************** */

const getAllOrders = (app) => {
    const ordersQuery = new Parse.Query('Orders')
    ordersQuery.equalTo('app', app)
    return ordersQuery.find()
}

const getPushSubscriptionTopic = (app) =>
    Parse.Cloud.run('notifications_getTopic', {
        appId: app.id,
        platformTypes: ['ios'],
    })

const getStats = (app) =>
    Parse.Cloud.run('stats_getStats', {
        appId: app.id,
        from: 30,
        unit: 'day',
    })

const getStoreCurrency = (app) => app.attributes.storeInfo.currency

/** ***************
 SUBMISSION FUNCTIONS
 *************** */

const getAssetsFromPlatforms = (platforms) => {
    const appId = Parse.User.current().attributes.app.id
    const App = Parse.Object.extend('Apps')
    return {
        appIcon: Parse.User.current().attributes.app.attributes.appIcon.url(),
        launchScreen:
            Parse.User.current().attributes.app.attributes.launchScreen.url(),
        placeholderImage:
            Parse.User.current().attributes.app.attributes.settings.attributes
                .themeOptions.placeholder,
    }
}

const getListingFromPlatforms = (platforms) => {
    const appId = Parse.User.current().attributes.app.id
    const App = Parse.Object.extend('Apps')
    if (platforms.length > 0) {
        if (platforms[0].attributes.nextVersion) {
            return platforms[0].attributes.nextVersion.attributes.listing
        }
        return platforms[0].attributes.version.attributes.listing
    }
    return null
}

const getSubmissionStepsFromPlatforms = (platforms) => {
    // Find if listings is completed
    let listing = null
    let androidAccountConnected = false
    let iosAccountConnected = false
    let androidVersionSubmitted = false
    let iosVersionSubmitted = false

    for (const platform of platforms) {
        // Find the listing
        if (platform.attributes.nextVersion) {
            listing = platform.attributes.nextVersion.attributes.listing
        } else if (platform.attributes.version) {
            listing = platform.attributes.version.attributes.listing
        }

        // Find connected account status
        if (platform.attributes.type === 'ios') {
            iosAccountConnected = platform.attributes.connected
        } else if (platform.attributes.type === 'android') {
            androidAccountConnected = platform.attributes.connected
        }

        // Find Submitted Versions
        if (platform.attributes.type === 'ios' && platform.attributes.version) {
            iosVersionSubmitted =
                platform.attributes.version.attributes.status !== 'created'
        } else if (
            platform.attributes.type === 'ios' &&
            platform.attributes.nextVersion
        ) {
            iosVersionSubmitted =
                platform.attributes.nextVersion.attributes.status !== 'created'
        }
        if (
            platform.attributes.type === 'android' &&
            platform.attributes.version
        ) {
            androidVersionSubmitted =
                platform.attributes.version.attributes.status !== 'created'
        } else if (
            platform.attributes.type === 'android' &&
            platform.attributes.nextVersion
        ) {
            androidVersionSubmitted =
                platform.attributes.nextVersion.attributes.status !== 'created'
        }
    }

    return {
        listingCompleted: !!listing,
        accountsConnected: iosAccountConnected || androidAccountConnected,
        appSubmitted: iosVersionSubmitted || androidVersionSubmitted,
    }
}

const savePlatforms = (listing, assets) => {
    const appId = Parse.User.current().attributes.app.id
    const App = Parse.Object.extend('Apps')
    return Parse.Cloud.run('editPlatforms', {
        appId: Parse.User.current().attributes.app.id,
        listing,
        assets,
    })
}

const submitPlatforms = () => {
    const appId = Parse.User.current().attributes.app.id
    const App = Parse.Object.extend('Apps')
    return Parse.Cloud.run('submitPlatforms', {
        appId: Parse.User.current().attributes.app.id,
        platformTypes: ['ios', 'android'],
    })
}

const getAppIdsFromPlatforms = (platforms) => {
    const appIds = {}
    platforms.map((platform) => {
        if (
            platform.attributes.type === 'ios' &&
            platform.attributes.appStoreId
        ) {
            appIds.appStoreId = platform.attributes.appStoreId
        }
        if (
            platform.attributes.type === 'android' &&
            platform.attributes.appStoreId
        ) {
            appIds.playStoreId = platform.attributes.appStoreId
        }
    })
    return appIds
}

/** ***************
 SUBSCRIPTION FUNCTIONS
 *************** */
const createCharge = (planType, source, term, price) =>
    Parse.Cloud.run('createCharge', {
        planType,
        source,
        term,
        price,
        test: false,
        appId: Parse.User.current().attributes.app.id,
    })

const activateCharge = ({ chargeId, planType, source }) =>
    Parse.Cloud.run('activateCharge', {
        appId: Parse.User.current().attributes.app.id,
        chargeId,
        planType,
        source,
    }).catch((error) => {
        throw new ActivateChargeError(error)
    })

const cancelCharge = (chargeId) =>
    Parse.Cloud.run('cancelCharge', {
        appId: Parse.User.current().attributes.app.id,
        chargeId,
    })

const createPlatforms = () =>
    Parse.Cloud.run('createPlatforms', {
        appId: Parse.User.current().attributes.app.id,
        platformTypes: ['ios', 'android'],
    }).catch((error) => {
        throw new CreatePlatformsError(error)
    })

/** ***************
 DROPS FUNCTIONS
 *************** */

const createDrop = (type) =>
    Parse.Cloud.run('drops_createDrop', {
        appId: Parse.User.current().attributes.app.id,
        type,
    })

const updateDrop = async (drop) => {
    // sanitize drops and add empty string for name if it doesnt exist
    const sanitizedDrop = cloneDeep(drop)
    sanitizedDrop.pages.map((page) => {
        if (!page.name) {
            page.name = ''
        }
    })

    const updatedDrop = await Parse.Cloud.run('drops_updateDrop', {
        appId: Parse.User.current().attributes.app.id,
        dropId: sanitizedDrop.id,
        updates: sanitizedDrop,
    })

    return updatedDrop
}

const deleteDrop = (dropId) =>
    Parse.Cloud.run('drops_deleteDrop', {
        appId: Parse.User.current().attributes.app.id,
        dropId,
    })

const publishDrop = (dropId) =>
    Parse.Cloud.run('drops_publishDrop', {
        appId: Parse.User.current().attributes.app.id,
        dropId,
    })

const cloneDrop = (dropId) =>
    Parse.Cloud.run('drops_cloneDrop', {
        appId: Parse.User.current().attributes.app.id,
        dropId,
    })

const getNextDrop = () => {
    const dropsQuery = new Parse.Query('Drops')
    dropsQuery.equalTo('app', Parse.User.current().get('app'))
    dropsQuery.equalTo('status', 'scheduled')
    dropsQuery.ascending('publishedAt')
    return dropsQuery.first()
}

const enableDrops = () =>
    Parse.Cloud.run('drops_enableDrops', {
        appId: Parse.User.current().attributes.app.id,
    })

/** ***************
 MOBILE WEB BANNER FUNCTIONS
*************** */
const parseParameters = (url) => {
    const parameters = {}
    const parser = document.createElement('a')
    parser.href = url
    const query = parser.search.slice(1)
    const variables = query.split('&')
    for (const variable of variables) {
        const pair = variable.split('=')
        pair[1] ? (pair[1] = `${pair[1]}`.replace(/\+/g, '%20')) : null
        parameters[pair[0]] = decodeURIComponent(pair[1])
    }

    return parameters
}

const getBannerStatus = async (appId, source) => {
    try {
        const res = await Parse.Cloud.run('getShopifyScriptTag', {
            appId,
            src: source,
        })
        if (res.src) {
            const parameters = parseParameters(res.src)
            return { params: parameters, script: res }
        }
        return { params: false, script: false }
    } catch (error) {
        return error
    }
}

const createBannerScript = async (appId, parameters, baseURL) => {
    let queryString = ''
    if (parameters) queryString = new URLSearchParams(parameters).toString()

    try {
        const res = await Parse.Cloud.run('createShopifyScriptTag', {
            appId,
            src: `${baseURL}${queryString}`,
        })

        if (res) {
            const params = parseParameters(res.src)
            return { params, script: res }
        }
        return { params: false, script: false }
    } catch (error) {
        return error
    }
}

const updateBannerScript = async (appId, scriptId, parameters, baseURL) => {
    const queryString = new URLSearchParams(parameters).toString()

    try {
        const res = await Parse.Cloud.run('updateShopifyScriptTag', {
            appId,
            scriptId,
            src: `${baseURL}${queryString}`,
        })

        if (res.src) {
            const params = parseParameters(res.src)
            return { params, script: res }
        }
        return { params: false, script: false }
    } catch (error) {
        return error
    }
}

const removeBannerScript = (appId, script) =>
    Parse.Cloud.run('deleteShopifyScriptTag', {
        appId,
        script,
    })

const optInFeature = async (app, feature) => {
    if (!app || !feature) throw new Error('missing required arguments')
    const optInObject = app.get('optIns') || {}
    const optInArray = Object.keys(optInObject).filter(
        (item) => optInObject[item]
    )
    optInArray.push(feature)
    try {
        const res = await Parse.Cloud.run('feature_setOptIn', {
            appId: app.id,
            feature,
        })
        const amplitudeUserProperties = {}
        amplitudeUserProperties[`${feature}-opt-in`] = true
        amplitudeUserProperties['products-opted-in'] = optInArray
        tapcartAnalytics.setUserProperties(amplitudeUserProperties)
        tapcartAnalytics.logEvent('product-opted-in', {
            product: feature,
        })
        return res
    } catch (error) {
        throw new Error(error)
    }
}

/** ***************
 METAFIELDS FUNCTIONS
*************** */

const enableMetafield = async (namespace, key, ownerType) => {
    if (!namespace || !key) throw new Error('missing required arguments')
    try {
        const res = await Parse.Cloud.run('pdp_enableMetafieldVisibility', {
            appId: Parse.User.current().attributes.app.id,
            namespace,
            key,
            ownerType,
        })

        if (res && res.metafieldVisibilityId) {
            return res.metafieldVisibilityId
        }
    } catch (error) {
        console.log('metafield error', error)
        throw new Error(
            'Unable to access metafield with the information you entered'
        )
    }
}

const saveBlocks = async (appId, blocks) => {
    //Blocks are always in the format of multiblocksections.
    return await Parse.Cloud.run('saveBlocks', {
        appId,
        blocks: blocks[0].blocks,
        multiBlockSections: blocks,
    })
}

const getIntegrationBlocks = async () => {
    return await Parse.Cloud.run('getIntegrationBlocks')
}

const verifyCKTAEWidgetIsEnabled = async ({ appId }) => {
    return await Parse.Cloud.run('checkMerchantCKTAEWidgetEnabled', { appId })
}

export {
    Parse,
    initializeParse,
    getPlatforms,
    getScheduledNotifs,
    getPastNotifs,
    getAllOrders,
    getPushSubscriptionTopic,
    getStats,
    getStoreCurrency,
    getAssetsFromPlatforms,
    getListingFromPlatforms,
    getSubmissionStepsFromPlatforms,
    savePlatforms,
    submitPlatforms,
    createCharge,
    activateCharge,
    createPlatforms,
    cancelCharge,
    createDrop,
    updateDrop,
    deleteDrop,
    publishDrop,
    cloneDrop,
    getNextDrop,
    enableDrops,
    getAppIdsFromPlatforms,
    getVersionStatusByDevice,
    getBannerStatus,
    updateBannerScript,
    createBannerScript,
    removeBannerScript,
    optInFeature,
    enableMetafield,
    saveBlocks,
    getIntegrationBlocks,
    verifyCKTAEWidgetIsEnabled,
}
