export function formatSubscriptionParseObject(subscription) {
    if (!subscription) return {}
    const {
        trialEndsOn,
        activatedOn,
        billingOn,
        priceBreakdown,
        chargeId,
        status,
        price,
        name,
        term,
        billingFrequency,
        trialDays,
        test,
        planType,
        netAmount,
        index,
        type,
        insightsPro,
    } = subscription.attributes
    const { createdAt, updatedAt, id } = subscription

    return {
        trialEndsOn,
        activatedOn,
        billingOn,
        priceBreakdown,
        chargeId,
        status,
        price,
        name,
        term,
        billingFrequency,
        trialDays,
        test,
        planType,
        netAmount,
        index,
        type,
        createdAt,
        updatedAt,
        insightsPro,
    }
}
export function formatAppResponse(appParseObject) {
    const parseAssets = { ...appParseObject.get('assets') }
    const assets = {
        appIcon: parseAssets.appIcon || '/img/app-icon-placeholder.jpg',
        launchScreen: parseAssets.launchScreen || '',
        placeholder: parseAssets.placeholder || '',
    }

    return {
        access: appParseObject.get('access'),
        appIcon: appParseObject.get('appIcon')?.url(),
        applepay: appParseObject.get('applepay'),
        assets,
        // createdAt is used to calculate app trial days left
        createdAt: appParseObject.attributes.createdAt,
        frozen: appParseObject.get('frozen'),
        id: appParseObject?.id,
        launchScreen: appParseObject.get('launchScreen')?.url(),
        listing: appParseObject.get('listing'),
        mainLogo: appParseObject.get('mainLogo')?.url(),
        nativeCheckout: appParseObject.get('nativeCheckout'),
        optIns: appParseObject.get('optIns'),
        ownerAccountId: appParseObject.get('owner')?.id,
        partnerId: appParseObject.attributes.partnerId,
        planStatus: appParseObject.get('planStatus'),
        redaction: appParseObject.get('redaction'),
        salesforceId: appParseObject.get('salesforceId'),
        shopifyApiKey: appParseObject.get('shopifyApiKey'),
        shopifyStore: appParseObject.get('shopifyStore'),
        startedOnboarding: appParseObject.get('startedOnboarding'),
        storeInfo: appParseObject.get('storeInfo'),
        subscription: formatSubscriptionParseObject(
            appParseObject.get('subscription')
        ),
        title: appParseObject.get('title'),
        trialExtendedAt: appParseObject.get('trialExtendedAt'),
        trialLength: appParseObject.get('trialLength'),
    }
}
