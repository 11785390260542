import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'

const DYNAMIC_PRODUCT_SETTINGS_API = `${import.meta.env.VITE_TC_INSIGHTS_API}/dynamic-product-settings`

export const readShopifyCollections = createAsyncThunk('dynamicProduct/readShopifyCollections', async (arg, { getState }) => {
  const headers = await getHeaders()
  const state = getState()
  const appId = state.app?.data?.id

  const getCollectionsForSettings = await fetch(`${DYNAMIC_PRODUCT_SETTINGS_API}/v1/${appId}/collections`, {
      method: 'GET',
      headers: {
          ...headers,
          'Content-Type': 'application/json',
      }
  })

  const getActiveCollectionsForSettings = await fetch(`${DYNAMIC_PRODUCT_SETTINGS_API}/v1/${appId}/collections/active`, {
    method: 'GET',
    headers: {
        ...headers,
        'Content-Type': 'application/json',
    }
  })

    const [collectionsResponseForSettings, activeCollectionsResponseForSettings] = await Promise.all([getCollectionsForSettings, getActiveCollectionsForSettings])
    const { data: { collections = [] } } = await collectionsResponseForSettings.json()
    const { data: {
      imageTags = [],
      lowestCostVariants = [],
    } } = await activeCollectionsResponseForSettings.json()

    return {
      shopifyCollections: collections,
      imageTags,
      lowestCostVariants,
    }
})

export const fetchFilteredShopifyCollections = createAsyncThunk('dynamicProduct/fetchFilteredShopifyCollections', async (searchTerm, { getState }) => {
  const headers = await getHeaders()
  const state = getState()
  const appId = state.app?.data?.id

    if (searchTerm) {
      const { data: { collections = [] } } = await fetch(`${DYNAMIC_PRODUCT_SETTINGS_API}/v1/${appId}/collections?search=${searchTerm}&limit=250`, {
        method: 'GET',
        headers: {  ...headers, 'Content-Type': 'application/json' },
      }).then(res => res.json())
      return collections
    }
})

export const updateDPSCollections = createAsyncThunk(
  'dynamicProduct/updateDynamicProductSettingsCollections',
    async (requestBody, { getState, rejectWithValue }) => {
      const headers = await getHeaders()
      const state = getState()
      const appId = state.app?.data?.id

      try {
        const response = await fetch(`${DYNAMIC_PRODUCT_SETTINGS_API}/v1/${appId}/collections`, {
          method: 'PUT',
          headers: {  ...headers, 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody)
        }).then(res => res.json())
        return {
          status: response.status,
          imageTags: requestBody.imageTags,
          lowestCostVariants: requestBody.lowestCostVariants,
        }
      } catch (error) {
        return rejectWithValue(`Unable to update Dynamic Product Settings: ${error}`)
      }
})


const initialState = {
  data: { 
    shopifyCollections: [],
    imageTags: [],
    lowestCostVariants: [],
    loading: 'idle',
    error: undefined,
    initialized: false,
  }
}

export const dynamicProductSlice = createSlice({
    name: 'dynamicProduct',
    initialState,
    reducers: { 
      updateShopifyCollections: (state, action) => {
        state.data.shopifyCollections = action.payload.payload
      },
      updateImageTags: (state, action) => {
        const updatedImageTags = [
          ...action.payload
        ]
        state.data.imageTags = updatedImageTags
      },
      updateLowestCostVariants: (state, action) => {
        const updatedLowestCostVariants = [
          ...action.payload
        ]
        state.data.lowestCostVariants = updatedLowestCostVariants
      }
    },
    extraReducers: builder => {
      builder.addCase(readShopifyCollections.pending, (state) => {
        state.loading = 'pending'
      })
      builder.addCase(readShopifyCollections.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = 'fufilled'
        state.error = undefined
        state.initialized = true
      })
      builder.addCase(readShopifyCollections.rejected, (state, action) => {
        state.loading = 'rejected'
        state.initialized = true
        state.error = action.error
      })
      builder.addCase(fetchFilteredShopifyCollections.pending, (state) => {
        state.loading = 'pending'
      })
      builder.addCase(fetchFilteredShopifyCollections.fulfilled, (state, action) => {
        state.data.shopifyCollections = [
          ...action.payload
        ]
        state.loading = 'fufilled'
        state.error = undefined
        state.initialized = true
      })
      builder.addCase(fetchFilteredShopifyCollections.rejected, (state, action) => {
        state.loading = 'rejected'
        state.initialized = true
        state.error = action.error
      })
      builder.addCase(updateDPSCollections.pending, (state) => {
        state.loading = 'pending'
      })
      builder.addCase(updateDPSCollections.fulfilled, (state, action) => {
        state.data.imageTags = action.payload.imageTags
        state.data.lowestCostVariants = action.payload.lowestCostVariants
        state.loading = 'fufilled'
        state.error = undefined
        state.initialized = true
      })
      builder.addCase(updateDPSCollections.rejected, (state, action) => {
        state.loading = 'rejected'
        state.initialized = true
        state.error = action.error
      })
    }
})

export const selectDynamicProductState = (state) => state.dynamicProduct.data.shopifyCollections

export const selectMerchantImageTags = (state) => state.dynamicProduct.data.imageTags

export const selectMerchantLowestCostVariants = (state) => state.dynamicProduct.data.lowestCostVariants

export const {
  updateShopifyCollections,
  updateImageTags,
  updateLowestCostVariants,
  
} = dynamicProductSlice.actions

export default dynamicProductSlice.reducer
