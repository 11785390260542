import { Parse } from 'services/parse'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import validateFonts from 'pages/CustomBlocks/utils/validate-fonts'

const initialState = {
    htmlLines: 0,
    cssLines: 0,
    jsLines: 0,
}

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        setHtmlLines: (state, action) => {
            state.htmlLines = action.payload
        },
        setCssLines: (state, action) => {
            state.cssLines = action.payload
        },
        setJsLines: (state, action) => {
            state.jsLines = action.payload
        },
        setJSONLines: (state, action) => {
            state.jsonLines = action.payload
        },
    },
})

export const getLines = (state) => ({
    htmlLines: state.customBlocksWorkspace.editor.htmlLines,
    cssLines: state.customBlocksWorkspace.editor.cssLines,
    jsLines: state.customBlocksWorkspace.editor.jsLines,
})

export const { setHtmlLines, setCssLines, setJsLines, setJSONLines } =
    editorSlice.actions
export default editorSlice.reducer
