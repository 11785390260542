import { createEntityAdapter } from '@reduxjs/toolkit'
import { Icon } from 'types/api-types'

/* assetsIconsAdapter utilized createEntityAdapter in order to manage normalized data in the assets slice
 * createEntityAdapter generates prebuilt reducers and selectors for performing CRUD operations on a normalized data structure
 * this is sligthtly different than how we generally handle entities as the top level of each state slice but setting these icons as
 * entities with provided keys will make the crud functionality on them much more seamless and optimized
 */

const assetsIconsAdapter = createEntityAdapter<Icon>({
    selectId: (asset) => asset.id,
    sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})

export default assetsIconsAdapter
