import { createEntityAdapter } from '@reduxjs/toolkit'
import { FontVariant } from 'types/api-types'

const assetsFontsAdapter = createEntityAdapter<FontVariant>({
    selectId: (asset) => {
        return asset.postScriptName
    },
})

export default assetsFontsAdapter
