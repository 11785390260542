/* eslint-disable no-underscore-dangle */
import { createEntityAdapter } from '@reduxjs/toolkit'
import { Screen } from 'types/api-types'

const screensAdapter = createEntityAdapter<Screen>({
    selectId: (screen) => screen._id,
    sortComparer: (a, b) => a.updatedAt - b.updatedAt,
})

export default screensAdapter
